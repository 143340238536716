import React from "react";
import "./Gallery.scss";
import Layout from "../Layout";
import LightGallery from "lightgallery/react/Lightgallery.es5";
// import styles
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay/lg-autoplay.es5";

const galleryContext = require.context("../../../assets/Img/Gallery", false, /\.(jpg|jpeg|png)$/);
const galleryImages = galleryContext.keys().map(galleryContext);
const Gallery = () => {
  const onInit = () => {};
  return (
    <>
      <Layout>
        <div className="col-lg-12 p-5 gallry_div">
          <div className="row">
            <LightGallery onInit={onInit} speed={100} mode="lg-fade" plugins={[lgThumbnail, lgZoom, lgAutoplay]}>
              {galleryImages.map((image, index) => (
                <div className="col-lg-4 p-2" key={index} data-src={image} data-sub-html={`<h4>Photo ${index + 1}</h4>`}>
                  <a href={image}>
                    <img className="img-fluid w-100" src={image} alt={`img${index}`} />
                  </a>
                </div>
              ))}
            </LightGallery>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Gallery;
