import React from "react";
import "./Dashboard.scss";
import Layout from "../../Layout/Layout";
import UserMenu from "./UserMenu/UserMenu";
import UserContent from "./UserContent/UserContent";
import { useAuth } from "../../../context/auth";
const Dashboard = () => {
  const [auth] = useAuth();
  return (
    <>
      <Layout
        title={"Creto: User Dashboard"}
        description={"Welcome to Creto: User dashboard, here you can access Products, Categories, Services, Advantages and News"}
        keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Crtow, online store"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Dashboard</h1>
                <ul className="breadcrumbs">
                  <li>Home</li>
                  <li>User</li>
                  <li>Dashboard</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-3 shop-sidebar">
                    <UserMenu />
                  </div>
                  <div className="col-12 col-lg-9 admin-content">
                    <div className="col-lg-12">
                      <h2>Welcome : {auth?.user?.name}</h2>
                    </div>
                    <UserContent />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Dashboard;
