import React, { useState, useEffect, useRef } from "react";
import "./Login.scss";
import Layout from "../../Layout";
import { FaUser, FaLock } from "react-icons/fa6";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../../../context/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const emailInputRef = useRef(null);
  const onLoad = () => {
    setTimeout(() => {
      emailInputRef.current.focus();
      const scrollToPosition = window.innerHeight * 0.2; // 10% from the top
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }, 100);
  };
  const isValidEmail = (email) => {
    // Regex for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleloginSubmit = async (e) => {
    e.preventDefault();

    // Field Validation
    if (!email || !password) {
      toast.error("Please fill all fields");
      return;
    }

    // Email Validation
    if (!isValidEmail(email)) {
      toast.error("Enter a valid email");
      return;
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, { email, password });

      if (res && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        // Navigate after a short delay
        setTimeout(() => {
          navigate(location.state || "/");
        }, 1000);
      } else {
        // Handle specific error messages
        const errorMessage = res.data.message;
        console.log(errorMessage);
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      const serverError = error.response.data.message;
      console.log(serverError);
      toast.error(serverError);
    }
  };
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <>
      <Layout title={"Creto: Login Page"} description={"Enter your details to Login to Creto online bike store"} keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Creto, online store"}>
        <div className="col-12 cus_Login">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Login</h1>
                <ul className="breadcrumbs">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Login</li>
                </ul>
              </div>
            </section>
            <section className="s-login">
              <div className="col-lg-4 mx-auto cus-login-page">
                <form onSubmit={handleloginSubmit}>
                  <Toaster />
                  <div className="row">
                    <div className="col-lg-12 mb-4 text-start heading">
                      <h3>Enter your credentials to login</h3>
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="email">
                        Email <span className="required_red">*</span>
                      </label>
                      <FaUser className="cus-input-icons" />
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailInputRef}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="password">
                        <FaLock className="cus-input-icons" />
                        Password <span className="required_red">*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        placeholder="Enter your password"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-12 mb-4 text-start cus_form_inputs">
                      <div className="checkbox-wrap">
                        <div className="checkbox-cover">
                          <p>
                            <input type="checkbox" id="rememberMe" />
                            <span>Remember me</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center cus_form_inputs">
                      <button type="submit" className="btn">
                        <span>Log in</span>
                      </button>
                    </div>
                    <div className="col-lg-12 text-center mt-5 cus_form_inputs">
                      <div className="row">
                        <div className="col text-start cus_anchors">
                          <NavLink to="/ForgotPassword">Lost your password ?</NavLink>
                        </div>
                        <div className="col text-end cus_anchors">
                          <NavLink to="/SignUp">Sign Up</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;
