import React from "react";
import { Link } from "react-router-dom";
import "./News.scss";

const News = () => {
  return (
    <>
      <section className="s-our-news">
        <div className="container">
          <h2 className="title">Our News</h2>
          <div className="news-cover row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="news-item">
                <h6 className="title">
                  <Link to="/news">doloremque laudantium, totam rem aperiam, eaque ipsa quae</Link>
                </h6>
                <div className="news-post-thumbnail">
                  <Link to="/news">
                    <img className="img-fluid" src={require("../../../../assets/Img/news-1.jpg")} alt="news" />
                  </Link>
                </div>
                <div className="meta">
                  <span className="date">
                    <i className="fa fa-calendar" aria-hidden="true"></i> Dec 26,2019
                  </span>
                  <span className="post-by">
                    <i className="fa fa-user" aria-hidden="true"></i> By Samson
                  </span>
                </div>
                <div className="post-content">
                  <p>Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium doloremque lauda ntium, totam rem aperiam, eaque.</p>
                </div>
                <Link to="/news" className="btn-news">
                  read more
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="news-item">
                <h6 className="title">
                  <Link to="/news">At vero eos et accusamus et iusto odio dignissimos ducim</Link>
                </h6>
                <div className="news-post-thumbnail">
                  <Link to="/single-news">
                    <img className="img-fluid" src={require("../../../../assets/Img/news-2.jpg")} alt="news" />
                  </Link>
                </div>
                <div className="meta">
                  <span className="date">
                    <i className="fa fa-calendar" aria-hidden="true"></i> Dec 26,2019
                  </span>
                  <span className="post-by">
                    <i className="fa fa-user" aria-hidden="true"></i> By Samson
                  </span>
                </div>
                <div className="post-content">
                  <p>Corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.</p>
                </div>
                <Link to="/single-news" className="btn-news">
                  read more
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="news-item">
                <h6 className="title">
                  <Link to="/news">On the other hand, we denounce with righteous indignation a</Link>
                </h6>
                <div className="news-post-thumbnail">
                  <Link to="/news">
                    <img className="img-fluid" src={require("../../../../assets/Img/news-3.jpg")} alt="news" />
                  </Link>
                </div>
                <div className="meta">
                  <span className="date">
                    <i className="fa fa-calendar" aria-hidden="true"></i> Dec 26,2019
                  </span>
                  <span className="post-by">
                    <i className="fa fa-user" aria-hidden="true"></i> By Samson
                  </span>
                </div>
                <div className="post-content">
                  <p>Blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those.</p>
                </div>
                <Link to="/single-news" className="btn-news">
                  read more
                </Link>
              </div>
            </div>
          </div>
          <div className="btn-cover">
            <Link className="btn" to="/news">
              <span>view more</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
