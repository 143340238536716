import React, { useState, useEffect } from "react";
import "./ProductsStock.scss";
import Layout from "../../../../../Layout/Layout";
import Spinner from "../../../../../Spinner/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactTable from "../../../../../ReactTable/ReactTable";
import { FaPenToSquare, FaCheck, FaXmark } from "react-icons/fa6";
import AdminMenu from "../../../AdminMenu/AdminMenu";
const ProductsStock = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [newStock, setNewStock] = useState({});

  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-products`);
      if (data?.success) {
        setProducts(data.products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Error in getting all products");
    } finally {
      setLoading(false);
    }
  };

  const updateStocks = async (pid) => {
    try {
      console.log(`Updating stock for product ID: ${pid}`);
      const stockValue = newStock[pid];
      console.log(`New stock value: ${stockValue}`);

      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/product/update-stocks/${pid}`, { stock: stockValue });

      if (response.data.success) {
        toast.success("Stock updated successfully");
      } else {
        toast.error(response.data.message || "Error while updating the stocks");
      }

      setEditingRow(null);
      getAllProducts(); // Refresh the product list
    } catch (error) {
      console.log("Error while updating the stocks:", error.response?.data || error.message);
      toast.error("Error while updating the stocks");
    }
  };

  const handleStockChange = (id, value) => {
    setNewStock({
      ...newStock,
      [id]: value,
    });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const columns = [
    {
      Header: "Photo",
      Cell: ({ row }) => (
        <img src={row.original.photos[0].url} style={{ width: "50px", height: "50px", borderRadius: "40px", border: "2px solid #e0e0e0" }} className="img-fluid" alt={row.original.photos[0].name} />
      ),
    },
    { Header: "Name", accessor: "name" },
    { Header: "Brand", accessor: "brand" },
    { Header: "Bike Category", accessor: "bikeCategory" },
    { Header: "Price", accessor: "price" },
    { Header: "Item Code", accessor: "itemCode" },
    {
      Header: "Available Stock",
      Cell: ({ row }) =>
        editingRow === row.original._id ? (
          <div className="col-12 text-center">
            <input
              type="number"
              min="0"
              max="10"
              className="form-control"
              value={newStock[row.original._id] || row.original.stock}
              onChange={(e) => handleStockChange(row.original._id, e.target.value)}
            />
          </div>
        ) : (
          row.original.stock
        ),
    },
    {
      Header: "Update Stocks",
      Cell: ({ row }) =>
        editingRow === row.original._id ? (
          <div className="text-center tableActions">
            <FaCheck onClick={() => updateStocks(row.original._id)} style={{ color: "green" }} />
            <FaXmark onClick={() => setEditingRow(null)} style={{ color: "red" }} />
          </div>
        ) : (
          <div className="text-center tableActions">
            <FaPenToSquare onClick={() => setEditingRow(row.original._id)} />
          </div>
        ),
    },
  ];

  return (
    <Layout
      title={"Creto: Product Stocks Page"}
      description={"Want to buy bikes? You are at great place - Creto online bike store"}
      keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Creto, online store"}>
      {loading ? (
        <Spinner /> // Show spinner while loading
      ) : (
        <div className="col-lg-12 cus_shopPage">
          <Toaster />
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Product Stocks</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Shop">Shop</Link>
                  </li>
                  <li>Stocks</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <AdminMenu />
                  </div>
                  <div className="col-lg-10 col-md-9 cus_forms">
                    <div className="row">
                      <Toaster />
                      <div className="card">
                        <div className="card-header">
                          <h4>Products Stock</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12 table-responsive">
                            <ReactTable data={products} columns={columns} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductsStock;
