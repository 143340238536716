import React from "react";
import "./FindBike.scss";
const FindBike = () => {
  return (
    <>
      <section className="s-find-bike">
        <div className="container">
          <form className="find-bike-form">
            <h2 className="title">find the bike</h2>
            <ul className="form-wrap">
              <li>
                <label>Type</label>
                <select className="nice-select" defaultValue="Mountain bike">
                  <option>Mountain bike</option>
                  <option>Hybrid/Comfort Bike</option>
                  <option>Cyclocross Bike</option>
                  <option>BMX/Trick Bike</option>
                  <option>Road Bike</option>
                  <option>Track Bike</option>
                </select>
              </li>
              <li>
                <label>Wheel Size</label>
                <select className="nice-select" defaultValue="20">
                  <option>20</option>
                  <option>24</option>
                  <option>26</option>
                  <option>27</option>
                  <option>27.5</option>
                  <option>28</option>
                </select>
              </li>
              <li>
                <label>Brand</label>
                <select className="nice-select" defaultValue="Pinarello">
                  <option>Pinarello</option>
                  <option>Eddy Merckx</option>
                  <option>Specialized</option>
                  <option>Giant</option>
                  <option>Trek</option>
                  <option>BMC</option>
                </select>
              </li>
              <li>
                <a href="shop.html" className="btn">
                  <span>search</span>
                </a>
              </li>
            </ul>
          </form>
        </div>
      </section>
    </>
  );
};
export default FindBike;
