import React from "react";
import "./UserFeedback.scss";
import UserMenu from "../../UserMenu/UserMenu";
import Layout from "../../../../Layout/Layout";
import { Toaster } from "react-hot-toast";
import { FaPenToSquare, FaTrashCan, FaCommentDots } from "react-icons/fa6";
import SanitizedHTML from "../../../../SanitizedHTML/SanitizedHTML";
import TextEditor from "../../../../TextEditor/TextEditor";
import Spinner from "../../../../Spinner/Spinner";
import useUserFeedback from "./UserFeedbackLogic/UserFeedbackLogics";

const UserFeedback = () => {
  const { feedbacks, loading, formData, isEditing, nameInputRef, handleChange, handleCreateFeedback, handleEdit, resetForm, deleteFeedback } = useUserFeedback();

  return (
    <>
      <Layout title={"Creto: Feedback"}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Feedback</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>User</li>
                    <li>Feedback</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container">
                  <div className="col-lg-12 userProfileDashboard">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 cusUserSidebar">
                        <UserMenu />
                      </div>
                      <div className="col-lg-10 col-md-9 cusforms">
                        <Toaster />
                        <h3>Feedback</h3>
                        <hr />

                        {isEditing && (
                          <section className="s-contacts page-contacts mb-4">
                            <div className="container s-anim">
                              <form onSubmit={handleCreateFeedback} id="feedbackForm" name="feedbackForm" encType="multipart/form-data">
                                <ul className="form-cover">
                                  <li className="inp-name">
                                    <input
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      ref={nameInputRef}
                                      value={formData.name}
                                      name="name"
                                      placeholder="Enter your name"
                                      aria-describedby="Enter your name"
                                      required
                                      autoComplete="off"
                                      onChange={handleChange}
                                    />
                                  </li>
                                  <li className="inp-name">
                                    <input
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      value={formData.email}
                                      name="email"
                                      placeholder="Enter your email"
                                      aria-describedby="Enter your email"
                                      required
                                      autoComplete="off"
                                      disabled
                                    />
                                  </li>
                                  <li className="inp-email">
                                    <input id="photo" type="file" className="form-control" name="photo" onChange={handleChange} aria-describedby="Upload your photo" autoComplete="off" />
                                  </li>
                                  <li className="inp-text">
                                    <TextEditor
                                      id="message"
                                      className="form-control"
                                      name="message"
                                      value={formData.message}
                                      onChange={(value) => handleChange({ target: { id: "message", value } })}
                                      placeholder="Enter your feedback message"
                                      aria-describedby="Enter your feedback message"
                                      required
                                    />
                                  </li>
                                </ul>
                                <div className="btn-form-cover">
                                  <button id="submit" type="submit" className="btn" aria-describedby="Submit Feedback">
                                    <span>Update Feedback</span>
                                  </button>
                                  <button id="cancel" type="button" className="btn" onClick={resetForm} aria-describedby="Cancel Feedback">
                                    <span>Cancel</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </section>
                        )}
                        {feedbacks && feedbacks.length > 0 ? (
                          <div className="col-12 table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>User Details</th>
                                  <th>Feedback</th>
                                  <th>Edit/Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {feedbacks.map((feedback, index) => {
                                  const { _id, name, email, photo, message } = feedback;
                                  return (
                                    <tr key={_id}>
                                      <td>{index + 1}</td>
                                      <td className="userDetails">
                                        <img src={photo[0].url} className="img-fluid feedbackImg" alt={name} />
                                        <div className="textContainer">
                                          <h5>{name}</h5>
                                          <span>{email}</span>
                                        </div>
                                      </td>
                                      <td style={{ maxWidth: "300px" }}>
                                        <SanitizedHTML htmlContent={message} />
                                      </td>
                                      <td className="tableActions">
                                        <FaPenToSquare onClick={() => handleEdit(feedback)} style={{ cursor: "pointer", marginRight: "10px" }} />
                                        <FaTrashCan onClick={() => deleteFeedback(_id)} style={{ cursor: "pointer" }} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="col-lg-12 text-center noItemsDiv">
                            <FaCommentDots />
                            <h4>There are no Feedbacks posted by you</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default UserFeedback;
