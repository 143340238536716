import React from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import "./ReactTable.scss"; // Adjust the path based on your project structure
import { FaMagnifyingGlass } from "react-icons/fa6";

// Define a function to compare props and determine if re-rendering is necessary
const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.columns === nextProps.columns && prevProps.data === nextProps.data && prevProps.onUpdate === nextProps.onUpdate && prevProps.onDelete === nextProps.onDelete;
};

const ReactTable = React.memo(({ columns, data }) => {
  // Dynamically add a serial number column
  const modifiedColumns = React.useMemo(() => {
    return [
      {
        Header: "Sr.No",
        accessor: (row, index) => index + 1,
      },
      ...columns, // Include original columns
    ];
  }, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable({ columns: modifiedColumns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy, usePagination);

  return (
    <div className="col-lg-12 my-4 table-responsive-sm">
      <div className="mb-2 position-relative">
        <input className="padding-left-1" type="text" value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        <FaMagnifyingGlass className="searchIcon" />
      </div>
      <table {...getTableProps()} className="table cus_grid_tables">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted && <span>{column.isSortedDesc ? " 🔽" : " 🔼"}</span>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
          </strong>{" "}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>{" "}
      </div>
    </div>
  );
}, arePropsEqual);

export default ReactTable;
