import React from "react";
import "./AdminContent.scss";
const AdminContent = () => {
  return (
    <>
      <div className="col-lg-12">
        <h3>Admin Dashboard Content</h3>
      </div>
    </>
  );
};
export default AdminContent;
