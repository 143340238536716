import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaUser, FaPhoneAlt, FaCommentDots } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ContactUs = () => {
  const [auth] = useAuth();
  const [userDetails, setUserDetails] = useState({ email: "", name: "", mobileno: "" });
  const [formData, setFormData] = useState({
    name: "",
    mobileno: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      mobileno: "",
      message: "",
    });
  };

  useEffect(() => {
    if (auth?.user) {
      const { name = "", email = "", mobileno = "" } = auth.user;
      setUserDetails({ name, email, mobileno });
      setFormData({ name, email, mobileno, message: "" });
    }
  }, [auth?.user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.mobileno) newErrors.mobileno = "Phone is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const { name, email, mobileno, message } = formData;
    const data = { name, email, mobileno, message };
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/v1/contactus/createContactmsg`, data, {
        headers: { "Content-Type": "application/json" },
      });
      toast.success("Your message has been sent successfully!");
      resetForm();
      setErrors({});
    } catch (error) {
      toast.error("There was an error sending your message. Please try again later.");
    }
  };

  return (
    <>
      <Layout
        title={"Creto: Contact Us"}
        description={"Have questions or need support? Contact Creto for assistance and share your valuable feedback with us. We are here to help you."}
        keywords={"Creto contact, customer support, Creto feedback, contact us, customer service, support, inquiry, help, feedback"}>
        <div className="col-lg-12 cus_ContactUs">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Contact Us</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </section>
            <section className="contacts-top">
              <div className="container">
                <Toaster />
                <div className="row contact-top-cover">
                  <div className="col-12 col-lg-6 contact-img">
                    <img src={require("../../../assets/Img/cont-img-1.jpg")} alt="Contact" />
                  </div>
                  <div className="col-12 col-lg-6 contact-info">
                    <h2 className="title">Contact Information</h2>
                    <p>
                      Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit aperiam, eaque sed quia non numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam.
                    </p>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim Nor again is there anyone who loves or pursues or desires to obtain.
                    </p>
                    <div className="col-12 mt-4 text-center">
                      {userDetails.name && userDetails.email ? (
                        <Link to="/feedback" className="btn">
                          <span>Give Feedback</span>
                        </Link>
                      ) : (
                        <Link to="/Login" className="btn">
                          <span>Login to give Feedback</span>
                        </Link>
                      )}
                    </div>
                    <ul className="social-list">
                      <li>
                        <Link to="https://www.facebook.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
                          <FaInstagram />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.youtube.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
                          <FaYoutube />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="s-contacts-block">
              <div className="container">
                <div className="row contacts-block">
                  <div className="col-12 col-md-4">
                    <div className="contact-block-item">
                      <div className="contact-block-left">
                        <img src={require("../../../assets/Img/cont-icon-1.png")} alt="Need Help" />
                        <h6>Need Help</h6>
                      </div>
                      <div className="contact-block-right">
                        <ul>
                          <li>
                            <a href="tel:18004886040">1-800-488-6040</a>
                          </li>
                          <li>
                            <a href="tel:18005784090">1-800-578-4090</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="contact-block-item">
                      <div className="contact-block-left">
                        <img src={require("../../../assets/Img/cont-icon-2.png")} alt="Questions" />
                        <h6>Questions</h6>
                      </div>
                      <div className="contact-block-right">
                        <ul>
                          <li>
                            <a href="mailto:team@gmail.com">team@gmail.com</a>
                          </li>
                          <li>
                            <a href="mailto:help@gmail.com">help@gmail.com</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="contact-block-item">
                      <div className="contact-block-left">
                        <img src={require("../../../assets/Img/cont-icon-3.png")} alt="Address" />
                        <h6>Address</h6>
                      </div>
                      <div className="contact-block-right">
                        <ul>
                          <li>
                            <a className="item-scroll" href="#map">
                              8500, Lorem Street, Chicago, IL, 55030
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="s-contacts page-contacts">
              <div className="container s-anim">
                <h2 className="title">Contact Us</h2>
                <form id="contactform" name="contactform" onSubmit={handleSubmit}>
                  <ul className="form-cover">
                    <li className="position-relative inp-name">
                      <FaUser className="contactForm_icons" />
                      <input id="name" type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" autoComplete="off" required />
                      {errors.name && <span className="error">{errors.name}</span>}
                    </li>
                    <li className="position-relative inp-phone">
                      <FaPhoneAlt className="contactForm_icons" />
                      <input id="mobileno" type="tel" className="form-control" name="mobileno" value={formData.mobileno} onChange={handleChange} placeholder="Phone" autoComplete="off" required />
                      {errors.mobileno && <span className="error">{errors.mobileno}</span>}
                    </li>
                    <li className="position-relative inp-email">
                      <FaThreads className="contactForm_icons" />
                      <input id="email" type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="E-mail" autoComplete="off" required />
                      {errors.email && <span className="error">{errors.email}</span>}
                    </li>
                    <li className="position-relative inp-text">
                      <FaCommentDots className="contactForm_icons" />
                      <textarea id="comments" className="form-control" name="message" value={formData.message} onChange={handleChange} placeholder="Message" autoComplete="off" required />
                      {errors.message && <span className="error">{errors.message}</span>}
                    </li>
                  </ul>
                  <div className="btn-form-cover">
                    {auth?.user ? (
                      <button id="#submit" type="submit" className="btn">
                        <span>Submit Message</span>
                      </button>
                    ) : (
                      <Link to="/Login" className="btn">
                        <span>Login to send Message</span>
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactUs;
