import React, { useEffect, useState } from "react";
import "./Header.scss";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { FaEnvelope, FaHeart, FaUser, FaShoppingCart, FaSearch } from "react-icons/fa";
import CartSideBar from "./Cart/CartSideBar/CartSideBar";

import websitelogo from "../../../assets/Img/logo.svg";
import { useAuth } from "../../../context/auth";
import toast from "react-hot-toast";
import SearchInput from "../../SearchInput/SearchInput";
import { useCart } from "../../../context/cart";
import { useWishlist } from "../../../context/wishlist";

const Header = () => {
  const [showCart, setShowcart] = useState(false);
  //getting cart
  const { cart } = useCart();
  const { wishlist } = useWishlist();
  const [showSearch, setShowSearch] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const wishlistCount = wishlist.length;

  useEffect(() => {
    setShowSearch(false);
    setShowcart(false);
    document.body.style.overflowY = "auto";
  }, [navigate]);

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    localStorage.removeItem("wishlist"); // Clear wishlist from local storage
    localStorage.removeItem("cart"); // Clear cart from local storage
    toast.success("Logout Successfully");
  };

  const toggleCart = () => {
    setShowcart((prevShowCart) => {
      if (!prevShowCart) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
      return !prevShowCart;
    });
  };
  const toggleSearch = () => {
    setShowSearch((prevShowSearch) => {
      if (!prevShowSearch) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
      return !prevShowSearch;
    });
  };
  const closeSearch = () => {
    setShowSearch(false);
  };

  return (
    <>
      <header className="header">
        <div className="nav-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="top-panel">
          <div className="container">
            <div className="top-panel-cover">
              <ul className="header-cont">
                <li>
                  <a href="tel:+912345687">
                    <IoIosCall className="fa fa-phone" />
                    +9123 456 87
                  </a>
                </li>
                <li>
                  <a href="mailto:creto@gmail.com">
                    <FaEnvelope />
                    creto@gmail.com
                  </a>
                </li>
              </ul>
              <ul className="icon-right-list">
                {auth.user ? (
                  <li>
                    <Link to="/dashboard/user/Wishlist" className="header-like">
                      <FaHeart />
                      <span>{wishlistCount}</span>
                    </Link>
                  </li>
                ) : null}
                <li className="dropdown">
                  <button type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <FaUser />
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {!auth.user ? (
                      <>
                        <li>
                          <NavLink to="/Login" className="header-user dropdown-item">
                            Login
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/SignUp" className="header-user dropdown-item">
                            SignUp
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <NavLink onClick={(e) => e.preventDefault()} style={{ pointerEvents: "none" }}>
                            Welcome <span>{auth?.user?.name}</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Profile" className="header-user dropdown-item">
                            Profile
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/dashboard/${auth.user.role === 1 ? "admin" : "user"}`} className="header-user dropdown-item">
                            Dashboard
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Login" onClick={handleLogout} className="header-user dropdown-item">
                            Logout
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
                {auth.user ? (
                  <li>
                    <button className="header-cart" onClick={toggleCart}>
                      <FaShoppingCart />
                      <span>{cart.reduce((total, cart) => total + cart.quantity, 0)}</span>
                    </button>
                  </li>
                ) : null}
                <li>
                  <button className="header-search" onClick={toggleSearch}>
                    <FaSearch />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header-menu">
          <div className="container">
            <Link to="/" className="logo">
              <img src={websitelogo} alt="Creto" className="img-fluid" />
            </Link>
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav nav-list me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink exact="true" to="/" className="nav-link" activeclassname="active">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/Aboutus" className="nav-link" activeclassname="active">
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/Services" className="nav-link" activeclassname="active">
                        Services
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/Shop" className="nav-link" activeclassname="active">
                        Shop
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/Gallery" className="nav-link" activeclassname="active">
                        Gallery
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/News" className="nav-link" activeclassname="active">
                        News
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact="true" to="/ContactUs" className="nav-link" activeclassname="active">
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {showCart && <CartSideBar toggleCart={toggleCart} />}
      {showSearch && <SearchInput toggleSearch={toggleSearch} closeSearch={closeSearch} />}
    </>
  );
};
export default Header;
