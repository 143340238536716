import React, { useState } from "react";
import "./CartSideBar.scss";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useCart } from "../../../../../context/cart";
import { BsCartX, BsFillTrashFill } from "react-icons/bs";
const CartSideBar = ({ toggleCart }) => {
  const { cart, removeCartItem, subTotal } = useCart();
  const [isClosing, setIsClosing] = useState(false);

  const handleCartClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      toggleCart();
    }, 200);
  };

  return (
    <>
      <div className={`col-lg-12 p-0 cartDiv ${isClosing ? "closing" : ""}`}>
        <div className="cart__sidebar open-cart">
          <div className="container">
            <div className="row cart__content">
              <div className="col-12 cart-text">
                <div className="row">
                  <div className="col text-start">
                    <h3 className="mb-40">Shopping cart</h3>
                  </div>
                  <div className="col cart-icon text-end" onClick={handleCartClose}>
                    <IoClose />
                  </div>
                </div>
              </div>
              {cart.length > 0 ? (
                <div className="col-12 cart-Body">
                  {cart.map((product, index) => (
                    <>
                      <div className="add_cart_product" key={index}>
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 pe-0 add_cart_product__thumb">
                            <img src={product.photos[0].url} className="img-fluid" alt="" />
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 add_cart_product__content">
                            <h5>
                              <Link to={`/Shop/SingleProduct/${product.slug}`}>{product.name}</Link>
                            </h5>
                            <h6>{product.brand}</h6>
                            <p>
                              Quantity: {product.quantity} × $ {product.price}
                            </p>
                            <button className="cart_close" onClick={() => removeCartItem(product._id)}>
                              <BsFillTrashFill />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="col-12 cart-bottom">
                    <div className="cart-bottom__text">
                      <span className="subTotal">Subtotal:</span>
                      <span className="end">${subTotal}</span>
                      <Link to="/Cart" className="btn">
                        <span>View cart</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  <div className="col-lg-12 text-center emptySideBar">
                    <BsCartX />
                    <h4>Your cart is empty, please add products in your cart</h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cart-offcanvas-overlay open-cart-overlay"></div>
      </div>
    </>
  );
};

export default CartSideBar;
