import React from "react";
import "./ServicesCreation.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { FaPenToSquare, FaTrashCan } from "react-icons/fa6";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactTable from "../../../../ReactTable/ReactTable";
import { useServiceLogic } from "./ServiceLogic/ServiceLogic";
const ServicesCreation = () => {
  const { serviceData, services, isEditing, nameInputRef, handleEditClick, resetForm, handleChange, handleCreateService, handleDelete, handleUpdate } = useServiceLogic();

  return (
    <>
      <Layout title={"Creto: Services Page"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Services</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Dashboard/Admin">Admin</Link>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3">
                    <AdminMenu />
                  </div>
                  <div className="col-md-9 cus_forms">
                    <div className="row">
                      <div className="card">
                        <div className="card-header">
                          <h4>Service Section</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12">
                            <form onSubmit={isEditing ? handleUpdate : handleCreateService}>
                              <div className="mb-3 cus_form_inputs">
                                <label htmlFor="name" className="form-label">
                                  Service name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Enter service name"
                                  value={serviceData.name}
                                  onChange={handleChange}
                                  required
                                  autoComplete="off"
                                  ref={nameInputRef}
                                />
                              </div>
                              <div className="mb-3 cus_form_inputs">
                                <label htmlFor="photo" className="form-label">
                                  Service Image
                                </label>
                                <input type="file" className="form-control" id="photo" name="photo" onChange={handleChange} required={!isEditing} />
                              </div>
                              <div className="text-center my-4">
                                <button type="submit" className="btn">
                                  <span>{isEditing ? "Update Service" : "Create New Service"}</span>
                                </button>
                                {isEditing && (
                                  <button type="button" className="btn" onClick={resetForm}>
                                    <span>Cancel</span>
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                          <div className="services">
                            {services.length > 0 ? (
                              <div className="col-lg-12 table-responsive">
                                <h4>Services</h4>
                                <ReactTable
                                  data={services}
                                  columns={[
                                    {
                                      Header: "Name",
                                      accessor: "name",
                                    },
                                    {
                                      Header: "Actions",
                                      Cell: ({ row }) => (
                                        <div className="tableActions">
                                          <FaPenToSquare onClick={() => handleEditClick(row.original)} />
                                          <FaTrashCan onClick={() => handleDelete(row.original._id)} />
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <h4>No services to show</h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
      <Toaster />
    </>
  );
};

export default ServicesCreation;
