import React from "react";
import "./Subscribe.scss";
import { FaAt } from "react-icons/fa6";
import subscribeEffectSVG from "../../../../assets/Img/subscribe-effect.svg";
const Subscribe = () => {
  return (
    <>
      <section className="cus_subcribe_div s-subscribe" style={{ backgroundImage: `url(${require("../../../../assets/Img/bg-subscribe.jpg")})` }}>
        <span className="mask"></span>
        <span className="subscribe-effect wow fadeIn" data-wow-duration="1s" style={{ backgroundImage: `url(${subscribeEffectSVG})` }}></span>
        <div className="container">
          <div className="subscribe-left">
            <h2 className="title">
              <span>Subscribe</span>
            </h2>
            <p>Subscribe us and you won't miss the new arrivals, as well as discounts and sales.</p>
            <form className="subscribe-form">
              <FaAt className="at_icon" />
              <input className="inp-form" type="email" name="subscribe" placeholder="E-mail" />
              <button type="submit" className="subscribe_btn btn btn-form">
                <span>send</span>
              </button>
            </form>
          </div>
          <img className="wow fadeInRightBlur rx-lazy subscriberightImg" data-wow-duration=".8s" data-wow-delay=".3s" src={require("../../../../assets/Img/subscribe-img.png")} alt="img" />
        </div>
      </section>
    </>
  );
};
export default Subscribe;
