// useProductLogic.js
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const useProductLogic = () => {
  const { slug } = useParams();
  const [singleProduct, setSingleProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [bikeCategory, setBikeCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [colors, setColors] = useState([]);
  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [frame, setFrame] = useState("");
  const [brakeType, setBrakeType] = useState("");
  const [suspension, setSuspension] = useState("");
  const [frameSize, setFrameSize] = useState([]);
  const [wheelSize, setWheelSize] = useState([]);
  const [tires, setTires] = useState("");
  const [bikeGears, setBikeGears] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [stock, setStock] = useState(0);
  const [onSale, setOnSale] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [id, setId] = useState("");

  const getSingleProduct = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-product/${slug}`);
      const productData = response.data;

      if (productData?.success) {
        setSingleProduct(productData.product);
        setId(productData.product._id);
        setName(productData.product.name);
        setBikeCategory(productData.product.bikeCategory);
        setBrand(productData.product.brand);
        setGender(productData.product.gender);
        setPrice(productData.product.price);
        setWeight(productData.product.weight);
        setFrame(productData.product.frame);
        setColors(productData.product.colors);
        setPhotos(productData.product.photos || []);
        setFrameSize(productData.product.frameSize || []);
        setBikeGears(productData.product.bikeGears || []);
        setWheelSize(productData.product.wheelSize || []);
        setTires(productData.product.tires);
        setBrakeType(productData.product.brakeType);
        setSuspension(productData.product.suspension);
        setDescription(productData.product.description);
        setQuantity(productData.product.quantity);
        setStock(productData.product.stock);
        setOnSale(productData.product.onSale);
        setShipping(productData.product.shipping);
      } else {
        setError("Error: Unable to fetch product");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      setError("Error: Unable to fetch product");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
        if (data?.success) {
          setCategories(data.categories);
          setDefaultCategory(data.categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Something went wrong while getting categories");
      }
    };
    fetchCategories();
  }, []);

  const setDefaultCategory = (categories) => {
    const defaultCategory = categories.find((category) => category.categoryName === "Bikes");
    setProductCategory(defaultCategory?._id || "");
  };
  // const fallbackCategory = categories.length > 0 ? categories[0]._id : "";

  const resetFormFields = () => {
    setName("");
    setDescription("");
    setProductCategory("");
    setBikeCategory("");
    setBrand("");
    setGender("");
    setColors([]);
    setPrice(0);
    setWeight(0);
    setFrame("");
    setBrakeType("");
    setSuspension("");
    setFrameSize([]);
    setBikeGears([]);
    setWheelSize([]);
    setTires("");
    setPhotos([]);
    setQuantity(1);
    setStock(0);
    setOnSale(false);
    setShipping(false);

    const inputElement = document.getElementById("photos");
    if (inputElement) inputElement.value = ""; // This clears the selected files

    const checkboxElements = document.querySelectorAll('input[type="checkbox"]');
    checkboxElements.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setPhotos(Array.from(files));
  };

  const buildProductFormData = async () => {
    try {
      const productData = new FormData();

      productData.append("name", name);
      productData.append("description", description);
      productData.append("productCategory", productCategory);
      productData.append("bikeCategory", bikeCategory);
      productData.append("brand", brand);
      productData.append("gender", gender);
      productData.append("price", price.toString());
      productData.append("weight", weight.toString());
      productData.append("frame", frame);
      productData.append("tires", tires);
      productData.append("brakeType", brakeType);
      productData.append("suspension", suspension);
      appendFrameSizes(productData);
      appendBikeGears(productData);
      appendWheelSizes(productData);
      appendColors(productData, colors);
      productData.append("quantity", quantity.toString());
      productData.append("stock", stock.toString());
      productData.append("onSale", onSale.toString());
      productData.append("shipping", shipping.toString()); // Assuming shipping is a boolean

      // Append each photo file
      if (Array.isArray(photos)) {
        photos.forEach((photo) => {
          productData.append("photos", photo);
        });
      }
      return productData;
    } catch (error) {
      console.error("Error building product data:", error);
      return new FormData();
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validate numeric fields
    if (isNaN(Number(price)) || isNaN(Number(quantity)) || Number(quantity) <= 0) {
      toast.error("Price must be numeric, and quantity must be a positive number");
      return;
    }

    try {
      const productData = await buildProductFormData(); // Get updated product data

      const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/product/update-product/${slug}`, productData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data?.success) {
        toast.success("Product Updated Successfully");
        resetFormFields(); // Reset form fields here
        setProductCategory(categories.find((category) => category.categoryName === "Bikes")?._id || "");
      } else {
        if (data?.message) {
          toast.error(data.message);
        } else {
          toast.error(data?.message || "something went wrong");
        }
      }
    } catch (error) {
      console.error("Axios Error:", error);
      if (error.response) {
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      }
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const handleCancelUpdate = (e) => {
    e.preventDefault();
    resetFormFields(); // Clears or resets form fields
    window.history.back();
    setTimeout(() => {
      const windowHeight = window.innerHeight;
      const scrollPosition = windowHeight * 0.4; // 10% of the window height
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }, 100); // Adjust the delay as needed
  };
  useEffect(() => {
    const productListingElement = document.getElementById("listingTable");
    console.log(productListingElement);
    if (productListingElement) {
      productListingElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const appendColors = (productData, colors) => {
    colors.forEach((color, index) => {
      productData.append(`colors[${index}]`, color);
    });
  };
  const appendFrameSizes = (productData) => {
    const validFrameSizes = ["Small", "Medium", "Large"];
    frameSize.filter((size) => validFrameSizes.includes(size)).forEach((size) => productData.append("frameSize", size));
  };

  const appendBikeGears = (productData) => {
    const validBikeGears = [0, 19, 21, 24];
    bikeGears.filter((gear) => validBikeGears.includes(gear)).forEach((gear) => productData.append("bikeGears", gear));
  };

  const appendWheelSizes = (productData) => {
    const validWheelSizes = [14, 16, 18, 22, 26, 27.5, 29];
    wheelSize.filter((size) => validWheelSizes.includes(size)).forEach((size) => productData.append("wheelSize", size));
  };

  const handleFrameSize = (e) => {
    const { value, checked } = e.target;
    setFrameSize((prevSizes) => (checked ? [...prevSizes, parseInt(value)] : prevSizes.filter((size) => size !== parseInt(value))));
  };

  const handleWheelSize = (e) => {
    const { value, checked } = e.target;
    const selectedWheelSize = parseInt(value);
    setWheelSize((prevSizes) => (checked ? [...prevSizes, selectedWheelSize] : prevSizes.filter((size) => size !== selectedWheelSize)));
  };

  const handleGearNos = (e) => {
    const { value, checked } = e.target;
    const selectedGear = parseInt(value); // Parse the value to an integer
    setBikeGears((prevGears) => (checked ? [...prevGears, selectedGear] : prevGears.filter((gear) => gear !== selectedGear)));
  };
  const handleColorChange = (e, color) => {
    const { checked } = e.target;
    if (checked) {
      setColors((prevColors) => [...prevColors, color]); // Add the selected color to the array
    } else {
      // If the color is unchecked, remove it from the array
      setColors((prevColors) => prevColors.filter((c) => c !== color));
    }
  };

  return {
    id,
    setId,
    categories,
    productCategory,
    setProductCategory,
    bikeCategory,
    setBikeCategory,
    brand,
    setBrand,
    gender,
    setGender,
    name,
    setName,
    description,
    setDescription,
    colors,
    setColors,
    photos,
    setPhotos,
    price,
    setPrice,
    weight,
    setWeight,
    frame,
    setFrame,
    brakeType,
    setBrakeType,
    suspension,
    setSuspension,
    frameSize,
    setFrameSize,
    wheelSize,
    setWheelSize,
    tires,
    setTires,
    bikeGears,
    setBikeGears,
    quantity,
    setQuantity,
    stock,
    setStock,
    shipping,
    setShipping,
    onSale,
    setOnSale,
    resetFormFields,
    singleProduct,
    handleUpdate,
    handleFrameSize,
    handleWheelSize,
    handleGearNos,
    handleFileChange,
    handleColorChange,
    getSingleProduct,
    handleCancelUpdate,
    loading,
    error,
  };
};

export default useProductLogic;
