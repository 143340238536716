import React, { useState, useEffect } from "react";
import "./ProductsListing.scss";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Layout from "../../../../../Layout/Layout";
import AdminMenu from "../../../AdminMenu/AdminMenu";
import { Link } from "react-router-dom";
import ReactTable from "../../../../../ReactTable/ReactTable";
import Spinner from "../../../../../Spinner/Spinner";
import { FaPenToSquare, FaTrashCan, FaEye } from "react-icons/fa6";

const ProductsListing = () => {
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-products`);
      if (data?.success) {
        setProductsData(data.products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Error in getting all products");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (pid) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/product/delete-product/${pid}`);
      if (data?.success) {
        toast.success("Deleted Successfully");
        getAllProducts();
      }
    } catch (error) {
      console.error("Error while deleting category:", error);
      toast.error("Error while deleting Product");
    }
  };
  useEffect(() => {
    getAllProducts();
  }, []);
  return (
    <>
      <Layout title={"Creto: Products Listing"}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Products</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Dashboard/Admin">Admin</Link>
                    </li>
                    <li>Products</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <AdminMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cus_forms">
                      <div className="row">
                        <div className="card">
                          <Toaster />
                          <div className="card-header">
                            <h4>Products Listing</h4>
                          </div>
                          <div className="card-body">
                            <div className="col-lg-12 table-responsive" id="listingTable">
                              <ReactTable
                                data={productsData}
                                columns={[
                                  { Header: "Name", accessor: "name" },
                                  { Header: "Brand", accessor: "brand" },
                                  { Header: "Bike Category", accessor: "bikeCategory" },
                                  { Header: "Price", accessor: "price" },
                                  { Header: "Item Code", accessor: "itemCode" },
                                  { Header: "Available Stock", accessor: "stock" },
                                  {
                                    Header: "Actions",
                                    Cell: ({ row }) => (
                                      <div className="tableActions">
                                        <Link to={`/Shop/SingleProduct/${row.original.slug}`}>
                                          <FaEye />
                                        </Link>
                                        <Link to={`/dashboard/admin/ProductUpdation/${row.original.slug}`}>
                                          <FaPenToSquare />
                                        </Link>
                                        <Link>
                                          <FaTrashCan onClick={() => handleDelete(row.original._id)} />
                                        </Link>
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                              <div className="totalProducts">Total Products: {productsData.length}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default ProductsListing;
