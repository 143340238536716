import React, { useState, createContext, useContext, useEffect, useCallback } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [tax, setTax] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  useEffect(() => {
    try {
      const existingCartItem = localStorage.getItem("cart");
      if (existingCartItem) {
        setCart(JSON.parse(existingCartItem));
      }
    } catch (error) {
      console.error("Failed to load cart from local storage:", error);
      setCart([]);
    }
  }, []);

  useEffect(() => {
    let calculatedSubTotal = 0;
    cart.forEach((item) => {
      calculatedSubTotal += item.price * item.quantity;
    });
    setSubTotal(calculatedSubTotal);

    const calculatedTax = calculatedSubTotal * 0.02; // Calculate 2% tax
    setTax(calculatedTax);
  }, [cart]);

  const updateCart = (updatedCart) => {
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const increaseQuantity = useCallback(
    (productId) => {
      const updatedCart = cart.map((item) => (item._id === productId ? { ...item, quantity: item.quantity + 1 } : item));
      updateCart(updatedCart);
    },
    [cart]
  );

  const decreaseQuantity = useCallback(
    (productId) => {
      const updatedCart = cart.map((item) => (item._id === productId && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item));
      updateCart(updatedCart);
    },
    [cart]
  );

  const removeCartItem = useCallback(
    (productId) => {
      const updatedCart = cart.filter((item) => item._id !== productId);
      updateCart(updatedCart);
    },
    [cart]
  );

  const formatCurrency = (value) => {
    return value.toLocaleString("en-CA", {
      style: "currency",
      currency: "CAD",
    });
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        increaseQuantity,
        decreaseQuantity,
        removeCartItem,
        subTotal,
        tax,
        totalPrice: subTotal + tax,
        formattedSubTotal: formatCurrency(subTotal),
        formattedTax: formatCurrency(tax),
        formattedTotalPrice: formatCurrency(subTotal + tax),
      }}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
