import { useState, useEffect, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../../../../../context/auth";

const useUserFeedback = () => {
  const [auth] = useAuth(); // Assuming useAuth returns an array with auth as the first element
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    photo: null,
    message: "",
  });
  const [currentFeedbackId, setCurrentFeedbackId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const nameInputRef = useRef(null);

  const fetchFeedbacksByEmail = useCallback(async () => {
    setLoading(true); // Ensure loading is set to true at the start
    try {
      const email = auth?.user?.email;
      if (!email) {
        toast.error("User email is not available");
        setLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/feedback/getFeedbacks/${email}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (response.data.success) {
        const feedbacks = response.data.feedbacks;
        setFeedbacks(feedbacks);
      } else {
        toast.error("Failed to fetch feedbacks");
      }
    } catch (error) {
      console.log("Error while getting the feedbacks", error);
    } finally {
      setLoading(false);
    }
  }, [auth]);

  const deleteFeedback = async (feedbackId) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/feedback/deletefeedback/${feedbackId}`);
      if (data?.success) {
        toast.success("Selected feedback deleted successfully");
        fetchFeedbacksByEmail();
      }
    } catch (error) {
      console.log("Error while deleting feedback", error.message);
      toast.error("Error while deleting the feedback");
    }
  };

  const handleEdit = (feedback) => {
    setFormData({
      name: feedback.name,
      email: feedback.email,
      photo: feedback.photo[0],
      message: feedback.message,
    });
    setCurrentFeedbackId(feedback._id);
    setIsEditing(true);
    nameInputRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, photo: files[0] });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleCreateFeedback = async (e) => {
    e.preventDefault();
    const { name, photo, message } = formData;
    const email = auth?.user?.email;

    if (!name || !email || !photo || !message) {
      toast.error("All fields are required");
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("email", email); // Ensure email is included
    data.append("message", message);
    if (photo) data.append("photo", photo);

    console.log([...data.entries()]); // Log form data entries to check

    try {
      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/feedback/updateFeedback/${currentFeedbackId}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(response.data.message || "Feedback updated successfully");
      resetForm();
      fetchFeedbacksByEmail();
    } catch (error) {
      console.log("Error while updating the feedback", error.message);
      toast.error("Error while updating the feedback");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      photo: null,
      message: "",
    });
    setIsEditing(false);
    setCurrentFeedbackId(null);
    document.getElementById("photo").value = "";
  };

  useEffect(() => {
    if (auth && auth.user && auth.token) {
      fetchFeedbacksByEmail();
    }
  }, [auth, fetchFeedbacksByEmail]);

  return {
    feedbacks,
    loading,
    formData,
    isEditing,
    nameInputRef,
    handleChange,
    handleCreateFeedback,
    handleEdit,
    resetForm,
    deleteFeedback,
  };
};

export default useUserFeedback;
