import React, { useState, createContext, useContext } from "react";

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [searchState, setSearchState] = useState({
    keyword: "",
    results: [],
    isSearchBoxOpen: false,
  });

  return <SearchContext.Provider value={[searchState, setSearchState]}>{children}</SearchContext.Provider>;
};

const useSearch = () => useContext(SearchContext);

export { useSearch, SearchProvider };
