import React, { useEffect, useState } from "react";
import "./SingleProduct.scss";
import Layout from "../../Layout";
import Slider from "react-slick";
import { FaPlay, FaReply } from "react-icons/fa";
import videobg from "../../../../assets/Img/videobg.jpg";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import Spinner from "../../../Spinner/Spinner";
import useSingleProductLogic from "./singleProductLogic";
import { Link } from "react-router-dom";
import RelatedProduct from "../RelateProducts/RelatedProduct";
import toast, { Toaster } from "react-hot-toast";
import SanitizedHTML from "../../../SanitizedHTML/SanitizedHTML";
import { useCart } from "../../../../context/cart";

const SingleProduct = () => {
  const { cart, setCart } = useCart();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const {
    sliderSettings,
    handleFrameChange,
    handleGearChange,
    handleWheelsChange,
    handleColorsChange,
    loading,
    singleProduct,
    auth,
    RadioButton,
    selectedColors,
    selectedFrame,
    selectedGear,
    selectedWheels,
    wishlistAddItem,
  } = useSingleProductLogic();

  const handleIncreaseQuantity = () => {
    setSelectedQuantity((prev) => (prev < 5 ? prev + 1 : prev));
  };

  const handleDecreaseQuantity = () => {
    setSelectedQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleAddToCart = () => {
    const existingItem = cart.find((item) => item._id === singleProduct._id);
    if (existingItem) {
      const updatedCart = cart.map((item) => (item._id === singleProduct._id ? { ...item, quantity: selectedQuantity } : item));
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...singleProduct, quantity: selectedQuantity }]);
    }
    toast.success(`${singleProduct.name} added to cart with quantity ${selectedQuantity}`);
  };

  // It loads the cart state from localStorage if it exists
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, [setCart]);

  //updates the localStorage with the current state of the cart
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <Layout
      title={"Creto: Single Product Page"}
      description={"Want to buy bikes? You are at a great place - Creto online bike store"}
      keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Crtow, online store"}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="col-lg-12 cus_shopPage">
          <Toaster />
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Shop</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Shop">Shop</Link>
                  </li>
                  <li>{singleProduct.name}</li>
                </ul>
              </div>
            </section>
            <section className="s-single-product">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-5" id={singleProduct._id}>
                    <div className="slider-single-for">
                      {singleProduct && singleProduct.photos && (
                        <Slider {...sliderSettings}>
                          {singleProduct.photos.map((photo, index) => (
                            <div className="singleProdImg" key={index}>
                              <img className="img-fluid w-100 h-80" src={photo.url} alt={singleProduct.name} />
                            </div>
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-7 single-shop-left">
                    <h2 className="title">{singleProduct.name}</h2>
                    <h4 className="subTitle">By {singleProduct.brand}</h4>
                    <div className="row">
                      <div className="col-lg-8 single_product_desc">
                        <div className="single-price">
                          <div className="new-price">${singleProduct.price}</div>
                        </div>
                        <div className="single-color cus_form_inputs">
                          <div className="row">
                            <div className="col-12">
                              <label>Color:</label>
                            </div>
                            <div className="col-12 btn-group cus_check_btns" id="colors" role="group" aria-label="Basic checkbox toggle button group">
                              <div className="row">
                                {singleProduct.colors.map((color, index) => (
                                  <div className="col p-0 colors_btns" key={index}>
                                    <RadioButton id={color} value={color} onChange={() => handleColorsChange(color)} label={color} checked={selectedColors === color} />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-size cus_form_inputs">
                          <label>Frame Size:</label>
                          <div className="btn-group cus_check_btns" id="frameSize" role="group" aria-label="Basic checkbox toggle button group">
                            {singleProduct.frameSize.map((frame, index) => (
                              <div key={index}>
                                <RadioButton id={frame} value={frame} onChange={() => handleFrameChange(frame)} label={frame} checked={selectedFrame === frame} />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="frame-size cus_form_inputs">
                          <label>Bike Gears:</label>
                          <div className="btn-group cus_check_btns" id="frameSize" role="group" aria-label="Basic checkbox toggle button group">
                            {singleProduct.bikeGears.map((gear, index) => (
                              <div key={index}>
                                <RadioButton id={gear} value={gear} onChange={() => handleGearChange(gear)} label={gear} checked={selectedGear === gear} />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="wheel-size cus_form_inputs">
                          <label>Wheel Size:</label>
                          <div className="btn-group cus_check_btns" id="frameSize" role="group" aria-label="Basic checkbox toggle button group">
                            {singleProduct.wheelSize.map((wheels, index) => (
                              <div key={index}>
                                <RadioButton type="radio" id={wheels} value={wheels} onChange={() => handleWheelsChange(wheels)} label={wheels} checked={selectedWheels === wheels} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 single_product_pricing">
                        <div className="single-quanity">
                          <div className="qty_label px-2">
                            <label>Quantity:</label>
                          </div>
                          <div className="input-group d-flex cartInputGroup">
                            <button className="btn-outline-secondary" type="button" onClick={handleDecreaseQuantity}>
                              <RiArrowDropDownLine />
                            </button>
                            <input type="text" className="form-control" value={selectedQuantity} readOnly />
                            <button
                              className={selectedQuantity >= 5 ? "btn-outline-secondary disabled" : "btn-outline-secondary"}
                              type="button"
                              disabled={selectedQuantity >= 5}
                              onClick={handleIncreaseQuantity}>
                              <RiArrowDropUpLine />
                            </button>
                          </div>
                          {selectedQuantity >= 5 && (
                            <span className="maxQty">
                              Max 5 qty are allowed <br /> of the same product{" "}
                            </span>
                          )}
                        </div>
                        <div className="single-btn-cover">
                          <p className="product_price">
                            {selectedQuantity} x {singleProduct.price}: <span className="product_total">${selectedQuantity * singleProduct.price}</span>
                          </p>
                          {auth?.token && auth?.user?.name ? (
                            <>
                              <button className="btn btn-buy-now" onClick={handleAddToCart}>
                                <span>Add to Cart</span>
                              </button>
                              <button className="btn btn-wishlist" onClick={() => wishlistAddItem(singleProduct)}>
                                <span>Add to Wishlist</span>
                              </button>
                            </>
                          ) : (
                            <Link to="/Login" className="btn btn-buy-now">
                              <span>Login to add items to the cart</span>
                            </Link>
                          )}
                          <p className="stock_qty" style={{ color: singleProduct.stock <= 3 ? "red" : "green" }}>
                            {singleProduct.stock} Items left in Stock
                          </p>
                        </div>
                        <div className="seller-details">
                          <div className="row">
                            <div className="col-4 pe-0 cus_labels">
                              <p>Payment</p>
                            </div>
                            <div className="col-8 ps-0 cus_label_values">
                              <p>Secure transaction by Razor Pay</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 pe-0 cus_labels">
                              <p>Ships from</p>
                            </div>
                            <div className="col-8 ps-0 cus_label_values">
                              <p>DHL Shipping with free tracking</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 pe-0 cus_labels">
                              <p>Sold by</p>
                            </div>
                            <div className="col-8 ps-0 cus_label_values">
                              <p>Nimocodecrafts</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 pe-0 cus_labels">
                              <p>Returns</p>
                            </div>
                            <div className="col-8 ps-0 cus_label_values">
                              <p>Eligible for Return, Refund or replacement within 30 days of receipt</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2 single-shop-tabs">
                    <div className="tab-wrap">
                      <div>
                        <ul className="nav nav-tabs product-tabs" id="myTabs">
                          <li className="nav-item">
                            <span className="nav-link active" id="description" data-bs-toggle="tab" href="#descriptionContent">
                              Description
                            </span>
                          </li>
                          <li className="nav-item">
                            <span className="nav-link" id="reviews" data-bs-toggle="tab" href="#reviewsContent">
                              Reviews
                            </span>
                          </li>
                          <li className="nav-item">
                            <span className="nav-link" id="qas" data-bs-toggle="tab" href="#qasContent">
                              Q&amp;A
                            </span>
                          </li>
                        </ul>
                        <div className="tab-content mt-2">
                          <div className="tab-pane fade show active" id="descriptionContent">
                            <div className="row">
                              <div className="col-lg-6 description_div">
                                <div>
                                  <SanitizedHTML htmlContent={singleProduct.description} />
                                </div>
                                <div className="accordion" id="myAccordion">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading1">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                        Main Configuration
                                      </button>
                                    </h2>
                                    <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#myAccordion">
                                      <div className="accordion-body configurations">
                                        <p>
                                          <span>Bike Category:</span> {singleProduct.bikeCategory}
                                        </p>
                                        <p>
                                          <span>Frame Type:</span> {singleProduct.frame}
                                        </p>
                                        <p>
                                          <span>For:</span> {singleProduct.gender}
                                        </p>
                                        <p>
                                          <span>Suspensions:</span> {singleProduct.suspension}
                                        </p>
                                        <p>
                                          <span>Brake Type:</span> {singleProduct.brakeType}
                                        </p>
                                        <p>
                                          <span>Item Code:</span> {singleProduct.itemCode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading2">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                        Wheel Size
                                      </button>
                                    </h2>
                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#myAccordion">
                                      <div className="accordion-body configurations">
                                        <p>
                                          <span>Available Wheel Sizes: </span>
                                          {singleProduct.wheelSize.join(", ")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading3">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                        Tires
                                      </button>
                                    </h2>
                                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#myAccordion">
                                      <div className="accordion-body configurations">
                                        <p>
                                          <span>Tires: {singleProduct.tires}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="single-video">
                                  <Link
                                    to="https://www.youtube.com/watch?v=y7ln90AROlc&t=72s"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="popup-open play_video btn-video"
                                    style={{ backgroundImage: `url("${videobg}")` }}>
                                    <FaPlay className="fa fa-play" aria-hidden="true" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="reviewsContent">
                            <ul className="reviews-list">
                              <li className="item">
                                <div className="review-item">
                                  <div className="review-avatar">
                                    <img src={require("../../../../assets/Img/testimonials-1.png")} alt="img" />
                                  </div>
                                  <div className="review-content">
                                    <div className="name">Sam Piters</div>
                                    <div className="date">Dec 26, 2019</div>
                                    <p className="review-comment">
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis cupiditate vel dicta animi nostrum delectus at doloremque nam eligendi unde! Nulla temporibus
                                      ut, libero, architecto tempora impedit ipsa mollitia unde.
                                    </p>
                                    <button type="button" className="review-btn">
                                      <FaReply /> Reply
                                    </button>
                                  </div>
                                </div>
                                <ul className="child">
                                  <li className="item">
                                    <div className="review-item">
                                      <div className="review-avatar">
                                        <img src={require("../../../../assets/Img/testimonials-2.png")} alt="img" />
                                      </div>
                                      <div className="review-content">
                                        <div className="name">Anna Smith</div>
                                        <div className="date">Dec 27, 2019</div>
                                        <p className="review-comment">
                                          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla eligendi a cum corporis, minus reprehenderit quo aut at, quas quisquam?
                                        </p>
                                        <button type="button" className="review-btn">
                                          <FaReply /> Reply
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <div className="reviews-form">
                              <h3 className="title">Leave a Comment</h3>
                              <form action="https://html.rovadex.com/">
                                <div className="row">
                                  <div className="col-lg-6 mb-3">
                                    <input type="text" name="your-name" className="form-control" placeholder="Name" />
                                  </div>
                                  <div className="col-lg-6 mb-3">
                                    <input type="email" name="your-email" className="form-control" placeholder="E-mail" />
                                  </div>
                                  <div className="col-lg-12 mb-3">
                                    <textarea name="your-text" placeholder="Message" className="form-control" defaultValue={""} />
                                  </div>
                                </div>
                                <div className="checkbox-wrap">
                                  <div className="checkbox-cover">
                                    <input type="checkbox" />
                                    <p>By using this form you agree with the storage and handling of your data by this website.</p>
                                  </div>
                                </div>
                                <div className="col-12 text-center btn-form-cover">
                                  <button type="submit" className="btn">
                                    <span>Submit Comment</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="qasContent">
                            <div className="faq-item">
                              <h5 className="title">
                                <span>I already own a bike. Why do I need bike share?</span>
                              </h5>
                              <p>
                                The power of enabling one-way trips rather than a forced round trip due to having a personal vehicle is transformative. Bike share opens up mobility options that
                                weren’t previously convenient and makes public transit more viable. It also enables greater access to multi-modal trips where you may use bike share for the first part
                                of your trip, but may take the trolley, a car service or even catch a ride with a friend on the way home.
                              </p>
                            </div>
                            <div className="faq-item">
                              <h5 className="title">
                                <span>Tell me about the bikes.</span>
                              </h5>
                              <p>
                                Our smart-bikes from Social Bicycles (SoBi) have brains! This sets them apart from other bike-share systems. On the back of the bike is a GPS-enabled, solar-powered
                                panel with an on-board lock.
                              </p>
                              <p>
                                With this panel, you can check out the bike, unlock and lock it, put it on hold and report a problem. It will even let you know how many miles you rode and how many
                                calories you burned by logging into your Social Bicycles account.
                              </p>
                              <p>
                                One of our favorite features on our bikes is the chainless shaft drive. You won’t have to worry about your pants getting caught or getting greasy! They also have nifty
                                extras like 3 speeds, an adjustable seat post, front and rear lights that illuminate automatically, a large, full-sized basket, puncture resistant tires and a bell
                                (just above the left hand grip – give it a turn!).
                              </p>
                            </div>
                            <div className="faq-item">
                              <h5 className="title">
                                <span>What are Coast Hub Stations and Park and Go racks?</span>
                              </h5>
                              <p>A Coast Hub station is where you go to find, check out and return a bike. It’s equipped with bikes and racks that the bikes are locked to.</p>
                              <p>
                                Locking outside of a station will incur a $3 convenience fee. You’ll also see designated Park and Go racks at businesses around town. This is free Coast parking, so
                                feel free to park here as well!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-12 p-0 relatedProducts">
                    <RelatedProduct singleProduct={singleProduct} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SingleProduct;
