import React from "react";
import "./TopSale.scss";
const TopSale = () => {
  return (
    <>
      <section className="s-top-sale">
        <div className="container">
          <h2 className="title">Top sale</h2>
          <div className="row product-cover">
            <div className="col-sm-6 col-lg-3">
              <div className="product-item">
                <ul className="product-icon-top">
                  <li>
                    <a>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <a href="single-shop.html" className="product-img">
                  <img className="rx-lazy" src={require("../../../../assets/Img/prod-9.png")} alt="product" />
                </a>
                <div className="product-item-cover">
                  <div className="price-cover">
                    <div className="new-price">$1.699</div>
                    <div className="old-price">$1.799</div>
                  </div>
                  <h6 className="prod-title">
                    <a href="single-shop.html">
                      Granite Peak 24" <br />
                      Girls Mountain Bike
                    </a>
                  </h6>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="prod-info">
                  <ul className="prod-list">
                    <li>
                      Frame Size: <span>17</span>
                    </li>
                    <li>
                      className: <span>City</span>
                    </li>
                    <li>
                      Number of speeds: <span>7</span>
                    </li>
                    <li>
                      Type: <span>Rigid</span>
                    </li>
                    <li>
                      Country registration: <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="product-item">
                <ul className="product-icon-top">
                  <li>
                    <a>
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <a href="single-shop.html" className="product-img">
                  <img className="rx-lazy" src={require("../../../../assets/Img/prod-10.png")} alt="product" />
                </a>
                <div className="product-item-cover">
                  <div className="price-cover">
                    <div className="new-price">$1.499</div>
                    <div className="old-price">$1.799</div>
                  </div>
                  <h6 className="prod-title">
                    <a href="single-shop.html">
                      Granite Peak 24" <br />
                      Girls Mountain Bike
                    </a>
                  </h6>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="prod-info">
                  <ul className="prod-list">
                    <li>
                      Frame Size: <span>17</span>
                    </li>
                    <li>
                      className: <span>City</span>
                    </li>
                    <li>
                      Number of speeds: <span>7</span>
                    </li>
                    <li>
                      Type: <span>Rigid</span>
                    </li>
                    <li>
                      Country registration: <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="product-item">
                <ul className="product-icon-top">
                  <li>
                    <a>
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <a href="single-shop.html" className="product-img">
                  <img className="rx-lazy" src={require("../../../../assets/Img/prod-11.png")} alt="product" />
                </a>
                <div className="product-item-cover">
                  <div className="price-cover">
                    <div className="new-price">$1.699</div>
                    <div className="old-price">$1.799</div>
                  </div>
                  <h6 className="prod-title">
                    <a href="single-shop.html">
                      Granite Peak 24" <br />
                      Girls Mountain Bike
                    </a>
                  </h6>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="prod-info">
                  <ul className="prod-list">
                    <li>
                      Frame Size: <span>17</span>
                    </li>
                    <li>
                      className: <span>City</span>
                    </li>
                    <li>
                      Number of speeds: <span>7</span>
                    </li>
                    <li>
                      Type: <span>Rigid</span>
                    </li>
                    <li>
                      Country registration: <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="product-item">
                <ul className="product-icon-top">
                  <li>
                    <a>
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <a href="single-shop.html" className="product-img">
                  <img className="rx-lazy" src={require("../../../../assets/Img/prod-12.png")} alt="product" />
                </a>
                <div className="product-item-cover">
                  <div className="price-cover">
                    <div className="new-price">$1.499</div>
                    <div className="old-price">$1.799</div>
                  </div>
                  <h6 className="prod-title">
                    <a href="single-shop.html">
                      Granite Peak 24" <br />
                      Girls Mountain Bike
                    </a>
                  </h6>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="prod-info">
                  <ul className="prod-list">
                    <li>
                      Frame Size: <span>17</span>
                    </li>
                    <li>
                      className: <span>City</span>
                    </li>
                    <li>
                      Number of speeds: <span>7</span>
                    </li>
                    <li>
                      Type: <span>Rigid</span>
                    </li>
                    <li>
                      Country registration: <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TopSale;
