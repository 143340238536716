import React, { useRef, useState, useEffect } from "react";
import "./Slider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import Slider from "react-slick";
import bgsliderimg1 from "../../../../assets/Img/bg-slider-1.svg";
import bgsliderimg2 from "../../../../assets/Img/bg-slider-2.svg";
import bgsliderimg3 from "../../../../assets/Img/bg-slider-3.svg";
import { Link } from "react-router-dom";

const Carouselslider = () => {
  const sliderRef = useRef(null);
  const [sliderState, setSliderState] = useState(null);
  const CustomNextArrow = () => (
    <div className="custom-slick-arrow custom-next-arrow" onClick={() => sliderState.slickNext()}>
      <FaAngleDown />
    </div>
  );

  const CustomPrevArrow = () => (
    <div className="custom-slick-arrow custom-prev-arrow" onClick={() => sliderState.slickPrev()}>
      <FaAngleUp />
    </div>
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    dots: true,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div role="toolbar">
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            display: "flex",
            flexDirection: "column", // Make the dots vertical
          }}>
          <CustomPrevArrow />
          {dots}
          <CustomNextArrow />
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <button type="button" data-role="none">
        0{i + 1}
      </button>
    ),
  };
  useEffect(() => {
    setSliderState(sliderRef.current);
  }, [sliderRef]);

  return (
    <>
      <section className="s-main-slider">
        <ul className="main-soc-list">
          <li>
            <Link to="https://www.facebook.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
              facebook
            </Link>
          </li>
          <li>
            <Link to="https://twitter.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
              twitter
            </Link>
          </li>
          <li>
            <Link to="https://www.instagram.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
              instagram
            </Link>
          </li>
        </ul>

        <div className="main-slider">
          <Slider ref={sliderRef} {...sliderSettings}>
            <div className="main-slide">
              <div className="container">
                <div className="main-slide-info">
                  <h2 className="title">best bikes for you</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="slide-img-cover">
                  <a href="single-shop.html" className="lable-bike">
                    <div className="lable-bike-img">
                      <img src={require("../../../../assets/Img/bike-info-slide.jpg")} alt="img" />
                    </div>
                    <div className="lable-bike-item">
                      <div className="model">model SX-200</div>
                      <div className="price">$1399</div>
                    </div>
                  </a>
                  <img src={require("../../../../assets/Img/img-slider.png")} alt="img" className="img-fluid slide-img" />
                </div>
                <div className="main-slide-bg">
                  <img src={bgsliderimg1} alt="bg-img" className="slide_bgImg" />
                </div>
              </div>
            </div>
            <div className="main-slide">
              <div className="container">
                <div className="main-slide-info">
                  <h2 className="title">best bikes for you</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore consectetur sint iure id expedita saepe.</p>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="slide-img-cover">
                  <a href="single-shop.html" className="lable-bike">
                    <div className="lable-bike-img">
                      <img src={require("../../../../assets/Img/bike-info-slide.jpg")} alt="img" />
                    </div>
                    <div className="lable-bike-item">
                      <div className="model">model M-300</div>
                      <div className="price">$1199</div>
                    </div>
                  </a>
                  <img src={require("../../../../assets/Img/img-slider-2.png")} alt="img" className="img-fluid slide-img" />
                </div>
                <div className="main-slide-bg">
                  <img src={bgsliderimg2} alt="bg-img" className="slide_bgImg" />
                </div>
              </div>
            </div>
            <div className="main-slide">
              <div className="container">
                <div className="main-slide-info">
                  <h2 className="title">best bikes for you</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio tenetur ab ut! Qui, facilis.</p>
                  <a href="single-shop.html" className="btn">
                    <span>buy now</span>
                  </a>
                </div>
                <div className="slide-img-cover">
                  <a href="single-shop.html" className="lable-bike">
                    <div className="lable-bike-img">
                      <img src={require("../../../../assets/Img/bike-info-slide.jpg")} alt="img" />
                    </div>
                    <div className="lable-bike-item">
                      <div className="model">model X-230</div>
                      <div className="price">$1099</div>
                    </div>
                  </a>
                  <img src={require("../../../../assets/Img/img-slider-3.png")} alt="img" className="img-fluid slide-img" />
                </div>
                <div className="main-slide-bg">
                  <img src={bgsliderimg3} alt="bg-img" className="slide_bgImg" />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};
export default Carouselslider;
