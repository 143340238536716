import React from "react";
import "./Services.scss";
import Layout from "../Layout";
import ServiceTabs from "../Home/ServiceTabs/ServiceTabs";
import Advantages from "../Home/Advantages/Advantages";
import Feedback from "../Home/Feedback/Feedback";
import TopSale from "../Home/TopSale/TopSale";
const Services = () => {
  return (
    <>
      <Layout>
        <div className="col-lg-12 cus_servicePage">
          <div className="row"></div>
          <section className="s-header-title">
            <div className="container">
              <h1>Services</h1>
              <ul className="breadcrumbs">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Services</li>
              </ul>
            </div>
          </section>
          <div className="col-lg-12 text-center cus_services_heading">
            <h2 className="title">Our Services</h2>
          </div>
          <ServiceTabs />
          <Advantages />
          <TopSale />
          <Feedback />
        </div>
      </Layout>
    </>
  );
};
export default Services;
