import React from "react";
import "./AboutUsCreation.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import TextEditor from "../../../../TextEditor/TextEditor";
import { useAboutUsLogic } from "./AboutUsLogic/AboutUsLogic";
import ReactTable from "../../../../ReactTable/ReactTable";
import { FaPenToSquare, FaTrashCan } from "react-icons/fa6";
import SanitizedHTML from "../../../../SanitizedHTML/SanitizedHTML"; // Ensure this path is correct

const AboutUsCreation = () => {
  const { aboutUsData, aboutusPosts, isEditing, handleChange, handleCreatePost, handleDelete, handleUpdate, handleEdit, resetForm, nameInputRef } = useAboutUsLogic();

  const handleEditClick = (postId) => {
    const postToEdit = aboutusPosts.find((post) => post._id === postId);
    handleEdit(postToEdit);
    setTimeout(() => {
      nameInputRef.current.scrollIntoView({ behavior: "smooth" });
      nameInputRef.current.focus();
    }, 400);
  };

  return (
    <Layout title={"Creto: About us post creation page"}>
      <div className="col-lg-12 cus_shopPage">
        <div className="row">
          <section className="s-header-title">
            <div className="container">
              <h1>About us</h1>
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Dashboard/Admin">Admin</Link>
                </li>
                <li>About us</li>
              </ul>
            </div>
          </section>
          <section className="s-shop">
            <div className="container-fluid px-4">
              <div className="row">
                <div className="col-lg-2 col-md-3">
                  <AdminMenu />
                </div>
                <div className="col-lg-10 col-md-9 cus_forms">
                  <div className="row">
                    <Toaster />
                    <div className="card">
                      <div className="card-header">
                        <h4>About Us Section</h4>
                      </div>
                      <div className="card-body">
                        <div className="col-lg-12">
                          <form onSubmit={isEditing ? handleUpdate : handleCreatePost}>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 mb-3 cus_form_inputs">
                                <label htmlFor="name" className="form-label">
                                  Post Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Enter post title"
                                  value={aboutUsData.name}
                                  onChange={handleChange}
                                  required
                                  autoComplete="off"
                                  ref={nameInputRef}
                                />
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-3 cus_form_inputs">
                                <label htmlFor="description" className="form-label">
                                  Post Description
                                </label>
                                <TextEditor
                                  id="description"
                                  value={aboutUsData.description}
                                  onChange={(value) => handleChange({ target: { id: "description", value } })}
                                  placeholder="Write about us post description"
                                  required
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-lg-12 mb-3 cus_form_inputs">
                                <label htmlFor="photo" className="form-label">
                                  Photos
                                </label>
                                <input type="file" className="form-control" id="photo" name="photo" onChange={handleChange} required={!isEditing} aria-describedby="Post Photos" />
                              </div>
                              <div className="mb-3">
                                <div className="text-center my-4">
                                  <button type="submit" className="btn" aria-label={isEditing ? "Update Post" : "Create Post"}>
                                    <span>{isEditing ? "Update Post" : "Create Post"}</span>
                                  </button>
                                  {isEditing && (
                                    <button type="button" className="btn" onClick={resetForm} aria-label="Cancel">
                                      <span>Cancel</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="team-members">
                          <h4>About Us Posts</h4>
                          <div className="col-lg-12 table-responsive">
                            <ReactTable
                              data={aboutusPosts}
                              columns={[
                                { Header: "Name", accessor: "name" },
                                { Header: "Description", accessor: "description", Cell: ({ value }) => <SanitizedHTML htmlContent={value} /> },
                                {
                                  Header: "Actions",
                                  Cell: ({ row }) => (
                                    <div>
                                      <FaPenToSquare onClick={() => handleEditClick(row.original._id)} />
                                      <FaTrashCan onClick={() => handleDelete(row.original._id)} />
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUsCreation;
