import React, { useState } from "react";
import "./ForgotPassword.scss";
import Layout from "../../Layout";
import { FaUser, FaLock } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../../../context/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [answer, setAnswer] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleForgotpassword = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/forgotPassword`, { email, answer, newPassword });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        //to store data on local storage we created a auth variable and then store data in string format
        setTimeout(() => {
          navigate(location.state || "/login");
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <Layout title={"Creto: Login Page"} description={"Enter your details to Login to Creto online bike store"} keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Crtow, online store"}>
        <div className="col-12 cus_Login">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Forgot Password</h1>
                <ul className="breadcrumbs">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Forgot Password</li>
                </ul>
              </div>
            </section>
            <section className="s-login">
              <div className="col-lg-4 mx-auto cus-login-page">
                <form onSubmit={handleForgotpassword}>
                  <Toaster />
                  <div className="row">
                    <div className="col-lg-12 mb-4 text-start heading">
                      <h3>Enter your credentials to login</h3>
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="email">
                        Email <span className="required_red">*</span>
                      </label>
                      <FaUser className="cus-input-icons" />
                      <input type="email" name="" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter your email" />
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="securityAnswer">
                        <FaLock className="cus-input-icons" />
                        Security question answer <span className="required_red">*</span>
                      </label>
                      <input type="text" name="" id="securityAnswer" value={answer} onChange={(e) => setAnswer(e.target.value)} className="form-control" placeholder="Enter your security answer" />
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="password">
                        <FaLock className="cus-input-icons" />
                        New Password <span className="required_red">*</span>
                      </label>
                      <input
                        type="password"
                        name=""
                        id="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="form-control"
                        placeholder="Enter your security answer"
                      />
                    </div>

                    <div className="col-lg-12 text-center cus_form_inputs">
                      <button type="submit" className="btn">
                        <span>Reset password</span>
                      </button>
                    </div>
                    {/* <div className="col-lg-12 text-center mt-5 cus_form_inputs">
                      <div className="row">
                        <div className="col text-start cus_anchors">
                          <a>Lost your password ?</a>
                        </div>
                        <div className="col text-end cus_anchors">
                          <NavLink to="/SignUp">Sign Up</NavLink>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ForgotPassword;
