import React, { createContext, useContext, useState, useEffect } from "react";

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    try {
      const existingWishlistItems = localStorage.getItem("wishlist");
      if (existingWishlistItems) {
        setWishlist(JSON.parse(existingWishlistItems));
      }
    } catch (error) {
      console.error("Failed to parse wishlist items from local storage:", error);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const addWishlistItem = (item) => {
    if (!wishlist.some((wItem) => wItem.id === item.id)) {
      setWishlist((prev) => [...prev, item]);
    }
  };

  const removeWishlistItem = (itemId) => {
    setWishlist((prev) => prev.filter((item) => item.id !== itemId));
  };

  const clearWishlist = () => {
    setWishlist([]);
  };

  const isItemInWishlist = (itemId) => {
    return wishlist.some((item) => item.id === itemId);
  };

  return <WishlistContext.Provider value={{ wishlist, setWishlist, addWishlistItem, removeWishlistItem, clearWishlist, isItemInWishlist }}>{children}</WishlistContext.Provider>;
};

export const useWishlist = () => {
  return useContext(WishlistContext);
};
