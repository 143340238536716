import { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export const useServiceLogic = () => {
  const [serviceData, setServiceData] = useState({
    name: "",
    photo: null,
  });
  const [services, setServices] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentServiceID, setCurrentServiceID] = useState(null);
  const nameInputRef = useRef(null);

  const handleEditClick = (service) => {
    handleEdit(service);
    setTimeout(() => {
      nameInputRef.current.scrollIntoView({ behavior: "smooth" });
      nameInputRef.current.focus();
    }, 400);
  };
  const resetForm = () => {
    setServiceData({
      name: "",
      photo: null,
    });
    setIsEditing(false);
    setCurrentServiceID(null);
    if (document.getElementById("photo")) {
      document.getElementById("photo").value = "";
    }
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setServiceData({ ...serviceData, photo: files[0] });
    } else {
      setServiceData({ ...serviceData, [id]: value });
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/service/get-services`);
      setServices(response.data.services); // Corrected to set the services state
    } catch (error) {
      console.error("Error while fetching services", error.message);
      toast.error("Error while fetching services");
    }
  };

  const handleCreateService = async (e) => {
    e.preventDefault();
    const { name, photo } = serviceData;
    if (!name || !photo) {
      toast.error("Name and Photo are required");
      return;
    }
    const data = new FormData();
    data.append("name", name);
    data.append("photo", photo);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/service/create-service`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      resetForm();
      fetchServices();
    } catch (error) {
      console.error("Error while creating service", error.message);
      toast.error(error.response?.data?.message || "Error while creating service");
    }
  };

  const handleDelete = async (serviceID) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/service/delete-service/${serviceID}`);
      if (data?.success) {
        toast.success("Successfully deleted service");
        resetForm();
        fetchServices();
      }
    } catch (error) {
      console.error("Error while deleting service", error.message);
      toast.error("Error while deleting service");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { name, photo } = serviceData;
    if (!name) {
      toast.error("Name is required");
      return;
    }
    const data = new FormData();
    data.append("name", name);
    if (photo) data.append("photo", photo);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/service/update-service/${currentServiceID}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      resetForm();
      fetchServices();
    } catch (error) {
      console.error("Error while updating the service", error.message);
      toast.error("Error while updating the service");
    }
  };

  const handleEdit = (service) => {
    setServiceData({ name: service.name, photo: null });
    setIsEditing(true);
    setCurrentServiceID(service._id);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return {
    serviceData,
    services,
    isEditing,
    currentServiceID,
    nameInputRef,
    handleEditClick,
    resetForm,
    handleChange,
    handleCreateService,
    handleDelete,
    handleUpdate,
    handleEdit,
    fetchServices,
  };
};
