import React from "react";
import "./AdminDashboard.scss";
import Layout from "../../Layout/Layout";
import AdminMenu from "./AdminMenu/AdminMenu";
import AdminContent from "./AdminContent/AdminContent";
import { Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <>
      <Layout
        title={"Creto: Admin Dashboard"}
        description={"Welcome to Creto: Admin dashboard, here you can access Products, Categories, Services, Advantages and News"}
        keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Crtow, online store"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Dashboard</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Dashboard/Admin">Admin</Link>
                  </li>
                  <li>Dashboard</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-lg-3 shop-sidebar">
                    <AdminMenu />
                  </div>
                  <div className="col-12 col-lg-9 admin-content">
                    <AdminContent />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Dashboard;
