import React from "react";
import "./Advantages.scss";
const Advantages = () => {
  return (
    <>
      <section className="s-our-advantages" style={{ backgroundImage: `url(${require("../../../../assets/Img/bg-advantages.jpg")})` }}>
        <span className="mask"></span>
        <div className="container">
          <h2 className="title">Our Advantages</h2>
          <div className="our-advantages-wrap">
            <div className="col-lg-3 col-xs-2 our-advantages-item">
              <img className="rx-lazy" src={require("../../../../assets/Img/advantages-1.png")} alt="icon" />
              <h5>
                Free shipping <br />
                from $500
              </h5>
            </div>
            <div className="col-lg-3 col-xs-2 our-advantages-item">
              <img className="rx-lazy" src={require("../../../../assets/Img/advantages-2.png")} alt="icon" />
              <h5>
                Warranty service <br />
                for 3 months
              </h5>
            </div>
            <div className="col-lg-3 col-xs-2 our-advantages-item">
              <img className="rx-lazy" src={require("../../../../assets/Img/advantages-3.png")} alt="icon" />
              <h5>
                Exchange and return <br />
                within 14 days
              </h5>
            </div>
            <div className="col-lg-3 col-xs-2 our-advantages-item">
              <img className="rx-lazy" src={require("../../../../assets/Img/advantages-4.png")} alt="icon" />
              <h5>
                Discounts for <br />
                customers
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Advantages;
