import { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export const useAboutUsLogic = () => {
  const [aboutUsData, setAboutUsData] = useState({
    name: "",
    description: "",
    photo: null,
  });
  const [aboutusPosts, setAboutusPosts] = useState([]); // Ensure it's initialized as an empty array
  const [isEditing, setIsEditing] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const nameInputRef = useRef(null);

  const resetForm = () => {
    setAboutUsData({
      name: "",
      description: "",
      photo: null,
    });
    setIsEditing(false);
    setCurrentPostId(null);
    document.getElementById("photo").value = "";
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target || {}; // Adjust to handle both regular inputs and custom TextEditor inputs
    if (files) {
      setAboutUsData({ ...aboutUsData, photo: files[0] });
    } else {
      setAboutUsData({ ...aboutUsData, [id]: value });
    }
  };

  const fetchAboutUsPosts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/aboutus/get-aboutusposts`);
      resetForm();
      setAboutusPosts(response.data.aboutusPosts); // Ensure it's always an array
    } catch (error) {
      console.log(error);
      toast.error("Error fetching about us posts");
    }
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    const { name, description, photo } = aboutUsData;

    if (!name || !description || !photo) {
      toast.error("Name, description, and photo are required");
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    data.append("photo", photo);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/aboutus/create-aboutuspost`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      resetForm();
      fetchAboutUsPosts();
    } catch (error) {
      console.log("Error while creating the about us post", error.message);
      toast.error(error.response?.data?.message || "Error while creating the about us post");
    }
  };

  const handleDelete = async (postId) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/aboutus/delete-aboutuspost/${postId}`);
      if (data?.success) {
        toast.success("Successfully deleted the post");
        resetForm();
        fetchAboutUsPosts();
      }
    } catch (error) {
      console.log("Error while deleting the post", error.message);
      toast.error("Error while deleting the post");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { name, description, photo } = aboutUsData;
    if (!name || !description) {
      toast.error("Name and description are required");
      return;
    }
    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    if (photo) data.append("photo", photo);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/aboutus/update-aboutpost/${currentPostId}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(response.data.message);
      resetForm();
      fetchAboutUsPosts();
    } catch (error) {
      console.log("Error while editing the post", error.message);
      toast.error("Error while editing the post");
    }
  };

  const handleEdit = (aboutUsPost) => {
    setAboutUsData({
      name: aboutUsPost.name,
      description: aboutUsPost.description,
      photo: null,
    });
    setIsEditing(true);
    setCurrentPostId(aboutUsPost._id);
  };

  useEffect(() => {
    fetchAboutUsPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    aboutUsData,
    aboutusPosts,
    isEditing,
    currentPostId,
    handleChange,
    handleCreatePost,
    handleDelete,
    handleUpdate,
    handleEdit,
    resetForm,
    nameInputRef,
  };
};
