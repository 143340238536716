import React, { useState } from "react";
import "./SignUp.scss";
import Layout from "../../Layout";
import { NavLink, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/signup`, { name, email, password, confirmPassword, mobileno, dob, answer, address });
      // toast.success("You have signed up successfully");

      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <Layout
        title={"Creto: Sign Up Page"}
        description={"New User ? Enter your details for Sign Up on Creto online bike store"}
        keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Creto, online store"}>
        <div className="col-12 cus_SignUp">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Sign Up</h1>
                <ul className="breadcrumbs">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Sign Up</li>
                </ul>
              </div>
            </section>
            <section className="s-login">
              <div className="col-xl-8 col-lg-12 mx-auto cus-login-page">
                <form onSubmit={handleSubmit}>
                  <Toaster />
                  <div className="row">
                    <div className="col-12 mb-4 text-start heading">
                      <h3>Enter your details for sign up</h3>
                    </div>

                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="name">
                        Name <span className="required_red">*</span>
                      </label>
                      <input type="text" name="" id="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter your name" required autocomplete="off" />
                    </div>
                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="emailId">
                        Email <span className="required_red">*</span>
                      </label>
                      <input
                        type="email"
                        name=""
                        id="emailId"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="password">
                        Password <span className="required_red">*</span>
                      </label>
                      <input
                        type="password"
                        name=""
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        placeholder="Enter your password"
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="confirmPassword">
                        Confirm password <span className="required_red">*</span>
                      </label>
                      <input
                        type="password"
                        name=""
                        id="confirmPassword"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        className="form-control"
                        placeholder="Enter your password"
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="mobileNo">
                        Mobile Number <span className="required_red">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id="mobileNo"
                        onChange={(e) => setMobileno(e.target.value)}
                        value={mobileno}
                        className="form-control"
                        placeholder="Enter your Mobile no."
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-6 mb-4 cus_form_inputs">
                      <label htmlFor="userDob">
                        Date of birth <span className="required_red">*</span>
                      </label>
                      <input
                        type="date"
                        name=""
                        id="userDob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        className="form-control"
                        placeholder="Enter your Mobile no."
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="answer">
                        Security Ques: Your favourite bike name ? <span className="required_red">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id="answer"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        className="form-control"
                        placeholder="Enter your security question answer"
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-12 mb-4 cus_form_inputs">
                      <label htmlFor="userAddress">
                        Address <span className="required_red">*</span>
                      </label>
                      <textarea
                        name=""
                        id="userAddress"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="form-control cus-textarea"
                        placeholder="Enter your address"
                        required
                        autocomplete="off"
                      />
                    </div>
                    <div className="col-lg-12 text-center cus_form_inputs">
                      <button type="submit" className="btn">
                        <span>Sign up</span>
                      </button>
                    </div>
                    <div className="col-lg-12 text-center mt-5 cus_form_inputs">
                      <div className="row">
                        <div className="col text-end cus_anchors">
                          <NavLink to="/Login">Already have an account? Sign In </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default SignUp;
