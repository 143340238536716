import React, { useState, useEffect } from "react";
import "./Products.scss";
import { FaHeart } from "react-icons/fa6";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner from "../../../Spinner/Spinner";
import { Link } from "react-router-dom";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const { data: categoriesData } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
      if (categoriesData?.success) {
        setCategories(categoriesData.categories);
      }
    } catch (error) {
      console.log("Error while fetching categories", error);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getProducts = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-products`);

      if (data?.success) {
        setProducts(data.products.slice(0, 8));
      }
    } catch (error) {
      console.log("Error while getting products", error);
      toast.error("Error while getting products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    fetchCategories();
  }, []);

  const filteredProducts = products.filter((product) => {
    if (activeTab === "All") return true;
    return product.category === activeTab;
  });

  const tabHeaders = ["All", "Road Bike"];

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <section className="s-products">
          <div className="container">
            <div className="tab-wrap">
              <div className="products-title-cover">
                <h2 className="title">Our Products</h2>
                <ul className="tab-nav product-tabs">
                  {tabHeaders.map((tab, index) => (
                    <li key={index} className={`tab-header ${activeTab === tab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                      <span>{tab}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="tabs-content">
                <div className="tab-pane active">
                  <div className="row product-cover">
                    {products.map((product) => {
                      const { _id, name, slug, price, photos, bikeCategory, bikeGears, gender, frame, weight } = product;
                      return (
                        <div className="col-sm-6 col-lg-3" key={_id}>
                          <Link to={`/Shop/SingleProduct/${slug}`}>
                            <div className="product-item">
                              {product.onSale && <span className="top-sale">On Sale</span>}
                              <ul className="product-icon-top">
                                <li>
                                  <a>
                                    <FaHeart />
                                  </a>
                                </li>
                              </ul>
                              <ul className="product-icon-top">
                                <li>
                                  <a>
                                    <FaHeart />
                                  </a>
                                </li>
                              </ul>
                              <div className="productPhoto">
                                <img className="rx-lazy" src={photos[0].url} alt={name} />
                              </div>
                              <div className="product-item-cover">
                                <div className="price-cover">
                                  <div className="new-price">${price}</div>
                                </div>
                                <h6 className="prod-title">
                                  <a href="single-shop.html">{name}</a>
                                </h6>
                                <a href="single-shop.html" className="btn">
                                  <span>Buy Now</span>
                                </a>
                              </div>
                              <div className="prod-info">
                                <ul className="prod-list">
                                  <li>
                                    For: <span>{gender}</span>
                                  </li>

                                  <li>
                                    Frame type: <span>{frame}</span>
                                  </li>
                                  {/* <li>Frame Size: {frameSize.join(", ")}</li> */}
                                  <li>
                                    Class: <span>{bikeCategory}</span>
                                  </li>
                                  <li>
                                    Bike Weight: <span>{weight}</span>
                                  </li>
                                  <li>
                                    Number of gears: <span>{bikeGears.join(", ")}</span>
                                  </li>
                                  <li className="availableColors">
                                    Available Colors: <br />
                                    {product.colors.map((colorName, index) => {
                                      const category = categories.find((category) => category.categoryName === "Colors" && category.subCategories === colorName);
                                      return category ? (
                                        <span
                                          key={index} // Use `index` as key if colorName may not be unique
                                          style={{
                                            backgroundColor: `#${category.subCatValue}`, // Use the color value from the category
                                          }}
                                          title={colorName}></span>
                                      ) : null;
                                    })}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Products;
