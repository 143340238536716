import React from "react";
import "./ProductCreation.scss";
import Layout from "../../../../../Layout/Layout";
import AdminMenu from "../../../AdminMenu/AdminMenu";
import { Toaster } from "react-hot-toast";
import Select from "react-select";
import customStyles from "../../CustomStyles/CustomStyles";
import useProductLogic from "./ProductLogic/ProductLogic";
import TextEditor from "../../../../../TextEditor/TextEditor";
import { Link } from "react-router-dom";

const ProductCreation = () => {
  const {
    categories,
    productCategory,
    setProductCategory,
    bikeCategory,
    setBikeCategory,
    brand,
    setBrand,
    gender,
    setGender,
    name,
    setName,
    description,
    setDescription,
    colors,
    price,
    setPrice,
    weight,
    setWeight,
    frame,
    setFrame,
    brakeType,
    setBrakeType,
    suspension,
    setSuspension,
    tires,
    setTires,
    quantity,
    setQuantity,
    stock,
    setStock,
    setOnSale,
    setShipping,
    handleCreate,
    handleFrameSize,
    handleWheelSize,
    handleGearNos,
    handleFileChange,
    handleColorChange,
  } = useProductLogic();
  //React Quill modules

  return (
    <Layout title={"Creto: Products Craetion Page"}>
      <div className="col-lg-12 cus_shopPage">
        <div className="row">
          <section className="s-header-title">
            <div className="container">
              <h1>Products</h1>
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Dashboard/Admin">Admin</Link>
                </li>
                <li>Products</li>
              </ul>
            </div>
          </section>
          <section className="s-shop">
            <div className="container-fluid px-4">
              <div className="row">
                <div className="col-lg-2 col-md-3">
                  <AdminMenu />
                </div>
                <div className="col-lg-10 col-md-9 cus_forms">
                  <div className="row">
                    <div className="card">
                      <Toaster />
                      <div className="card-header">
                        <h4>Product Section</h4>
                      </div>
                      <div className="card-body">
                        <div className="col-lg-12">
                          <form onSubmit={handleCreate} method="post" encType="multipart/form-data">
                            <div className="row">
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="productName" className="form-label">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="productName"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder="Enter product name"
                                  aria-describedby="productName"
                                  required
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="productCategory" className="form-label">
                                  Product Category
                                </label>
                                <Select
                                  className="cus-form-select"
                                  id="productCategory"
                                  aria-describedby="productCategory"
                                  options={[
                                    {
                                      label: "Bikes",
                                      value: categories.find((category) => category.categoryName === "Bikes")?._id || "", // Set the value of "Bikes" category
                                    },
                                    ...categories
                                      .filter((category) => category.categoryName !== "Bikes") // Exclude "Bikes" category
                                      .map((category) => ({
                                        label: category.categoryName,
                                        value: category._id,
                                        isDisabled: true, // Disable other categories
                                      })),
                                  ]}
                                  onChange={(selectedOption) => setProductCategory(selectedOption.value)}
                                  value={{
                                    label: categories.find((category) => category._id === productCategory)?.categoryName || "Bikes", // Set the label as "Bikes" by default
                                    value: productCategory,
                                  }}
                                  styles={customStyles}
                                  isDisabled // Disable the entire select element
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="bikeCategory" className="form-label">
                                  Bike Category
                                </label>
                                <Select
                                  className="cus-form-select"
                                  id="bikeCategory"
                                  aria-describedby="bikeCategory"
                                  options={categories
                                    .filter((category) => category.categoryName === "Bikes")
                                    .map((category) => ({
                                      label: category.subCategories,
                                      value: category.subCategories,
                                    }))}
                                  onChange={(selectedOption) => setBikeCategory(selectedOption.value)}
                                  value={{
                                    label: bikeCategory || "Select Bike Category",
                                    value: bikeCategory,
                                  }}
                                  styles={customStyles}
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="brandName" className="form-label">
                                  Brand
                                </label>
                                <Select
                                  className="cus-form-select"
                                  id="brandName"
                                  aria-describedby="brandName"
                                  options={categories
                                    .filter((category) => category.categoryName === "Brand")
                                    .map((category) => ({
                                      label: category.subCategories,
                                      value: category.subCategories,
                                    }))}
                                  onChange={(selectedOption) => setBrand(selectedOption.value)}
                                  value={{
                                    label: brand || "Select the Brand",
                                    value: brand,
                                  }}
                                  styles={customStyles}
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="gender" className="form-label">
                                  Gender
                                </label>
                                <Select
                                  className="cus-form-select"
                                  id="gender"
                                  aria-describedby="gender"
                                  options={categories
                                    .filter((category) => category.categoryName === "Gender")
                                    .map((category) => ({
                                      label: category.subCategories,
                                      value: category.subCategories,
                                    }))}
                                  onChange={(selectedOption) => setGender(selectedOption.value)}
                                  value={{
                                    label: gender || "Select Gender",
                                    value: gender,
                                  }}
                                  styles={customStyles}
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="productPrice" className="form-label">
                                  Price
                                </label>
                                <input
                                  type="number"
                                  className="form-control numbers"
                                  id="productPrice"
                                  value={price}
                                  onChange={(e) => setPrice(e.target.value)}
                                  placeholder="Enter product price"
                                  aria-describedby="productPrice"
                                  required
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="weight" className="form-label">
                                  Bike weight
                                </label>
                                <input
                                  type="number"
                                  className="form-control numbers"
                                  id="weight"
                                  value={weight}
                                  onChange={(e) => setWeight(e.target.value)}
                                  placeholder="Enter bike weight in kgs"
                                  aria-describedby="weight"
                                  required
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="frame" className="form-label">
                                  Frame
                                </label>
                                <select className="form-select" id="frame" value={frame} onChange={(e) => setFrame(e.target.value)} aria-describedby="frame" required>
                                  <option value="" disabled>
                                    Select Frame
                                  </option>
                                  <option value="aluminium">Aluminium</option>
                                  <option value="steel">Steel</option>
                                </select>
                              </div>

                              <div className="col-lg-8 mb-3 cus_form_inputs">
                                <label htmlFor="color" className="form-label">
                                  Color
                                </label>
                                <br />
                                <div className="btn-group cus_check_btns" id="colors" role="group" aria-label="Basic checkbox toggle button group">
                                  {categories
                                    .filter((category) => category.categoryName === "Colors")
                                    .map((category, index) => (
                                      <React.Fragment key={index}>
                                        <input
                                          type="checkbox"
                                          className="btn-check"
                                          id={`color-${category.subCategories}`} // Use a unique ID for each checkbox
                                          value={category.subCategories}
                                          autoComplete="off"
                                          onChange={(e) => handleColorChange(e, category.subCategories)}
                                          checked={colors.includes(category.subCategories)}
                                        />
                                        <label className="btn" htmlFor={`color-${category.subCategories}`}>
                                          <span>{category.subCategories}</span>
                                        </label>
                                      </React.Fragment>
                                    ))}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3 cus_form_inputs">
                                <label htmlFor="photos" className="form-label">
                                  Photos
                                </label>
                                <input
                                  type="file"
                                  className="form-control photos"
                                  name="photos" // Remove brackets here
                                  id="photos"
                                  onChange={(e) => handleFileChange(e)}
                                  multiple
                                  required
                                />
                              </div>
                              <div className="col-lg-4 mb-3 cus_form_inputs">
                                <label htmlFor="frameSize" className="form-label">
                                  Frame Size
                                </label>
                                <br />
                                <div className="btn-group cus_check_btns" id="frameSize" role="group" aria-label="Basic checkbox toggle button group">
                                  <input type="checkbox" className="btn-check" id="Small" value="Small" autoComplete="off" onChange={handleFrameSize} />
                                  <label className="btn" htmlFor="Small">
                                    <span>Small</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="Medium" value="Medium" autoComplete="off" onChange={handleFrameSize} />
                                  <label className="btn" htmlFor="Medium">
                                    <span>Medium</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="Large" value="Large" autoComplete="off" onChange={handleFrameSize} />
                                  <label className="btn" htmlFor="Large">
                                    <span>Large</span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="bikeGears" className="form-label">
                                  Bike Gears
                                </label>
                                <br />
                                <div className="btn-group cus_check_btns" id="bikeGears" role="group" aria-label="Basic checkbox toggle button group">
                                  <input type="checkbox" className="btn-check" id="0" value="0" autoComplete="off" onChange={handleGearNos} />
                                  <label className="btn" htmlFor="0">
                                    <span>None</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="19" value="19" autoComplete="off" onChange={handleGearNos} />
                                  <label className="btn" htmlFor="19">
                                    <span>19</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="21" value="21" autoComplete="off" onChange={handleGearNos} />
                                  <label className="btn" htmlFor="21">
                                    <span>21</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="24" value="24" autoComplete="off" onChange={handleGearNos} />
                                  <label className="btn" htmlFor="24">
                                    <span>24</span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3 cus_form_inputs">
                                <label htmlFor="wheelSize" className="form-label">
                                  Wheel Size
                                </label>
                                <br />
                                <div className="btn-group cus_check_btns" id="wheelSize" role="group" aria-label="Basic checkbox toggle button group">
                                  <input type="checkbox" className="btn-check" id="14" autoComplete="off" />
                                  <label className="btn" htmlFor="14" id="14" value="14" autoComplete="off" onChange={handleWheelSize}>
                                    <span>14</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="16" value="16" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="16">
                                    <span>16</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="18" value="18" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="18">
                                    <span>18</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="22" value="22" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="22">
                                    <span>22</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="26" value="26" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="26">
                                    <span>26</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="27_5" value="27.5" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="27_5">
                                    <span>27.5</span>
                                  </label>
                                  <input type="checkbox" className="btn-check" id="29" value="29" autoComplete="off" onChange={handleWheelSize} />
                                  <label className="btn" htmlFor="29">
                                    <span>29</span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3 cus_form_inputs">
                                <label htmlFor="tires" className="form-label">
                                  Tires
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tires"
                                  value={tires}
                                  onChange={(e) => setTires(e.target.value)}
                                  placeholder="Enter tire details"
                                  aria-describedby="tires"
                                />
                              </div>
                              <div className="col-lg-4 mb-3 cus_form_inputs">
                                <label htmlFor="brakeType" className="form-label">
                                  Brake Type
                                </label>
                                <select className="form-select" name="brakeType" id="brakeType" value={brakeType} onChange={(e) => setBrakeType(e.target.value)} aria-describedby="brakeType" required>
                                  <option value="" disabled>
                                    Select Brake Type
                                  </option>
                                  <option value="Front disc & Rear rim">Front Disc & Rear Rim</option>
                                  <option value="Front & Rear disc">Front & Rear Disc</option>
                                  <option value="Front & Rear rim">Front & Rear Rim</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3 cus_form_inputs">
                                <label htmlFor="suspension" className="form-label">
                                  Suspensions
                                </label>
                                <select
                                  className="form-select"
                                  name="suspension"
                                  id="suspension"
                                  value={suspension}
                                  onChange={(e) => setSuspension(e.target.value)}
                                  aria-describedby="suspension"
                                  required>
                                  <option value="" disabled>
                                    Select Suspensions
                                  </option>
                                  <option value="Front & Rear">Front & Rear</option>
                                  <option value="Front only">Front only</option>
                                  <option value="Rear only">Rear only</option>
                                  <option value="No Suspensions">No Suspensions</option>
                                </select>
                              </div>

                              <div className="mb-3 cus_form_inputs">
                                <label htmlFor="productDescription" className="form-label">
                                  Product Description
                                </label>
                                <TextEditor
                                  value={description}
                                  placeholder="Write product description"
                                  // className="form-control textarea"
                                  id="productDescription"
                                  onChange={(value) => setDescription(value)}
                                  aria-describedby="productDescription"
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="quantity" className="form-label">
                                  Quantity
                                </label>
                                <input
                                  type="number"
                                  value={quantity}
                                  placeholder="Enter quantity"
                                  className="form-control numbers"
                                  id="quantity"
                                  onChange={(e) => setQuantity(e.target.value)}
                                  aria-describedby="quantity"
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="stock" className="form-label">
                                  Stock Available
                                </label>
                                <input
                                  type="number"
                                  value={stock}
                                  placeholder="Enter stock quantity"
                                  className="form-control numbers"
                                  id="stock"
                                  onChange={(e) => setStock(e.target.value)}
                                  aria-describedby="stock"
                                />
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="onSale" className="form-label">
                                  Is product on sale ?
                                </label>
                                <select
                                  placeholder="Select Shipping"
                                  className="form-select mb-3"
                                  id="onSale"
                                  onChange={(e) => {
                                    setOnSale(e.target.value === "1");
                                  }}
                                  aria-describedby="onSale">
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                              </div>
                              <div className="col-lg-3 mb-3 cus_form_inputs">
                                <label htmlFor="shippingStatus" className="form-label">
                                  Is product available for shipping ?
                                </label>
                                <select
                                  placeholder="Select Shipping"
                                  className="form-select mb-3"
                                  id="shippingStatus"
                                  onChange={(e) => {
                                    setShipping(e.target.value === "1");
                                  }}
                                  aria-describedby="shippingStatus">
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                              </div>
                              <div className="mb-3">
                                <div className="text-center my-4">
                                  <button type="submit" className="btn">
                                    <span>Add Product</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default ProductCreation;
