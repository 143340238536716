import React from "react";
import "./UserMenu.scss";
import { NavLink } from "react-router-dom";
import { FaUser, FaHeart, FaCartArrowDown, FaCommentDots, FaPaperPlane } from "react-icons/fa";
const UserMenu = () => {
  return (
    <>
      <div className="col-lg-12">
        <div className="list-group">
          <NavLink to="/dashboard/user/Profile" className="list-group-item list-group-item-action" aria-current="true">
            <FaUser /> My Profile
          </NavLink>
          <NavLink to="/dashboard/user/Outbox" className="list-group-item list-group-item-action">
            <FaPaperPlane />
            Outbox
          </NavLink>
          <NavLink to="/dashboard/user/Wishlist" className="list-group-item list-group-item-action">
            <FaHeart />
            Wishlist
          </NavLink>
          <NavLink to="/dashboard/user/Orders" className="list-group-item list-group-item-action">
            <FaCartArrowDown />
            Orders
          </NavLink>
          <NavLink to="/dashboard/user/UserFeedback" className="list-group-item list-group-item-action">
            <FaCommentDots />
            Feedbacks
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default UserMenu;
