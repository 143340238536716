import React, { useState, useEffect } from "react";
import Layout from "../../../../Layout/Layout";
import Spinner from "../../../../Spinner/Spinner";
import UserMenu from "../../UserMenu/UserMenu";
import "./Outbox.scss";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ReactTable from "../../../../ReactTable/ReactTable";
import SanitizedHTML from "../../../../SanitizedHTML/SanitizedHTML";
const Outbox = () => {
  const [loading, setLoading] = useState(true);
  const [sentMessages, setSentMessages] = useState([]);

  const getSentMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/contactus/getUserContactmsg`);
      setSentMessages(response.data.contactMessages);
      setLoading(false);
    } catch (error) {
      console.log("Error while getting sent messages", error);
      toast.error("Error while getting sent messages");
      setLoading(false);
    }
  };

  useEffect(() => {
    getSentMessages();
  }, []);

  return (
    <Layout title={"Creto: User Outbox"}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Outbox</h1>
                <ul className="breadcrumbs">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>User</li>
                  <li>Outbox</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container">
                <div className="col-lg-12 userProfileDashboard">
                  <div className="row">
                    <div className="col-lg-2 col-md-3 cusUserSidebar">
                      <UserMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cusforms">
                      <Toaster />
                      <h3>Outbox</h3>
                      <div className="card">
                        <div className="card-header">
                          <h4>Inbox</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12 table-responsive">
                            <ReactTable
                              data={sentMessages}
                              columns={[
                                { Header: "Name", accessor: "name" },
                                { Header: "Email", accessor: "email" },
                                { Header: "Message", accessor: "message", Cell: ({ value }) => <SanitizedHTML htmlContent={value} /> },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Outbox;
