import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../../../context/auth";
import { useWishlist } from "../../../../context/wishlist";

const useSingleProductLogic = () => {
  const { slug } = useParams();
  const { addWishlistItem } = useWishlist();
  const [auth] = useAuth();
  const [singleProduct, setSingleProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFrame, setSelectedFrame] = useState("");
  const [selectedGear, setSelectedGear] = useState("");
  const [selectedWheels, setSelectedWheels] = useState("");
  const [selectedColors, setSelectedColors] = useState("");

  const RadioButton = useMemo(
    () =>
      ({ id, value, onChange, checked, label }) =>
        (
          <>
            <input type="radio" className="btn-check" id={id} value={value} autoComplete="off" onChange={onChange} checked={checked} />
            <label className={`btn ${checked ? "active" : ""}`} htmlFor={id}>
              <span>{label}</span>
            </label>
          </>
        ),
    []
  );

  const handleFrameChange = useCallback((frame) => setSelectedFrame(frame), []);
  const handleGearChange = useCallback((gears) => setSelectedGear(gears), []);
  const handleWheelsChange = useCallback((wheels) => setSelectedWheels(wheels), []);
  const handleColorsChange = useCallback((colors) => setSelectedColors(colors), []);

  const getSingleProduct = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-product/${slug}`);
      const productData = response.data;

      if (productData?.success) {
        setSingleProduct(productData.product);
      } else {
        console.error("Error: Unable to fetch product");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    getSingleProduct();
    window.scrollTo(0, 0);
  }, [getSingleProduct]);

  const sliderSettings = useMemo(
    () => ({
      customPaging: function (i) {
        if (singleProduct && singleProduct.photos && singleProduct.photos.length > i && singleProduct.photos[i]) {
          return (
            <a>
              <img className="img-fluid w-100 h-100" src={singleProduct.photos[i].url} alt={`pagination-${i}`} />
            </a>
          );
        } else {
          return <span>Pagination Not Available</span>;
        }
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 4000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [singleProduct]
  );

  const wishlistAddItem = async (product) => {
    const email = auth.user.email;
    const payload = { productID: product._id, email };
    try {
      const wishlistResponse = await axios.get(`${process.env.REACT_APP_API}/api/v1/wishlist/get-wishlist/${email}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      if (wishlistResponse.data?.success && Array.isArray(wishlistResponse.data.items)) {
        const productExists = wishlistResponse.data.items.some((item) => item.productID === product._id);

        if (productExists) {
          toast.error(`${product.name} is already in your wishlist`);
          return;
        }
      } else {
        toast.error("Failed to fetch wishlist items");
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/wishlist/add-wishlist`, payload, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      if (response.data.success) {
        addWishlistItem(product);
        toast.success(`${product.name} added to wishlist`);
      } else {
        toast.error(response.data.message || `Failed to add ${product.name} to wishlist`);
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
      toast.error(`${error.response?.data?.message || error.message}`);
    }
  };

  const { _id, name, price, stock, photos, colors, brand, gender, description, frame, frameSize, wheelSize, bikeGears, bikeCategory, brakeType, suspension, tires, itemCode } = singleProduct || {};

  return {
    sliderSettings,
    handleFrameChange,
    handleGearChange,
    handleWheelsChange,
    handleColorsChange,
    wishlistAddItem,
    loading,
    singleProduct,
    auth,
    _id,
    name,
    price,
    stock,
    photos,
    colors,
    brand,
    gender,
    description,
    frame,
    frameSize,
    wheelSize,
    bikeGears,
    bikeCategory,
    brakeType,
    suspension,
    tires,
    itemCode,
    RadioButton,
    selectedColors,
    selectedFrame,
    selectedGear,
    selectedWheels,
  };
};

export default useSingleProductLogic;
