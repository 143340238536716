import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import "./Wishlist.scss";
import Layout from "../../../../Layout/Layout";
import UserMenu from "../../UserMenu/UserMenu";
import Spinner from "../../../../Spinner/Spinner";
import { useAuth } from "../../../../../context/auth";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { FaCartArrowDown, FaCartPlus, FaTrashCan } from "react-icons/fa6";
import { useWishlist } from "../../../../../context/wishlist";

const Wishlist = () => {
  const [auth] = useAuth();
  const { wishlist, setWishlist } = useWishlist();
  const [loading, setLoading] = useState(true);

  const fetchWishlistItems = useCallback(async () => {
    setLoading(true);
    try {
      const email = auth?.user?.email;
      if (!email) {
        toast.error("User email is not available");
        setLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/wishlist/get-wishlist/${email}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (response.data.success) {
        const items = response.data.items;
        setWishlist(items);
        localStorage.setItem("wishlist", JSON.stringify(items));
      } else {
        toast.error("Failed to fetch wishlist items");
      }
    } catch (error) {
      console.error("Error while fetching wishlist items", error);
      toast.error("Error while fetching wishlist items");
    } finally {
      setLoading(false);
    }
  }, [auth, setWishlist]);

  const deleteWishlistItem = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API}/api/v1/wishlist/delete-wishlist/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        if (response.data.success) {
          toast.success("Item deleted successfully from wishlist");
          const updatedWishlist = wishlist.filter((item) => item._id !== id);
          setWishlist(updatedWishlist);
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
        } else {
          toast.error("Failed to delete item");
        }
      } catch (error) {
        console.error("Error while deleting wishlist item", error);
        toast.error("Error while deleting wishlist item");
      } finally {
        setLoading(false);
      }
    },
    [auth, wishlist, setWishlist]
  );

  useEffect(() => {
    if (auth && auth.user && auth.token) {
      fetchWishlistItems();
    }
  }, [auth, fetchWishlistItems]);

  return (
    <Layout title={"Creto: Wishlist"}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="col-lg-12 cus_shopPage">
          <section className="s-header-title">
            <div className="container">
              <h1>Profile</h1>
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>User</li>
                <li>Wishlist</li>
              </ul>
            </div>
          </section>
          <section className="s-shop">
            <div className="container">
              <div className="col-lg-12 userProfileDashboard">
                <div className="row">
                  <div className="col-lg-2 col-md-3 cusUserSidebar">
                    <UserMenu />
                  </div>
                  <div className="col-lg-10 col-md-9 cusforms table-responsive">
                    <Toaster />
                    {wishlist && wishlist.length > 0 ? (
                      <>
                        <h3>Wishlisted Items</h3>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Name</th>
                              <th>Bike Category</th>
                              <th>Frame</th>
                              <th>Price</th>
                              <th>Item Code</th>
                              <th>Buy Now</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {wishlist.map((item, index) => {
                              if (!item.productID) {
                                console.error("Missing productID in wishlist item:", item);
                                return null;
                              }

                              const { _id } = item;
                              const { name, frame, bikeCategory, brand, price, itemCode, photos, slug } = item.productID;

                              return (
                                <tr key={_id || index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <span className="wishList_Img">
                                      <img src={photos[0]?.url || "placeholder.jpg"} className="img-fluid" alt={name || "Product Image"} />
                                    </span>
                                    {name || "Unnamed Product"}
                                    <br />
                                    <span className="brandName">{brand || "Unknown Brand"}</span>
                                  </td>
                                  <td>{bikeCategory || "Unknown Category"}</td>
                                  <td className="text-capitalize">{frame || "Unknown Frame"}</td>
                                  <td>${price || "N/A"}</td>
                                  <td>{itemCode || "No Item Code"}</td>
                                  <td className="text-center">
                                    <Link to={`/Shop/SingleProduct/${slug || "#"}`}>
                                      <FaCartPlus className="wishlistAddToCart" />
                                    </Link>
                                  </td>
                                  <td className="text-center">
                                    <FaTrashCan className="wishlistDelFromCart" onClick={() => deleteWishlistItem(_id)} />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="col-lg-12 text-center noItemsDiv">
                        <FaCartArrowDown />
                        <h4>There are no wishlisted items</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
};

export default Wishlist;
