import React from "react";
import "./Layout.scss";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Helmet } from "react-helmet";

const Layout = ({
  children,
  title = "Creto: Best bikes for you",
  description = "Creto is an online store for sale of different kinds and styles of bikes",
  keywords = "Creto, bikes, mountain bikes, Sale, HTML5, SCSS, Bootstrap 5, React, MongoDB, Node",
  author = "nimocodecraft",
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
