import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "./PriceRangeSlider.scss";
import "rc-slider/assets/index.css";

const PriceRangeSlider = ({ min, max, minPrice, maxPrice, onChange }) => {
  const [minPriceInput, setMinPriceInput] = useState(minPrice);
  const [maxPriceInput, setMaxPriceInput] = useState(maxPrice);

  useEffect(() => {
    // Update input values when minPrice or maxPrice props change
    setMinPriceInput(minPrice);
    setMaxPriceInput(maxPrice);
  }, [minPrice, maxPrice]);

  const handleMinInputChange = (e) => {
    const newValue = parseInt(e.target.value);
    setMinPriceInput(newValue);
    onChange([newValue, maxPrice]);
  };

  const handleMaxInputChange = (e) => {
    const newValue = parseInt(e.target.value);
    setMaxPriceInput(newValue);
    onChange([minPrice, newValue]);
  };

  const handleSliderChange = (newValue) => {
    // Update input values and slider values
    setMinPriceInput(newValue[0]);
    setMaxPriceInput(newValue[1]);
    onChange(newValue);
  };

  return (
    <div className="row">
      <div className="col-12">
        <Slider min={min} max={max} range value={[minPrice, maxPrice]} onChange={handleSliderChange} />
      </div>
      <div className="col-6 amount-cover">
        <input type="number" className="form-control numbers w-100" value={minPriceInput} min={min} max={max} onChange={handleMinInputChange} />
      </div>
      <div className="col-6 amount-cover">
        <input type="number" className="form-control numbers w-100" value={maxPriceInput} min={min} max={max} onChange={handleMaxInputChange} />
      </div>

      <div className="col-12 mt-3">
        Price Range: <strong>${minPrice}</strong> - <strong>${maxPrice}</strong>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
