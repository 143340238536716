import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "./ServiceTabs.scss";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
const ServiceTabs = () => {
  const [services, setServices] = useState([]);
  const fetchServices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/service/get-services`);
      setServices(response.data.services); // Corrected to set the services state
    } catch (error) {
      console.error("Error while fetching services", error.message);
      toast.error("Error while fetching services");
    }
  };
  useEffect(() => {
    fetchServices();
  }, []);
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow-prev slick-arrow" onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow-next slick-arrow" onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  };

  const ServiceTabsettings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2500,
    touchThreshold: 100,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <>
      <Toaster />
      <section className="s-category-bicycle">
        <div className="container">
          <div className="slider-categ-bicycle">
            <Slider {...ServiceTabsettings}>
              {services.map((service) => {
                const { _id, name, photo } = service;
                return (
                  <div className="slide-categ-bicycle" key={_id}>
                    <div className="categ-bicycle-item">
                      <img src={photo[0].url} alt="category" />
                      <div className="categ-bicycle-info">
                        <h4 className="title">{name}</h4>
                        <a href="shop.html" className="btn">
                          <span>view more</span>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
export default ServiceTabs;
