import React, { useEffect, useState } from "react";
import "./Footer.scss";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaAnglesUp, FaAt } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a smoother effect
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position relative to the viewport height
      const viewportHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const scrollThreshold = viewportHeight * 0.6; // Adjust this threshold as needed

      // Update button visibility based on scroll position
      setShowButton(scrollY > scrollThreshold);
    };

    // Add a scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <footer>
        <div className="container">
          <div className="row footer-item-cover">
            <div className="footer-subscribe col-md-7 col-lg-8">
              <h6>subscribe</h6>
              <p>Subscribe us and you won't miss the new arrivals, as well as discounts and sales.</p>
              <form className="subscribe-form">
                <FaAt className="at_icon" />
                <input className="inp-form" type="email" name="subscribe" placeholder="E-mail" />
                <button type="submit" className="footer_btn btn btn-form">
                  <span>send</span>
                </button>
              </form>
            </div>
            <div className="footer-item col-md-5 col-lg-4">
              <h6>info</h6>
              <ul className="footer-list">
                <li>
                  <a href="shop.html">FAQ</a>
                </li>
                <li>
                  <a href="shop.html">Contacts</a>
                </li>
                <li>
                  <a href="shop.html">Shipping + Heading</a>
                </li>
                <li>
                  <a href="shop.html">Exchanges</a>
                </li>
                <li>
                  <a href="shop.html">2019 Catalog</a>
                </li>
                <li>
                  <a href="shop.html">Returns</a>
                </li>
                <li>
                  <a href="shop.html">Search</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row footer-item-cover">
            <div className="footer-touch col-md-7 col-lg-8">
              <h6>stay in touch</h6>
              <ul className="footer-soc social-list">
                <li>
                  <Link to="https://www.facebook.com/nimocodecraft" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://twitter.com/nimocodecraft" rel="noopener noreferrer">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://www.instagram.com/nimocodecraft" rel="noopener noreferrer">
                    <FaInstagram />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://www.youtube.com/nimocodecraft" rel="noopener noreferrer">
                    <FaYoutube />
                  </Link>
                </li>
              </ul>
              <div className="footer-autor">
                Questions? Please write us at: <a href="mailto:nimocodecraft@gmail.com">nimocodecraft@gmail.com</a>
              </div>
            </div>
            <div className="footer-item col-md-5 col-lg-4">
              <h6>shop</h6>
              <ul className="footer-list">
                <li>
                  <a href="shop.html">Road Bike</a>
                </li>
                <li>
                  <a href="shop.html">City Bike</a>
                </li>
                <li>
                  <a href="shop.html">Mountain Bike</a>
                </li>
                <li>
                  <a href="shop.html">Kids Bike</a>
                </li>
                <li>
                  <a href="shop.html">BMX Bike</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-copyright">
              <a target="_blank" href="https://nimocodecraft.com/" rel="noopener noreferrer">
                nimocodecraft
              </a>{" "}
              © {new Date().getFullYear()}. All Rights Reserved.
            </div>
            <ul className="footer-pay">
              <li>
                <span>
                  <img src={require("../../../assets/Img/footer-pay-1.png")} alt="img" />
                </span>
              </li>
              <li>
                <span>
                  <img src={require("../../../assets/Img/footer-pay-2.png")} alt="img" />
                </span>
              </li>
              <li>
                <span>
                  <img src={require("../../../assets/Img/footer-pay-3.png")} alt="img" />
                </span>
              </li>
              <li>
                <span>
                  <img src={require("../../../assets/Img/footer-pay-4.png")} alt="img" />
                </span>
              </li>
            </ul>
            <span onClick={scrollToTop} className={`to-top ${showButton ? "active" : ""}`}>
              <FaAnglesUp />
              <span className="d-none">Back to Home</span>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
