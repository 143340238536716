import React from "react";
import "./News.scss";
import Layout from "../Layout";
import { FaCalendarDays, FaUser, FaQuoteRight, FaAt, FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";

const News = () => {
  return (
    <>
      <Layout>
        <div className="col-lg-12 cus_newsPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>News</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link> {/* Replace with Link */}
                  </li>
                  <li>News</li>
                </ul>
              </div>
            </section>
            <section className="s-news">
              <div className="container">
                <div className="row">
                  <div className="col-xxl-8 col-md-12 blog-cover">
                    <div className="post-item-cover">
                      <h4 className="title title-line-left">
                        <Link to="/single-news">Lorem ipsum dolor sit amet, consectetur</Link> {/* Replace with Link */}
                      </h4>
                      <div className="post-header">
                        <div className="post-thumbnail">
                          <Link to="/single-news">
                            {" "}
                            {/* Replace with Link */}
                            <img src={require("../../../assets/Img/blog-1.jpg")} alt="img" className="img-fluid" />
                          </Link>
                        </div>
                        <div className="meta">
                          <span className="post-date">
                            <FaCalendarDays />
                            Dec 26, 2019
                          </span>
                          <span className="post-by">
                            <FaUser />
                            By <Link to="/">Samson</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-category">
                            Category: <Link to="/">Coaching</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                      </div>
                      <div className="post-content">
                        <div className="text">
                          <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
                            occaecati cupiditate non provident, similique.
                          </p>
                        </div>
                      </div>
                      <div className="post-footer">
                        <div className="meta">
                          <span className="post-comment">
                            <Link to="/">Comments 2</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-tags">
                            Tags: <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Dorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                        <Link to="/single-news" className="btn">
                          {" "}
                          {/* Replace with Link */}
                          <span>more</span>
                        </Link>
                      </div>
                    </div>
                    <div className="post-item-cover">
                      <h4 className="title title-line-left">
                        <Link to="/single-news">Duis aute irure dolor in reprehenderit in</Link> {/* Replace with Link */}
                      </h4>
                      <div className="post-header">
                        <div className="post-thumbnail">
                          <Link to="/single-news">
                            {" "}
                            {/* Replace with Link */}
                            <img src={require("../../../assets/Img/blog-2.jpg")} alt="img" className="img-fluid" />
                          </Link>
                        </div>
                        <div className="meta">
                          <span className="post-date">
                            <FaCalendarDays />
                            Dec 26, 2019
                          </span>
                          <span className="post-by">
                            <FaUser />
                            By <Link to="/">Samson</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-category">
                            Category: <Link to="/">Coaching</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                      </div>
                      <div className="post-content">
                        <div className="text">
                          <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
                            occaecati cupiditate non provident, similique.
                          </p>
                        </div>
                      </div>
                      <div className="post-footer">
                        <div className="meta">
                          <span className="post-comment">
                            <Link to="/">Comments 2</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-tags">
                            Tags: <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Dorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                        <Link to="/single-news" className="btn">
                          {" "}
                          {/* Replace with Link */}
                          <span>more</span>
                        </Link>
                      </div>
                    </div>
                    <div className="post-item-cover">
                      <blockquote>
                        <div className="row">
                          <div className="col-xxl-1 col-lg-1 col-md-2 p-0">
                            <FaQuoteRight />
                          </div>
                          <div className="col-xxl-11 col-lg-11 col-md-10 ps-lg-4">
                            <p>“At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias”</p>
                            <div className="col-lg-12">
                              <cite>
                                by <Link to="/">Kerry Ashman</Link> {/* Example, update the path accordingly */}
                              </cite>
                            </div>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    <div className="post-item-cover">
                      <div className="post-header">
                        <h4 className="title title-line-left">
                          <Link to="/single-news">sed do eiusmod tempor incididunt ut labore et</Link> {/* Replace with Link */}
                        </h4>
                        <div className="meta">
                          <span className="post-date">
                            <FaCalendarDays />
                            Dec 26, 2019
                          </span>
                          <span className="post-by">
                            <FaUser />
                            By <Link to="/">Samson</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-category">
                            Category: <Link to="/">Coaching</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                      </div>
                      <div className="post-content">
                        <div className="text">
                          <p>
                            Ultricies tristique nulla aliquet enim tortor. Arcu bibendum at varius vel pharetra vel turpis nunc eget. Et leo duis ut diam quam nulla. Cras pulvinar mattis nunc sed
                            blandit libero volutpat. Blandit volutpat maecenas volutpat blandit aliquam etiam erat velit.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="post-footer">
                        <div className="meta">
                          <span className="post-comment">
                            <Link to="/">Comments 2</Link> {/* Example, update the path accordingly */}
                          </span>
                          <span className="post-tags">
                            Tags: <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                            <Link to="/">Dorem</Link> {/* Example, update the path accordingly */}
                          </span>
                        </div>
                        <Link to="/single-news" className="btn">
                          {" "}
                          {/* Replace with Link */}
                          <span>more</span>
                        </Link>
                      </div>
                    </div>
                    <div className="pagination-cover">
                      <ul className="pagination">
                        <li className="pagination-item item-prev">
                          <Link to="/">
                            {" "}
                            {/* Example, update the path accordingly */}
                            <FaAngleLeft />
                          </Link>
                        </li>
                        <li className="pagination-item active">
                          <Link to="/">1</Link> {/* Example, update the path accordingly */}
                        </li>
                        <li className="pagination-item">
                          <Link to="/">2</Link> {/* Example, update the path accordingly */}
                        </li>
                        <li className="pagination-item">
                          <Link to="/">3</Link> {/* Example, update the path accordingly */}
                        </li>
                        <li className="pagination-item">
                          <Link to="/">4</Link> {/* Example, update the path accordingly */}
                        </li>
                        <li className="pagination-item">
                          <Link to="/">5</Link> {/* Example, update the path accordingly */}
                        </li>
                        <li className="pagination-item item-next">
                          <Link to="/">
                            {" "}
                            {/* Example, update the path accordingly */}
                            <FaAngleRight />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-md-12 sidebar">
                    <Link to="/" className="btn btn-sidebar">
                      {" "}
                      {/* Replace with Link */}
                      <span>Widgets</span>
                    </Link>
                    <ul className="widgets">
                      <li className="widget widget-search">
                        <h5 className="title">search</h5>
                        <form className="search-form">
                          <FaAt />
                          <input className="inp-form" type="email" name="search" placeholder="E-mail" />
                          <button type="submit" className="btn btn-form">
                            <span>send</span>
                          </button>
                        </form>
                      </li>
                      <li className="widget widget-tags">
                        <h5 className="title">Tags</h5>
                        <ul>
                          <li>
                            <Link to="/">Loremiipsum</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Sitiamet</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Dolor</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Lorem</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Loremiipsum</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Dolor</Link> {/* Example, update the path accordingly */}
                          </li>
                          <li>
                            <Link to="/">Sitiamet</Link> {/* Example, update the path accordingly */}
                          </li>
                        </ul>
                      </li>
                      <li className="widget widget-archive">
                        <h5 className="title">archive</h5>
                        <ul>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              January 2019
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              February 2019
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              March 2019
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              April 2019
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              May 2019
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="widget widget-categories">
                        <h5 className="title">categories</h5>
                        <ul>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              Training
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              Courses
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              Conferences
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              <FaAngleRight />
                              UI/UX Designer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="widget widget-recent-posts">
                        <h5 className="title">recent blog posts</h5>
                        <ul>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              Mobile App Design: From Beginner to Intermediate
                            </Link>
                            <div className="date">
                              <FaCalendarDays />
                              Dec 27, 2019 at 5:47 pm
                            </div>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              Et harum quidem rerum facilis est et expedita distinctio
                            </Link>
                            <div className="date">
                              <FaCalendarDays />
                              Dec 17, 2018 at 5:47 pm
                            </div>
                          </li>
                          <li>
                            <Link to="/">
                              {" "}
                              {/* Example, update the path accordingly */}
                              Nam libero tempore, cum soluta nobis est eligendi optio
                            </Link>
                            <div className="date">
                              <FaCalendarDays />
                              Dec 8, 2018 at 5:47 pm
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default News;
