import React, { useState, useEffect } from "react";
import "./Profile.scss";
import Layout from "../../../../Layout/Layout";
import UserMenu from "../../UserMenu/UserMenu";
import { useAuth } from "../../../../../context/auth";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BsFillCameraFill } from "react-icons/bs";

const Profile = () => {
  const [auth, setAuth] = useAuth();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
    mobileno: "",
    dob: "",
    address: "",
    createdAt: "",
  });

  const calculateDuration = (startDate) => {
    const start = new Date(startDate);
    const end = new Date();
    const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return { years, remainingMonths };
  };

  const formatDate = (dob) => {
    if (!dob) return "";
    const date = new Date(dob);
    return date.toISOString().slice(0, 10); // Format: YYYY-MM-DD
  };

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/auth/profile`, userDetails);
      if (data?.error) {
        console.error(data.error);
      } else {
        setAuth({ ...auth, user: data.updatedUser });
        localStorage.setItem("auth", JSON.stringify({ ...auth, user: data.updatedUser }));
        toast.success("Profile updated successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile");
    }
  };

  useEffect(() => {
    const { email, name, mobileno, dob, address, createdAt } = auth?.user || {};
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      name,
      mobileno,
      email,
      address,
      dob: formatDate(dob),
      createdAt: calculateDuration(createdAt),
    }));
  }, [auth?.user]);
  return (
    <>
      <Layout title={"Creto: Profile"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Profile</h1>
                <ul className="breadcrumbs">
                  <li>Home</li>
                  <li>User</li>
                  <li>Profile</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container">
                <div className="col-lg-12 userProfileDashboard">
                  <div className="row">
                    <div className="col-lg-2 col-md-3 cusUserSidebar">
                      <UserMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cusforms">
                      <h3>Profile Content</h3>
                      <hr />
                      <form onSubmit={handleSubmit}>
                        <div className="row align-items-center">
                          <div className="w-auto position-relative profilePicture">
                            <img src={require("../../../../../assets/Img/autor-img.png")} className="img-fluid profileImg" alt="Profile Img" />
                            <div className="photoUpload">
                              <label className="form-label m-1 profilePhotoLabel" htmlFor="customFile1">
                                <BsFillCameraFill />
                              </label>
                              <input type="file" className="form-control d-none" id="customFile1" />
                            </div>
                          </div>
                          <div className="w-auto profileDetails">
                            <h4>{userDetails.name}</h4>
                            {userDetails.createdAt.years < 1 ? (
                              <h5>
                                Active from: <span>{userDetails.createdAt.remainingMonths} months</span>
                              </h5>
                            ) : (
                              <h5>
                                Active from:{" "}
                                <span>
                                  {userDetails.createdAt.years} years {userDetails.createdAt.remainingMonths} months
                                </span>
                              </h5>
                            )}
                            <h6>{userDetails.address}</h6>
                          </div>
                        </div>
                        {/* Personal Information section */}
                        <div className="card mb-4">
                          <div className="card-header">Personal Information</div>
                          <div className="card-body">
                            <Toaster />
                            <div className="row">
                              <div className="col-lg-6 mb-4 cus_form_inputs">
                                <label htmlFor="name">
                                  Name <span className="required_red">*</span>
                                </label>
                                <input type="text" id="name" name="name" value={userDetails.name} onChange={handleChange} className="form-control" placeholder="Enter your name" />
                              </div>
                              <div className="col-lg-6 mb-4 cus_form_inputs">
                                <label htmlFor="email">
                                  Email <span className="required_red">*</span>
                                </label>
                                <input type="email" id="email" value={userDetails.email} onChange={handleChange} className="form-control" placeholder="Enter your email" disabled />
                              </div>
                              <div className="col-lg-4 mb-4 cus_form_inputs">
                                <label htmlFor="password">
                                  Password <span className="required_red">*</span>
                                </label>
                                <input type="password" id="password" name="password" value={userDetails.password} onChange={handleChange} className="form-control" placeholder="Enter your password" />
                              </div>
                              <div className="col-lg-4 mb-4 cus_form_inputs">
                                <label htmlFor="mobileNo">
                                  Mobile Number <span className="required_red">*</span>
                                </label>
                                <input type="text" id="mobileNo" name="mobileno" value={userDetails.mobileno} onChange={handleChange} className="form-control" placeholder="Enter your Mobile no." />
                              </div>
                              <div className="col-lg-4 mb-4 cus_form_inputs">
                                <label htmlFor="userDob">
                                  Date of birth <span className="required_red">*</span>
                                </label>
                                <input type="date" id="userDob" name="dob" value={userDetails.dob} onChange={handleChange} className="form-control" placeholder="Enter your Mobile no." />
                              </div>
                              <div className="col-lg-12 mb-4 cus_form_inputs">
                                <label htmlFor="userAddress">
                                  Address <span className="required_red">*</span>
                                </label>
                                <textarea
                                  id="userAddress"
                                  name="address"
                                  value={userDetails.address}
                                  onChange={handleChange}
                                  className="form-control cus-textarea"
                                  placeholder="Enter your address"
                                  required
                                />
                              </div>
                              <div className="col-lg-12 text-center cus_form_inputs">
                                <button type="submit" className="btn">
                                  <span>Update</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
