import React, { useState, useEffect, useCallback } from "react";
import "./AdminOrders.scss";
import axios from "axios";
import moment from "moment";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import Spinner from "../../../../Spinner/Spinner";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../../../context/auth";
import ReactTable from "../../../../ReactTable/ReactTable"; // Adjust the path based on your project structure
import { FaUser, FaEnvelope, FaPhone } from "react-icons/fa6";

const AdminOrders = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status] = useState(["Not Processed", "Processing", "Shipped", "Delivered", "Cancelled"]);
  const [auth] = useAuth();

  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/all-orders`);
      if (data.success) {
        setOrders(data.orders);
        setLoading(false);
      } else {
        toast.error("Error while getting orders");
      }
    } catch (error) {
      console.error("Error while getting orders", error);
      toast.error("Error while getting orders");
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  // Memoize the handler to prevent unnecessary re-renders
  const handleStatusChange = useCallback(
    async (orderId, newStatus) => {
      try {
        setLoading(true);

        // Making a PUT request to update the order status
        const { data } = await axios.put(
          `${process.env.REACT_APP_API}/api/v1/product/order-status/${orderId}`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        // Handling response based on success status
        if (data?.success) {
          toast.success("Order status updated successfully");
          getOrders(); // Refresh orders
        } else {
          toast.error(data?.message || "Failed to update order status");
        }
      } catch (error) {
        console.error("Error updating order status", error);
        toast.error(error?.response?.data?.message || "Error updating order status");
      } finally {
        setLoading(false);
      }
    },
    [auth.token] // Dependencies include only auth.token
  );
  // Dependencies include only auth.token

  // Define columns for the ReactTable component
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Details",
        accessor: "products",
        id: "productDetails",
        Cell: ({ value }) => (
          <div style={{ minWidth: "210px" }}>
            {value.map((p, i) => (
              <React.Fragment key={i}>
                <div className="order_Img" style={{ position: "relative" }}>
                  <img src={p.product.photos[0]?.url} alt={p.product.name} style={{ width: "50px", height: "50px", marginRight: "10px" }} />
                  <span className="orderQty">{p.quantity}</span>
                </div>
                <h6>{p.product.name}</h6>
                <h6>{p.product.itemCode}</h6>
                <br />
              </React.Fragment>
            ))}
          </div>
        ),
      },
      {
        Header: "Buyer Details",
        accessor: "buyer",
        Cell: ({ value }) => (
          <div style={{ minWidth: "200px" }}>
            <h6>
              <FaUser /> : {value.name}
            </h6>
            <h6>
              <FaEnvelope /> : {value.email}
            </h6>
            <h6>
              <FaPhone /> : {value.mobileno}
            </h6>
          </div>
        ),
      },
      {
        Header: "Price",
        accessor: "totalPrice",
        Cell: ({ value }) => (
          <div className="text-center">
            <h6>${value}</h6>
          </div>
        ),
      },
      {
        Header: "Order Code",
        accessor: "orderCode",
        Cell: ({ value }) => (
          <div className="text-center">
            <h6>{value}</h6>
          </div>
        ),
      },
      {
        Header: "Payment",
        accessor: "paymentStatus",
        Cell: ({ value }) => (
          <h6
            style={{
              color: value === "Completed" ? "green" : value === "Pending" ? "orange" : value === "Failed" ? "red" : "inherit",
            }}
            className="fw-bold">
            {value}
          </h6>
        ),
      },
      {
        Header: "Order Status",
        accessor: "status",
        Cell: ({ row, value }) => (
          <div style={{ minWidth: "120px" }}>
            <select className="form-select" value={value} onChange={(e) => handleStatusChange(row.original._id, e.target.value)}>
              {status.map((statusValue) => (
                <option key={statusValue} value={statusValue}>
                  {statusValue}
                </option>
              ))}
            </select>
          </div>
        ),
      },
      {
        Header: "Shipping Address",
        accessor: "shippingAddress",
        Cell: ({ value }) => (
          <div style={{ minWidth: "180px" }}>
            <h6>{value}</h6>
          </div>
        ),
      },
      {
        Header: "Ordered On",
        accessor: "createdAt",
        Cell: ({ value }) => <h6>{moment(value).format("DD/MM/YYYY")}</h6>,
      },
      {
        Header: "Additional Details",
        Cell: () => (
          <div>
            <h6>Color: </h6>
            <h6>Frame Size:</h6>
            <h6>Bike Gears: </h6>
            <h6>Wheel Size: </h6>
          </div>
        ),
      },
    ],
    [status, handleStatusChange] // Updated dependencies array
  );

  return (
    <>
      <Layout title={"Creto: All Orders page"}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Inbox</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Dashboard/Admin">Admin</Link>
                    </li>
                    <li>Inbox</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <AdminMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cus_forms">
                      <div className="row">
                        <Toaster />
                        <div className="card">
                          <div className="card-header">
                            <h4>All Orders</h4>
                          </div>
                          <div className="card-body">
                            <div className="col-12 table-responsive">
                              <ReactTable columns={columns} data={orders} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default AdminOrders;
