import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./Components/Layout/Services/Services";
import Home from "./Components/Layout/Home/Home";
import Shop from "./Components/Layout/Shop/Shop";
import SingleProduct from "./Components/Layout/Shop/SingleProduct/SingleProduct";
import Gallery from "./Components/Layout/Gallery/Gallery";
import AboutUs from "./Components/Layout/AboutUs/AboutUs";

import News from "./Components/Layout/News/News";
import ContactUs from "./Components/Layout/ContactUs/ContactUs";

import Feedback from "./Components/Layout/Feedback/Feedback";
import Login from "./Components/Layout/Auth/Login/Login";
import SignUp from "./Components/Layout/Auth/SignUp/SignUp";
import Dashboard from "./Components/User/Dashboard/Dashboard";
import AdminDashboard from "./Components/Admin/AdminDashboard/AdminDashboard";
import { AuthProvider } from "./context/auth.js";
import { SearchProvider } from "./context/search.js";
import { CartProvider } from "./context/cart.js";
import { WishlistProvider } from "./context/wishlist.js";
import PrivateRoute from "./Components/Routes/Private";
import AdminRoute from "./Components/Routes/AdminRoute";
import ForgotPassword from "./Components/Layout/Auth/ForgotPassword/ForgotPassword";
import CategoryCreation from "./Components/Admin/AdminDashboard/AdminPages/Categories/CategoryCreation";
//Products Routes
import ProductsListing from "./Components/Admin/AdminDashboard/AdminPages/Products/ProductsListing/ProductsListing";
import ProductCreation from "./Components/Admin/AdminDashboard/AdminPages/Products/ProductCreation/ProductCreation";
import ProductUpdation from "./Components/Admin/AdminDashboard/AdminPages/Products/ProductUpdation/ProductUpdate";
import ProductsStock from "./Components/Admin/AdminDashboard/AdminPages/Products/ProductsStock/ProductsStock";
//About us content routes
import AboutUsCreation from "./Components/Admin/AdminDashboard/AdminPages/AboutUs/AboutUsCreation";
import FeedbacksListed from "./Components/Admin/AdminDashboard/AdminPages/FeedbacksListed/FeedbacksListed";
import TeamsCreation from "./Components/Admin/AdminDashboard/AdminPages/Teams/TeamsCreation";
import ServicesCreation from "./Components/Admin/AdminDashboard/AdminPages/Services/ServicesCreation";
import NewsCreation from "./Components/Admin/AdminDashboard/AdminPages/News/NewsCreation";
import AdminInbox from "./Components/Admin/AdminDashboard/AdminPages/Inbox/AdminInbox";
import AdminOrders from "./Components/Admin/AdminDashboard/AdminPages/AdminOrders/AdminOrders";
//profile routes
import Profile from "./Components/User/Dashboard/UserPages/Profile/Profile";
import Outbox from "./Components/User/Dashboard/UserPages/Outbox/Outbox";
import Wishlist from "./Components/User/Dashboard/UserPages/WishList/Wishlist";
import Orders from "./Components/User/Dashboard/UserPages/Orders/Orders";
import UserFeedback from "./Components/User/Dashboard/UserPages/UserFeedback/UserFeedback";
import Cart from "./Components/Layout/Header/Cart/Cart";

const App = () => {
  return (
    <>
      <AuthProvider>
        <SearchProvider>
          <CartProvider>
            <WishlistProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/AboutUs" element={<AboutUs />} />
                  <Route path="/Services" element={<Services />} />
                  <Route path="/Shop" element={<Shop />} />
                  <Route path="/Shop/SingleProduct/:slug" element={<SingleProduct />} />
                  <Route path="/Gallery" element={<Gallery />} />
                  <Route path="/News" element={<News />} />
                  <Route path="/ContactUs" element={<ContactUs />} />
                  <Route path="/Feedback" element={<Feedback />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/SignUp" element={<SignUp />} />
                  <Route path="/ForgotPassword" element={<ForgotPassword />} />
                  <Route path="/Profile" element={<PrivateRoute />}>
                    <Route path="" element={<Profile />} />
                  </Route>
                  <Route path="/Cart" element={<PrivateRoute />}>
                    <Route path="" element={<Cart />} />
                  </Route>
                  <Route path="/Dashboard" element={<PrivateRoute />}>
                    <Route path="user" element={<Dashboard />} />
                    <Route path="user/Profile" element={<Profile />} />
                    <Route path="user/Outbox" element={<Outbox />} />
                    <Route path="user/Wishlist" element={<Wishlist />} />
                    <Route path="user/Orders" element={<Orders />} />
                    <Route path="user/UserFeedback" element={<UserFeedback />} />
                  </Route>
                  <Route path="/Dashboard" element={<AdminRoute />}>
                    <Route path="admin" element={<AdminDashboard />} />
                    <Route path="admin/Inbox" element={<AdminInbox />} />
                    <Route path="admin/CategoryCreation" element={<CategoryCreation />} />
                    <Route path="admin/ProductCreation" element={<ProductCreation />} />
                    <Route path="admin/ServicesCreation" element={<ServicesCreation />} />
                    <Route path="admin/ProductCreation" element={<ProductCreation />} />
                    <Route path="admin/ProductsListing" element={<ProductsListing />} />
                    <Route path="admin/ProductsStock" element={<ProductsStock />} />
                    <Route path="admin/ProductUpdation/:slug" element={<ProductUpdation />} />
                    <Route path="admin/AboutUsPosts" element={<AboutUsCreation />} />
                    <Route path="admin/Feedbacks" element={<FeedbacksListed />} />
                    <Route path="admin/Team" element={<TeamsCreation />} />
                    <Route path="admin/NewsCreation" element={<NewsCreation />} />
                    <Route path="admin/AdminOrders" element={<AdminOrders />} />
                  </Route>
                </Routes>
              </Router>
            </WishlistProvider>
          </CartProvider>
        </SearchProvider>
      </AuthProvider>
    </>
  );
};
export default App;
