// TextEditor.js
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.scss";

const TextEditor = ({ value, onChange, placeholder }) => {
  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      // ["link", "image", "video"],
      ["clean"],
    ],
  };

  return <ReactQuill value={value} placeholder={placeholder} onChange={onChange} aria-describedby="productDescription" theme="snow" style={{ height: "100%" }} modules={quillModules} />;
};

export default TextEditor;
