import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./Pagination.scss";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // currentPage,totalPages,onPageChange are props

  //dynamically render Page Numbers
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i} className={`pagination-item ${currentPage === i ? "active" : ""}`}>
          <span onClick={() => onPageChange(i)}>{i}</span>
        </li>
      );
    }
    return pageNumbers;
  };

  //dynamically render Page Numbers//
  return (
    <div className="pagination-cover">
      <ul className="pagination">
        <li className={`pagination-item item-prev ${currentPage === 1 ? "disabled" : ""}`}>
          <span onClick={() => onPageChange(currentPage - 1)}>
            <FaAngleLeft />
          </span>
        </li>
        {renderPageNumbers()}
        <li className={`pagination-item item-next ${currentPage === totalPages ? "disabled" : ""}`}>
          <span onClick={() => onPageChange(currentPage + 1)}>
            <FaAngleRight />
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
