import React, { useState, useEffect } from "react";
import "./AdminInbox.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ReactTable from "../../../../ReactTable/ReactTable";
import axios from "axios";
import SanitizedHTML from "../../../../SanitizedHTML/SanitizedHTML";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import Spinner from "../../../../Spinner/Spinner";

const AdminInbox = () => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/contactus/getContactmsg`);
      setMessages(response.data.contactMessages);
      setLoading(false);
    } catch (error) {
      console.log("Error while getting messages", error);
      toast.error("Error while getting messages");
    }
  };

  const handleDelete = async (messageID) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/contactus/deleteContactmsg/${messageID}`);
      if (data?.success) {
        toast.success("Successfully deleted message");
        fetchMessages();
        setLoading(false);
      }
    } catch (error) {
      console.log("Error while deleting message", error);
      toast.error("Error while deleting message");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <>
      <Layout title={"Creto: Admin inbox page"}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Inbox</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Dashboard/Admin">Admin</Link>
                    </li>
                    <li>Inbox</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <AdminMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cus_forms">
                      <div className="row">
                        <Toaster />
                        <div className="card">
                          <div className="card-header">
                            <h4>Inbox</h4>
                          </div>
                          <div className="card-body">
                            <div className="col-lg-12 table-responsive">
                              <ReactTable
                                data={messages}
                                columns={[
                                  { Header: "Name", accessor: "name" },
                                  { Header: "Email", accessor: "email" },
                                  { Header: "Message", accessor: "message", Cell: ({ value }) => <SanitizedHTML htmlContent={value} /> },
                                  {
                                    Header: "View",
                                    Cell: ({ row }) => (
                                      <div>
                                        <FaEye />
                                      </div>
                                    ),
                                  },
                                  {
                                    Header: "Actions",
                                    Cell: ({ row }) => (
                                      <div>
                                        <FaTrashAlt onClick={() => handleDelete(row.original._id)} style={{ cursor: "pointer", color: "red" }} />
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default AdminInbox;
