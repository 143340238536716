import React, { useEffect, useState } from "react";
import "./CategoryCreation.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { FaPenToSquare, FaTrashCan } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import CreatableSelect from "react-select/creatable"; // Import CreatableSelect
import ReactTable from "../../../../ReactTable/ReactTable";
import { Link } from "react-router-dom";

const CategoryCreation = () => {
  const [categoryFormData, setCategoryFormData] = useState({
    categoryName: "",
    subCategories: "",
    subCatValue: "",
    categories: [], // Added 'categories' array to store the list
  });
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Handle category updates
      if (selectedCategory) {
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/category/update-category/${selectedCategory._id}`, categoryFormData);
        if (data?.success) {
          toast.success(`${categoryFormData.categoryName} is updated successfully`);
        } else {
          toast.error(data.message);
        }
        setSelectedCategory(null);
      } else {
        // Handle new category creation
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/category/create-category`, categoryFormData);
        if (data?.success) {
          const { categoryName, subCategories } = categoryFormData;
          toast.success(`${subCategories} in ${categoryName} is created successfully`);
          // Add the newly created subcategory to the options array
          setCategoryFormData((prevState) => ({
            ...prevState,
            categories: [...prevState.categories, { value: subCategories, label: subCategories }],
          }));
        } else {
          toast.error(data.message);
        }
      }
      setCategoryFormData({ categoryName: "", subCategories: "", subCatValue: "", categories: [] });
      getAllCategories();
    } catch (error) {
      console.error("Error in input form submission:", error);
      toast.error("Something went wrong in the input form. Please try again.");
    }
  };

  // Function to handle category update
  const handleUpdate = async (category) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
      if (data?.success) {
        setCategoryFormData((prevData) => ({
          ...prevData,
          categories: data.categories,
          categoryName: category.categoryName,
          subCategories: category.subCategories,
          subCatValue: category.subCatValue,
        }));
      }
      setSelectedCategory(category);
    } catch (error) {
      console.error("Error while updating category:", error);
      toast.error("Error while updating category");
    }
  };

  // Get categories
  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
      if (data?.success) {
        setCategoryFormData((prevData) => ({ ...prevData, categories: data.categories }));
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while getting categories");
    }
  };

  // Delete categories
  const handleCatDelete = async (categoryId) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/category/delete-category/${categoryId}`);
      if (data?.success) {
        toast.success("Deleted Successfully");
        getAllCategories();
        setCategoryFormData({ categoryName: "", subCategories: "", subCatValue: "", categories: [] });
      }
    } catch (error) {
      console.error("Error while deleting category:", error);
      toast.error("Error while deleting category");
    }
  };
  const handleCancel = () => {
    setSelectedCategory(null);
    setCategoryFormData((prevState) => ({
      ...prevState,
      categoryName: "",
      subCategories: "",
      subCatValue: "",
    }));
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <Layout title={"Creto: Categories Page"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Category</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Dashboard/Admin">Admin</Link>
                  </li>
                  <li>Category</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <AdminMenu />
                  </div>
                  <div className="col-lg-10 col-md-9 cus_forms">
                    <div className="row">
                      <Toaster />
                      <div className="card">
                        <div className="card-header">
                          <h4>Category Section</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12">
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-lg-4 col-sm-12 mb-3 cus_form_inputs">
                                  <label htmlFor="categoryLabel" className="form-label">
                                    Category name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="categoryLabel"
                                    value={categoryFormData.categoryName}
                                    onChange={(e) => setCategoryFormData({ ...categoryFormData, categoryName: e.target.value })}
                                    placeholder="Enter category name"
                                    aria-describedby="categoryLabel"
                                    autoComplete="off"
                                    pattern="^[^\s]+$"
                                    required
                                  />
                                </div>
                                <div className="col-lg-4 col-sm-12 mb-3 cus_form_inputs">
                                  <label htmlFor="subCategoryLabel" className="form-label">
                                    Sub Category name
                                  </label>
                                  {/* Replace the input field with CreatableSelect */}
                                  {categoryFormData.categories && (
                                    <CreatableSelect
                                      isClearable
                                      className="cus-form-select creatable"
                                      id="subCategoryLabel"
                                      aria-describedby="subCategoryLabel"
                                      options={categoryFormData.categories.map((cat) => ({
                                        label: cat.subCategories,
                                        value: cat.subCategories,
                                      }))}
                                      onChange={(selectedOption, actionMeta) => {
                                        if (actionMeta.action === "clear") {
                                          // Handle the case when the user clears the input field
                                          setCategoryFormData({ ...categoryFormData, subCategories: "" });
                                        } else if (actionMeta.action === "select-option" || actionMeta.action === "create-option") {
                                          // Handle the case when the user selects or creates an option
                                          setCategoryFormData({ ...categoryFormData, subCategories: selectedOption.label });
                                        }
                                      }}
                                      value={{ label: categoryFormData.subCategories || "Enter Sub Category name", value: categoryFormData.subCategories }}
                                    />
                                  )}
                                </div>
                                <div className="col-lg-4 col-sm-12 mb-3 cus_form_inputs">
                                  <label htmlFor="subCategoryValue" className="form-label">
                                    Sub Category Value
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="subCategoryValue"
                                    value={categoryFormData.subCatValue}
                                    onChange={(e) => setCategoryFormData({ ...categoryFormData, subCatValue: e.target.value })}
                                    placeholder="Enter sub category value"
                                    aria-describedby="subCategoryValue"
                                    autoComplete="off"
                                    max="6"
                                  />
                                </div>
                                <div className="col-12 text-center my-4">
                                  <button type="submit" className="btn">
                                    <span>{selectedCategory ? "Update" : "Create new category"}</span>
                                  </button>
                                  {selectedCategory && (
                                    <button type="button" className="btn" onClick={() => handleCancel()}>
                                      <span>Cancel</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>

                          <ReactTable
                            data={categoryFormData.categories}
                            columns={[
                              { Header: "Category", accessor: "categoryName" },
                              { Header: "Sub Category", accessor: "subCategories" },
                              {
                                Header: "Actions",
                                Cell: ({ row }) => (
                                  <>
                                    <FaPenToSquare onClick={() => handleUpdate(row.original)} />
                                    <FaTrashCan onClick={() => handleCatDelete(row.original._id)} />
                                  </>
                                ),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CategoryCreation;
