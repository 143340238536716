import React, { useState, useEffect } from "react";
import "./FeedbacksListed.scss";
import axios from "axios";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import toast, { Toaster } from "react-hot-toast";
import ReactTable from "../../../../ReactTable/ReactTable";
import { FaTrashCan } from "react-icons/fa6";
import SanitizedHTML from "../../../../SanitizedHTML/SanitizedHTML";
import { Link } from "react-router-dom";
import Spinner from "../../../../Spinner/Spinner";

const FeedbacksListed = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFeedbacks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/feedback/getFeedbacks`);
      if (response.data.success) {
        const feedbacks = response.data.feedbacks;
        setFeedbacks(feedbacks);
      } else {
        toast.error("Failed to fetch feedbacks");
      }
    } catch (error) {
      console.log("Error while getting the feedbacks", error);
      toast.error("Error while getting the feedbacks");
    } finally {
      setLoading(false);
    }
  };

  const handlePublish = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus; // Toggle the publish status
      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/feedback/publishFeedback/${id}`, { published: newStatus });
      if (response.data.success) {
        toast.success("Feedback status updated successfully");
        getFeedbacks(); // Refresh the feedback list
      } else {
        toast.error("Failed to update feedback status");
      }
    } catch (error) {
      console.error("Error while updating feedback status", error);
      toast.error("Error while updating feedback status");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}/api/v1/feedback/deleteFeedback/${id}`);
      if (response.data.success) {
        toast.success("Feedback deleted successfully");
        getFeedbacks(); // Refresh the feedback list
      } else {
        toast.error("Failed to delete feedback");
      }
    } catch (error) {
      console.error("Error while deleting feedback", error);
      toast.error("Error while deleting feedback");
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <>
      <Layout title={"Creto: Feedbacks listing"}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Feedbacks</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Dashboard/Admin">Admin</Link>
                    </li>
                    <li>User Feedbacks</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container-fluid px-4">
                  <Toaster />
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <AdminMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cus_forms">
                      <div className="row">
                        <div className="card">
                          <div className="card-header">
                            <h4>Feedbacks Listing</h4>
                          </div>
                          <div className="card-body">
                            <div className="col-lg-12">
                              <div className="col-lg-12 table-responsive">
                                <ReactTable
                                  data={feedbacks}
                                  columns={[
                                    { Header: "Name", accessor: "name" },
                                    { Header: "Email", accessor: "email" },
                                    { Header: "Message", accessor: "message", Cell: ({ value }) => <SanitizedHTML htmlContent={value} /> },
                                    {
                                      Header: "Published",
                                      Cell: ({ row }) => (
                                        <div>
                                          <span onClick={() => handlePublish(row.original._id, row.original.published)} style={{ cursor: "pointer", color: row.original.published ? "green" : "red" }}>
                                            {row.original.published ? "Unpublish" : "Publish"}
                                          </span>
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: "Actions",
                                      Cell: ({ row }) => (
                                        <div>
                                          <FaTrashCan onClick={() => handleDelete(row.original._id)} style={{ cursor: "pointer", color: "red" }} />
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default FeedbacksListed;
