import React, { useEffect } from "react";
import "./Shop.scss";
import Layout from "../Layout";
import Pagination from "../../Pagination/Pagination";
import { Toaster } from "react-hot-toast";
import { FaThLarge, FaTh, FaThList, FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import ShopLogic from "./ShopLogic";
import PriceRangeSlider from "../../PricerangeSlider/PriceRangeSlider";

const Shop = () => {
  const {
    categories,
    loading,
    selectedFilters,
    getAllProducts,
    fetchCategories,
    updateCheckboxFilters,
    priceRange,
    setPriceRange,
    filteredBikes,
    resetFilters,
    totalPages,
    currentProducts,
    currentPage,
    setCurrentPage,
  } = ShopLogic();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAllProducts();
    fetchCategories();
  }, []); // The dependency array is intentionally left empty

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const scrollToTop = () => {
    const windowHeight = window.innerHeight;
    const scrollPosition = windowHeight * 0.15; // Scroll to 10% of the window height

    window.scrollTo(0, scrollPosition);
  };

  return (
    <>
      <Layout
        title={"Creto: Shop Page"}
        description={"Want to buy bikes? You are at gleat place - Creto online bike store"}
        keywords={"Bikes, Mountain Bikes, Sale, Login, Login Page, Crtow, online store"}>
        {loading ? (
          <Spinner /> // Show spinner while loading
        ) : (
          <div className="col-lg-12 cus_shopPage">
            <div className="row">
              <section className="s-header-title">
                <div className="container">
                  <h1>Shop</h1>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Shop</li>
                  </ul>
                </div>
              </section>
              <section className="s-shop">
                <div className="container">
                  <div className="shop-sidebar-btn btn">
                    <span>filter</span>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-3 shop-sidebar">
                      <ul className="widgets wigets-shop">
                        <li className="widget wiget-cart">
                          <h5 className="title">Cart</h5>
                          <p className="not-product">No products in the cart.</p>
                        </li>
                        <li className="widget wiget-shop-category">
                          <h5 className="title">bikes</h5>
                          <ul>
                            {categories
                              .filter((category) => category.categoryName === "Bikes")
                              .map((bikeCategory) => (
                                <li key={bikeCategory._id}>
                                  <p>
                                    <input
                                      type="checkbox"
                                      checked={selectedFilters.bikes.includes(bikeCategory.subCategories)}
                                      onChange={(e) => updateCheckboxFilters("bikes", bikeCategory.subCategories, e.target.checked)}
                                    />
                                    <span>{bikeCategory.subCategories}</span>
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className="widget wiget-gender">
                          <h5 className="title">gender</h5>
                          <ul>
                            {categories
                              .filter((category) => category.categoryName === "Gender")
                              .map((genderName) => (
                                <li key={genderName._id}>
                                  <p>
                                    <input
                                      type="checkbox"
                                      checked={selectedFilters.genders.includes(genderName.subCategories)}
                                      onChange={(e) => updateCheckboxFilters("genders", genderName.subCategories, e.target.checked)}
                                    />
                                    <span>{genderName.subCategories}</span>
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className="widget wiget-brand">
                          <h5 className="title">brand</h5>
                          <ul>
                            {categories
                              .filter((category) => category.categoryName === "Brand")
                              .map((brand) => (
                                <li key={brand._id}>
                                  <p>
                                    <input
                                      type="checkbox"
                                      checked={selectedFilters.brands.includes(brand.subCategories)}
                                      onChange={(e) => updateCheckboxFilters("brands", brand.subCategories, e.target.checked)}
                                    />
                                    <span>{brand.subCategories}</span>
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className="widget wiget-brand">
                          <h5 className="title">color</h5>
                          <ul>
                            {categories
                              .filter((category) => category.categoryName === "Colors")
                              .map((color) => (
                                <li key={color._id}>
                                  <p>
                                    <input
                                      type="checkbox"
                                      checked={selectedFilters.colors.includes(color.subCategories)}
                                      onChange={(e) => updateCheckboxFilters("colors", color.subCategories, e.target.checked)}
                                    />
                                    <span>
                                      <label className="coloredLabel" style={{ background: `#${color.subCatValue}` }}></label> {color.subCategories}
                                    </span>
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className="widget wiget-price">
                          <h5 className="title">price($)</h5>
                          <div className="row" id="slider-range">
                            <PriceRangeSlider min={100} max={1500} minPrice={priceRange[0]} maxPrice={priceRange[1]} onChange={setPriceRange} />
                          </div>
                        </li>
                      </ul>
                      <button type="button" onClick={resetFilters} className="reset-filter-btn">
                        Reset Filters
                      </button>
                    </div>

                    <div className="col-12 col-lg-9 shop-cover">
                      <h2 className="title">road bike</h2>
                      {currentProducts.length > 0 ? (
                        <>
                          <div id="productsShopDiv" className="shop-sort-cover">
                            <Toaster />
                            <div className="sort-left">{filteredBikes.length} products found</div>
                            <div className="sort-right">
                              <ul className="sort-form">
                                <li data-atr="large">
                                  <FaThLarge />
                                </li>
                                <li data-atr="block" className="active">
                                  <FaTh />
                                </li>
                                <li data-atr="list">
                                  <FaThList />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="shop-product-cover">
                            <div className="row product-cover block">
                              {currentProducts.map((product) => (
                                <div className="col-xxl-4 col-lg-4 col-sm-6 col-xs-12 prod-item-col" key={product._id}>
                                  <div className="product-item">
                                    <Link to={`/Shop/SingleProduct/${product.slug}`} onClick={scrollToTop}>
                                      {product.onSale && <span className="top-sale">On Sale</span>}
                                      <ul className="product-icon-top">
                                        <li>
                                          <FaHeart />
                                        </li>
                                      </ul>

                                      {product.photos && product.photos.length > 0 ? (
                                        <div className="productPhoto">
                                          <img
                                            className="img-fluid"
                                            src={product.photos[0].url} // Assuming Cloudinary provides direct URLs
                                            alt={product.name}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          className="img-fluid"
                                          src="path_to_placeholder_image" // Replace with a placeholder image or any default image
                                          alt={product.name}
                                        />
                                      )}
                                    </Link>
                                    <div className="product-item-wrap">
                                      <div className="product-item-cover">
                                        <Link to={`/Shop/SingleProduct/${product.slug}`} onClick={scrollToTop}>
                                          <div className="price-cover">
                                            <div className="new-price">${product.price}</div>
                                            {/* <div className="old-price">$1.799</div> */}
                                          </div>
                                          <h6 className="prod-title">
                                            {product.name} <br />
                                            By {product.brand}
                                          </h6>
                                        </Link>
                                        <Link className="btn" to={`/Shop/SingleProduct/${product.slug}`} onClick={scrollToTop}>
                                          <span>Buy now</span>
                                        </Link>
                                      </div>
                                      <div className="prod-info">
                                        <ul className="prod-list">
                                          <li>
                                            For: <span>{product.gender}</span>
                                          </li>

                                          <li>
                                            Frame type: <span>{product.frame}</span>
                                          </li>
                                          {/* <li>Frame Size: {product.frameSize.join(", ")}</li> */}
                                          <li>
                                            Class: <span>{product.bikeCategory}</span>
                                          </li>
                                          <li>
                                            Bike Weight: <span>{product.weight}</span>
                                          </li>
                                          <li>
                                            Number of gears: <span>{product.bikeGears.join(", ")}</span>
                                          </li>

                                          <li className="availableColors">
                                            Available Colors: <br />
                                            {product.colors.map((colorName, index) => {
                                              const category = categories.find((category) => category.categoryName === "Colors" && category.subCategories === colorName);
                                              return category ? (
                                                <span
                                                  key={index} // Use `index` as key if colorName may not be unique
                                                  style={{
                                                    backgroundColor: `#${category.subCatValue}`, // Use the color value from the category
                                                  }}
                                                  title={colorName}></span>
                                              ) : null;
                                            })}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 my-5 py-5 text-center emptyPoducts">
                            <h2>No matching products found</h2>
                          </div>
                        </>
                      )}

                      <div className="baner-shop">
                        <span className="mask"></span>
                        <img className="img-fluid" src={require("../../../assets/Img/banner-shop.jpg")} alt="img" />
                        <div className="baner-item-content">
                          <h2>our discount program</h2>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmmpor incididunt ut labore et dolore magna aliqua.</p>
                          <a href="single-shop.html" className="btn">
                            <span>read more</span>
                          </a>
                          <div className="banner-sale-cover">
                            <div className="banner-sale">30% off</div>
                            <p>Lorem ipsum dolor sit amet</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default Shop;
