import React, { useState, useEffect } from "react";
import "./AboutUs.scss";
import Layout from "../Layout";
import { FaFacebookF, FaLinkedin, FaPinterest, FaSquareInstagram } from "react-icons/fa6";
import Feedback from "../Home/Feedback/Feedback";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import SanitizedHTML from "../../SanitizedHTML/SanitizedHTML";
import Spinner from "../../Spinner/Spinner";

const AboutUs = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [aboutUsPosts, setAboutUsPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/team/get-members`);
      setTeamMembers(response.data.members);
    } catch (error) {
      console.error("Error while fetching team members", error);
      toast.error("Error while fetching team members");
    }
  };

  const fetchAboutUsPosts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/aboutus/get-aboutusposts`);
      setAboutUsPosts(response.data.aboutusPosts);
    } catch (error) {
      console.error("Error while fetching About us posts", error);
      toast.error("Error while fetching About us posts");
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      await Promise.all([fetchTeamMembers(), fetchAboutUsPosts()]);
      setLoading(false);
    };
    fetchdata();
  }, []);

  return (
    <Layout
      title={"Creto: About Us"}
      description={"Discover the story behind Creto. Learn about our mission, values, and the team dedicated to bringing you the best in bikes and mountain bikes."}
      keywords={"Creto, about us, our story, mission, values, Creto team, bike brand, mountain bikes, online store, company history"}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="col-12 cus_aboutsUS">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>About Us</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </section>
            <section className="s-about-advantages">
              <div className="container">
                <h2 className="title">
                  <span>Our advantages</span>
                </h2>
                <div className="row about-adv-cover">
                  {aboutUsPosts.map((post) => {
                    const { _id, name, description, photo } = post;
                    return (
                      <div key={_id} className="col-12 col-md-6 about-adv-item">
                        <img src={photo[0].url} className="img-fluid" alt="img" aria-describedby="About us Photo" />
                        <h5 className="title" aria-describedby="About us post title">
                          {name}
                        </h5>

                        <SanitizedHTML htmlContent={description} />
                      </div>
                    );
                  })}
                </div>
                <div className="our-advantages-wrap advantages-wrap-about">
                  <div className="our-advantages-item">
                    <img src={require("../../../assets/Img/advantages-about-1.jpg")} alt="icon" />
                    <h5>
                      Free shipping <br />
                      from $500
                    </h5>
                  </div>
                  <div className="our-advantages-item wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".25s">
                    <img src={require("../../../assets/Img/advantages-about-2.jpg")} alt="icon" />
                    <h5>
                      Warranty service <br />
                      for 3 months
                    </h5>
                  </div>
                  <div className="our-advantages-item wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".35s">
                    <img src={require("../../../assets/Img/advantages-about-3.jpg")} alt="icon" />
                    <h5>
                      Exchange and return <br />
                      within 14 days
                    </h5>
                  </div>
                  <div className="our-advantages-item wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".45s">
                    <img className="rx-lazy" src={require("../../../assets/Img/advantages-about-4.jpg")} alt="icon" />
                    <h5>
                      Discounts for <br />
                      customers
                    </h5>
                  </div>
                </div>
              </div>
            </section>
            <section className="s-about-count" style={{ backgroundImage: `url(${require("../../../assets/Img/bg-about-count.jpg")})` }}>
              <span className="mask"></span>
              <span className="effwct-bg-about" style={{ backgroundImage: `url(${require("../../../assets/Img/effect-bg-about.svg")})` }}></span>
              <div className="container">
                <div className="row about-count-cover">
                  <div className="col-sm-6 col-md-3 about-count-item">
                    <div className="number">
                      <span data-number="2200">0</span>
                    </div>
                    <h5>Satisfied customers</h5>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
                  </div>
                  <div className="col-sm-6 col-md-3 about-count-item">
                    <div className="number">
                      <span data-number="1650">0</span>
                    </div>
                    <h5>Various Products</h5>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
                  </div>
                  <div className="col-sm-6 col-md-3 about-count-item">
                    <div className="number">
                      <span data-number="125">0</span>
                    </div>
                    <h5>Famous brand</h5>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
                  </div>
                  <div className="col-sm-6 col-md-3 about-count-item">
                    <div className="number">
                      <span data-number="1988">0</span>
                    </div>
                    <h5>Orders per year</h5>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="s-our-team">
              <div className="container">
                <h2 className="title">
                  <span>our team</span>
                </h2>
                <div className="row team-cover">
                  {teamMembers.map((member) => {
                    const { _id, name, designation, photo, facebookLink, instagramLink, linkedinLink, pinterestLink } = member;
                    return (
                      <div key={_id} className="col-sm-6 col-md-3 team-item">
                        <img src={photo[0].url} className="img-fluid team_member" alt="img" />
                        <h5 className="title">{name}</h5>
                        <div className="prof">{designation}</div>
                        <ul className="social-list">
                          {facebookLink && (
                            <li>
                              <Link to={facebookLink} target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                              </Link>
                            </li>
                          )}
                          {instagramLink && (
                            <li>
                              <Link to={instagramLink} target="_blank" rel="noopener noreferrer">
                                <FaSquareInstagram />
                              </Link>
                            </li>
                          )}
                          {linkedinLink && (
                            <li>
                              <Link to={linkedinLink} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                              </Link>
                            </li>
                          )}
                          {pinterestLink && (
                            <li>
                              <Link to={pinterestLink} target="_blank" rel="noopener noreferrer">
                                <FaPinterest />
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
            <Feedback />
            <section className="s-clients about-clients">
              <div className="container">
                <div className="row clients-cover">
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-1.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-2.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-4.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-5.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-6.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center client-slide">
                    <div className="client-slide-cover">
                      <img src={require("../../../assets/Img/client-4.png")} className="img-fluid w-75" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default AboutUs;
