import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Spinner.scss";
const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 && navigate(`/${path}`, { state: location.pathname });
    //location is passed in aditional state property while login
    return () => clearInterval(interval);
    //cleanup function
  }, [count, navigate, location, path]);
  //dependancy array above
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
      <svg className="bike" viewBox="0 0 48 30" width="48px" height="30px">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
          <g transform="translate(9.5,19)">
            <circle className="bike__tire" r="9" strokeDasharray="56.549 56.549" />
            <g className="bike__spokes-spin" strokeDasharray="31.416 31.416" strokeDashoffset="-23.562">
              <circle className="bike__spokes" r="5" />
              <circle className="bike__spokes" r="5" transform="rotate(180,0,0)" />
            </g>
          </g>
          <g transform="translate(24,19)">
            <g className="bike__pedals-spin" strokeDasharray="25.133 25.133" strokeDashoffset="-21.991" transform="rotate(67.5,0,0)">
              <circle className="bike__pedals" r="4" />
              <circle className="bike__pedals" r="4" transform="rotate(180,0,0)" />
            </g>
          </g>
          <g transform="translate(38.5,19)">
            <circle className="bike__tire" r="9" strokeDasharray="56.549 56.549" />
            <g className="bike__spokes-spin" strokeDasharray="31.416 31.416" strokeDashoffset="-23.562">
              <circle className="bike__spokes" r="5" />
              <circle className="bike__spokes" r="5" transform="rotate(180,0,0)" />
            </g>
          </g>
          <polyline className="bike__seat" points="14 3,18 3" strokeDasharray="5 5" />
          <polyline className="bike__body" points="16 3,24 19,9.5 19,18 8,34 7,24 19" strokeDasharray="79 79" />
          <path className="bike__handlebars" d="m30,2h6s1,0,1,1-1,1-1,1" strokeDasharray="10 10" />
          <polyline className="bike__front" points="32.5 2,38.5 19" strokeDasharray="19 19" />
        </g>
      </svg>

      <h4 className="mb-4">redirecting you to Login Page in {count} seconds</h4>
    </div>
  );
};
export default Spinner;
