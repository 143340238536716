import React, { useState, useEffect } from "react";
import "./Orders.scss";
import UserMenu from "../../UserMenu/UserMenu";
import Layout from "../../../../Layout/Layout";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../../../../context/auth";
import moment from "moment";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();

  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/orders`);
      if (data.success) {
        setOrders(data.orders);
      } else {
        toast.error("Error while getting orders");
      }
    } catch (error) {
      console.error("Error while getting orders", error);
      toast.error("Error while getting orders");
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  return (
    <>
      <Layout title={"Creto: Orders"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Orders</h1>
                <ul className="breadcrumbs">
                  <li>Home</li>
                  <li>User</li>
                  <li>Orders</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container">
                <div className="col-lg-12 userProfileDashboard">
                  <div className="row">
                    <Toaster />
                    <div className="col-lg-2 col-md-3 cusUserSidebar">
                      <UserMenu />
                    </div>
                    <div className="col-lg-10 col-md-9 cusforms">
                      <h3>Orders</h3>
                      <hr />
                      <div className="col-12 table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Product Name</th>
                              <th>Buyer Details</th>
                              <th>QTY.</th>
                              <th>Price</th>
                              <th>Shipping Address</th>
                              <th>Ordered On</th>
                              <th>Payment</th>
                              <th>Order Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {orders.map((order, index) => {
                              const { products, totalPrice, shippingAddress, status, buyer, paymentStatus, createdAt } = order;
                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    <h6>{index + 1}</h6>
                                  </td>
                                  <td style={{ minWidth: "210px" }}>
                                    {products.map((p, i) => (
                                      <React.Fragment key={i}>
                                        <span className="order_Img">
                                          <img src={p.product.photos[0]?.url} alt={p.product.name} style={{ width: "50px", height: "50px", marginRight: "10px" }} />
                                        </span>
                                        <h6>{p.product.name}</h6>
                                        <h6>{p.product.itemCode}</h6>
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </td>

                                  <td style={{ minWidth: "250px" }}>
                                    <h6>Name: {buyer.name}</h6>
                                    <h6>Email: {buyer.email}</h6>
                                    <h6>Phone No: {buyer.mobileno}</h6>
                                  </td>
                                  <td className="text-center">
                                    {products.map((p, i) => (
                                      <h6 key={i}>{p.quantity}</h6>
                                    ))}
                                  </td>
                                  <td className="text-center">
                                    <h6>${totalPrice}</h6>
                                  </td>
                                  <td style={{ minWidth: "180px" }}>
                                    <h6>{shippingAddress}</h6>
                                  </td>
                                  <td>
                                    <h6>{moment(createdAt).format("DD/MM/YYYY")}</h6>
                                  </td>
                                  <td>
                                    <h6
                                      style={{
                                        color: paymentStatus === "Completed" ? "green" : paymentStatus === "Pending" ? "orange" : paymentStatus === "Failed" ? "red" : "inherit",
                                      }}
                                      className="fw-bold">
                                      {paymentStatus}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6
                                      className="fw-bold"
                                      style={{
                                        color:
                                          status === "Not Processed"
                                            ? "gray"
                                            : status === "Processing"
                                            ? "blue"
                                            : status === "Shipped"
                                            ? "orange"
                                            : status === "Delivered"
                                            ? "green"
                                            : status === "Cancelled"
                                            ? "red"
                                            : "inherit",
                                      }}>
                                      {status}
                                    </h6>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Orders;
