import axios from "axios";
import React, { useState, useEffect, createContext, useContext } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });

  useEffect(() => {
    const loadAuthDataFromLocalStorage = () => {
      const data = localStorage.getItem("auth");
      if (data) {
        const parseData = JSON.parse(data);
        setAuth((prevAuth) => ({
          ...prevAuth,
          user: parseData.user,
          token: parseData.token,
        }));
      }
    };
    loadAuthDataFromLocalStorage();
  }, []);

  useEffect(() => {
    // Axios interceptor to set Authorization header
    const axiosInterceptor = axios.interceptors.request.use((config) => {
      config.headers.Authorization = auth.token;
      return config;
    });

    return () => {
      // Remove the interceptor when component unmounts
      axios.interceptors.request.eject(axiosInterceptor);
    };
  }, [auth.token]);

  return <AuthContext.Provider value={[auth, setAuth]}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
