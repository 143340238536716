import React from "react";
import "./Home.scss";
import Layout from "../Layout";
import Carouselslider from "./Slider/Slider";
import FindBike from "./FindBike/FindBike";
import ServiceTabs from "./ServiceTabs/ServiceTabs";
import Advantages from "./Advantages/Advantages";
import Products from "./Products/Products";
import Subscribe from "./Subscribe/Subscribe";
import TopSale from "./TopSale/TopSale";
import Feedback from "./Feedback/Feedback";
import News from "./News/News";
import ComingSale from "./ComingSale/ComingSale";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Creto: Home Page</title>
        <meta name="description" content="Creto: Home page" />
      </Helmet>
      <Layout>
        <Carouselslider />
        <FindBike />
        <ServiceTabs />
        <Advantages />
        <Products />
        <Subscribe />
        <TopSale />
        <Feedback />
        <News />
        <ComingSale />
      </Layout>
    </>
  );
};
export default Home;
