import React, { useState } from "react";
import "./SearchInput.scss";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";

const SearchInput = ({ closeSearch }) => {
  const navigate = useNavigate();
  const [values, setValues] = useSearch();
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false); // State to track if search has been performed

  const handleSearchForm = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const { data: searchResultsData } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/search/${values.keyword}`);
      setSearchResults(searchResultsData);
      setSearchPerformed(true); // Set searchPerformed to true after search
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchForm(e);
    }
  };

  const handleLinkNavigate = (slug) => {
    navigate(`/Shop/SingleProduct/${slug}`);
    setSearchPerformed(false); // Close the search box after navigation
    setValues({ ...values, keyword: "" });
    document.body.style.overflowY = "auto";
  };
  const handleClose = () => {
    closeSearch();
    setValues({ ...values, keyword: "" });
    document.body.style.overflowY = "auto";
  };

  return (
    <div className="col-lg-12 p-5 searchDiv">
      <div className="container">
        <form className="d-flex" role="search" onSubmit={handleSearchForm}>
          <div className="col-lg-12 position-relative serachBox">
            <input
              type="search"
              name="searchBox"
              id="searchBox"
              value={values.keyword}
              onChange={(e) => setValues({ ...values, keyword: e.target.value })}
              aria-label="searchBox"
              placeholder="Enter keywords to search"
              onKeyDown={handleKeyDown}
            />
            <FaMagnifyingGlass className="serachIcon" />
            <FaXmark className="closeSearch" onClick={handleClose} />
          </div>
        </form>
        {searchPerformed &&
          searchResults.length === 0 && ( // Only render "No result found" if search is performed and no results
            <div className="col-lg-12 my-4 text-center">
              <h3 className="fw-bold">No result found</h3>
            </div>
          )}
        {searchResults.length !== 0 && ( // Render search results if there are any
          <div className="col-lg-12 table-reponsive seachTableDiv">
            <table className="table searchTable">
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>For</th>
                  <th>Frame</th>
                  <th>Weight</th>
                  <th>Price</th>
                  <th>Item Code</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((product, index) => (
                  <tr key={index} onClick={() => handleLinkNavigate(product.slug)}>
                    <td>{index + 1}</td>
                    <td>
                      <img src={product.photos[0].url} className="img-fluid sreachImg" alt="Product" />
                    </td>
                    <td>
                      {product.name}
                      <span className="sub-text">{product.bikeCategory}</span>
                    </td>
                    <td>{product.brand}</td>
                    <td>{product.gender}</td>
                    <td>{product.frame}</td>
                    <td>{product.weight} kg</td>
                    <td>${product.price}</td>
                    <td>{product.itemCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
