import React from "react";
import "./AdminMenu.scss";
import { NavLink } from "react-router-dom";

const AdminMenu = () => {
  return (
    <>
      <div className="col-lg-12">
        <div className="list-group">
          <NavLink to="/dashboard/admin/Inbox" className="list-group-item list-group-item-action" aria-current="true">
            Inbox
          </NavLink>
          <NavLink to="/dashboard/admin/AdminOrders" className="list-group-item list-group-item-action">
            Orders
          </NavLink>
          <NavLink to="/dashboard/admin/CategoryCreation" className="list-group-item list-group-item-action" aria-current="true">
            Create Categories
          </NavLink>
          <NavLink to="/dashboard/admin/ProductCreation" className="list-group-item list-group-item-action">
            Create Products
          </NavLink>
          <NavLink to="/dashboard/admin/ProductsListing" className="list-group-item list-group-item-action">
            Products Listing
          </NavLink>
          <NavLink to="/dashboard/admin/ProductsStock" className="list-group-item list-group-item-action">
            Stocks
          </NavLink>
          <NavLink to="/dashboard/admin/AboutUsPosts" className="list-group-item list-group-item-action">
            About us Posts
          </NavLink>
          <NavLink to="/dashboard/admin/Feedbacks" className="list-group-item list-group-item-action">
            Feedbacks
          </NavLink>
          <NavLink to="/dashboard/admin/Team" className="list-group-item list-group-item-action">
            Team
          </NavLink>
          <NavLink to="/dashboard/admin/ServicesCreation" className="list-group-item list-group-item-action">
            Services
          </NavLink>
          <NavLink to="/dashboard/admin/NewsCreation" className="list-group-item list-group-item-action">
            News
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default AdminMenu;
