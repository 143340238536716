import React from "react";
import "./TeamsCreation.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactTable from "../../../../ReactTable/ReactTable";
import { FaPenToSquare, FaTrashCan, FaXmark } from "react-icons/fa6";
import { useTeamLogic } from "./TeamsLogic/TeamsLogic";

const TeamsCreation = () => {
  const { teamData, teamMembers, isEditing, handleChange, handleCreateMember, handleUpdateMember, handleDelete, handleEditClick, resetForm, nameInputRef, clearSocialLink } = useTeamLogic();

  return (
    <>
      <Layout title={"Creto: Team member creation page"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>Team</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Dashboard/Admin">Admin</Link>
                  </li>
                  <li>Team</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <AdminMenu />
                  </div>
                  <div className="col-lg-10 col-md-9 cus_forms">
                    <div className="row">
                      <Toaster />
                      <div className="card">
                        <div className="card-header">
                          <h4>Team section</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12">
                            <form onSubmit={isEditing ? handleUpdateMember : handleCreateMember}>
                              <div className="row">
                                <div className="col-lg-4 col-sm-12 mb-3 cus_form_inputs">
                                  <label htmlFor="name" className="form-label">
                                    Team member name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    ref={nameInputRef}
                                    value={teamData.name}
                                    onChange={handleChange}
                                    placeholder="Enter team member name"
                                    required
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-4 col-sm-12 mb-3 cus_form_inputs">
                                  <label htmlFor="designation" className="form-label">
                                    Designation
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="designation"
                                    value={teamData.designation}
                                    onChange={handleChange}
                                    placeholder="Enter team member designation"
                                    required
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-4 mb-3 cus_form_inputs">
                                  <label htmlFor="photo" className="form-label">
                                    Photo
                                  </label>
                                  <input type="file" className="form-control photos" name="photo" id="photo" onChange={handleChange} required={!isEditing} />
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3 position-relative cus_form_inputs">
                                  <label htmlFor="facebookLink" className="form-label">
                                    Facebook Link
                                  </label>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id="facebookLink"
                                    value={teamData.facebookLink}
                                    onChange={handleChange}
                                    placeholder="Enter facebook id link"
                                    autoComplete="off"
                                    aria-label="Enter facebook id link"
                                  />
                                  {isEditing && <FaXmark className="clearmark" onClick={() => clearSocialLink("facebookLink")} />}
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3 position-relative cus_form_inputs">
                                  <label htmlFor="instagramLink" className="form-label">
                                    Instagram Link
                                  </label>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id="instagramLink"
                                    value={teamData.instagramLink}
                                    onChange={handleChange}
                                    placeholder="Enter instagram id link"
                                    autoComplete="off"
                                    aria-label="Enter instagram profile link"
                                  />
                                  {isEditing && <FaXmark className="clearmark" onClick={() => clearSocialLink("instagramLink")} />}
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3 position-relative cus_form_inputs">
                                  <label htmlFor="linkedinLink" className="form-label">
                                    Linkedin Profile
                                  </label>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id="linkedinLink"
                                    value={teamData.linkedinLink}
                                    onChange={handleChange}
                                    placeholder="Enter linkedin profile link"
                                    autoComplete="off"
                                    aria-label="Enter linkedin profile link"
                                  />
                                  {isEditing && <FaXmark className="clearmark" onClick={() => clearSocialLink("linkedinLink")} />}
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3 position-relative cus_form_inputs">
                                  <label htmlFor="pinterestLink" className="form-label">
                                    Pinterest Profile
                                  </label>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id="pinterestLink"
                                    value={teamData.pinterestLink}
                                    onChange={handleChange}
                                    placeholder="Enter pinterest profile link"
                                    autoComplete="off"
                                    aria-label="Enter pinterest profile link"
                                  />
                                  {isEditing && <FaXmark className="clearmark" onClick={() => clearSocialLink("pinterestLink")} />}
                                </div>
                                <div className="mb-3">
                                  <div className="text-center my-4">
                                    <button type="submit" className="btn" aria-label="Create/Update Team Member">
                                      <span>{isEditing ? "Update Team Member" : "Create Team Member"}</span>
                                    </button>
                                    {isEditing && (
                                      <button type="button" className="btn" aria-label="Cancel" onClick={resetForm}>
                                        <span>Cancel</span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="team-members">
                              <h4>Team Members</h4>
                              <div className="col-lg-12 table-responsive">
                                <ReactTable
                                  data={teamMembers}
                                  columns={[
                                    {
                                      Header: "Name",
                                      accessor: "name",
                                      Cell: ({ row }) => (
                                        <div style={{ minWidth: "110px" }}>
                                          <div>{row.original.name}</div>
                                        </div>
                                      ),
                                    },
                                    { Header: "Designation", accessor: "designation" },
                                    { Header: "Facebook", accessor: "facebookLink" },
                                    { Header: "Instagram", accessor: "instagramLink" },
                                    { Header: "LinkedIn", accessor: "linkedinLink" },
                                    { Header: "Pinterest", accessor: "pinterestLink" },
                                    {
                                      Header: "Actions",
                                      Cell: ({ row }) => (
                                        <div className="tableActions">
                                          <FaPenToSquare onClick={() => handleEditClick(row.original)} />
                                          <FaTrashCan onClick={() => handleDelete(row.original._id)} />
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TeamsCreation;
