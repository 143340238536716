import React from "react";
import "./NewsCreation.scss";
import Layout from "../../../../Layout/Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { FaPenToSquare, FaTrashCan } from "react-icons/fa6";
import { Link } from "react-router-dom";
const NewsCreation = () => {
  return (
    <>
      <Layout title={"Creto: News Page"}>
        <div className="col-lg-12 cus_shopPage">
          <div className="row">
            <section className="s-header-title">
              <div className="container">
                <h1>News</h1>
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Dashboard/Admin">Admin</Link>
                  </li>
                  <li>News</li>
                </ul>
              </div>
            </section>
            <section className="s-shop">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3">
                    <AdminMenu />
                  </div>
                  <div className="col-md-9 cus_forms">
                    <div className="row">
                      <div className="card">
                        <div className="card-header">
                          <h4>News Section</h4>
                        </div>
                        <div className="card-body">
                          <div className="col-lg-12">
                            <form>
                              <div className="row">
                                <div className="mb-3 cus_form_inputs">
                                  <label htmlFor="newsTitle" className="form-label">
                                    News title
                                  </label>
                                  <input type="text" className="form-control" id="newsTitle" placeholder="Enter news title" aria-describedby="newsTitle" />
                                </div>
                                <div className="mb-3 cus_form_inputs">
                                  <label htmlFor="newsPhoto" className="form-label">
                                    News Photo
                                  </label>
                                  <input className="form-control" id="newsPhoto" type="file" aria-describedby="newsPhoto" />
                                </div>
                                <div className="col-lg-6 mb-3 cus_form_inputs">
                                  <label htmlFor="author" className="form-label">
                                    Author
                                  </label>
                                  <input type="text" className="form-control" id="author" placeholder="Enter author name" aria-describedby="author" />
                                </div>
                                <div className="col-lg-6 mb-3 cus_form_inputs">
                                  <label htmlFor="newsdate" className="form-label">
                                    Date
                                  </label>
                                  <input type="date" className="form-control" id="newsdate" placeholder="Enter post date" aria-describedby="newsdate" />
                                </div>
                                <div className="col-lg-12 mb-3 cus_form_inputs">
                                  <label htmlFor="smallDesc" className="form-label">
                                    Small Description
                                  </label>
                                  <textarea className="form-control" id="smallDesc" placeholder="Enter small description" aria-describedby="smallDesc"></textarea>
                                </div>
                                <div className="col-lg-12 mb-3 cus_form_inputs">
                                  <label htmlFor="descOne" className="form-label">
                                    Full Description 1
                                  </label>
                                  <textarea className="form-control" id="descOne" placeholder="Enter small description" aria-describedby="descOne"></textarea>
                                </div>
                                <div className="col-lg-12 mb-3 cus_form_inputs">
                                  <label htmlFor="descTwo" className="form-label">
                                    Full Description 2
                                  </label>
                                  <textarea className="form-control" id="descTwo" placeholder="Enter small description" aria-describedby="descTwo"></textarea>
                                </div>
                                <div className="col-lg-12 mb-3 cus_form_inputs">
                                  <label htmlFor="quote" className="form-label">
                                    Quote
                                  </label>
                                  <textarea className="form-control" id="quote" placeholder="Enter a quote" aria-describedby="quote"></textarea>
                                </div>
                                <div className="col-lg-6 mb-3 cus_form_inputs">
                                  <label htmlFor="tags" className="form-label">
                                    Tags
                                  </label>
                                  <input type="text" id="tags" className="form-control" placeholder="Enter tags" aria-describedby="tags" />
                                </div>
                                <div className="text-center my-4">
                                  <button type="submit" className="btn">
                                    <span>Create new service</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="col-lg-12 my-4 table-responsive-sm">
                            <table className="table cus_grid_tables">
                              <thead>
                                <tr>
                                  <th>Sr.no</th>
                                  <th>Photo</th>
                                  <th>Name</th>
                                  <th>Edit/Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>Photo</td>
                                  <td>Free Shipping from $500</td>
                                  <td>
                                    <FaPenToSquare className="cus_edit" />
                                    <FaTrashCan className="cus_delete" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>Photo</td>
                                  <td>Warranty Service for 3 months</td>
                                  <td>
                                    <FaPenToSquare className="cus_edit" />
                                    <FaTrashCan className="cus_delete" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Photo</td>
                                  <td>Exchange and return within 14 days</td>
                                  <td>
                                    <FaPenToSquare className="cus_edit" />
                                    <FaTrashCan className="cus_delete" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Photo</td>
                                  <td>Discounts for cutomers</td>
                                  <td>
                                    <FaPenToSquare className="cus_edit" />
                                    <FaTrashCan className="cus_delete" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default NewsCreation;
