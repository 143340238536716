import React from "react";
import "./Modal.scss";

const Modal = ({ show, onClose, title, content }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <div className="modal fade show d-block" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {title}
              </h1>
              <button type="button" className="btn-close" onClick={onClose} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">{content}</div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={onClose} data-bs-dismiss="modal">
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default Modal;
