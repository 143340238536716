import React, { useState, useEffect } from "react";
import "./ComingSale.scss";

const ComingSale = () => {
  const [countDown, setCountDown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const saleEndDate = new Date("2023-11-24T23:59:59").getTime();

  useEffect(() => {
    const updateCountDown = () => {
      const currentDateTime = new Date().getTime();
      const timeRemaining = saleEndDate - currentDateTime;
      if (timeRemaining > 0) {
        const { days, hours, minutes, seconds } = {
          days: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
          hours: Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((timeRemaining % (1000 * 60)) / 1000),
        };
        setCountDown({ days, minutes, hours, seconds });
      }
    };
    const countDownInterval = setInterval(updateCountDown, 1000);
    updateCountDown();
    return () => clearInterval(countDownInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //is an empty dependency array. In React,
  //this signifies that the effect should run once, and only once,  after the initial render of the component
  const { days, hours, minutes, seconds } = countDown;

  return (
    <>
      <section className="s-banner" style={{ backgroundImage: `url(${require("../../../../assets/Img/bg-section-banner.jpg")})` }}>
        <span className="mask"></span>
        <div className="banner-img">
          <div className="banner-img-bg wow fadeIn" data-wow-duration=".6s" style={{ backgroundImage: `url(${require("../../../../assets/Img/effect-section-banner.svg")}` }}></div>
          <img className="rx-lazy wow fadeInLeftBlur" data-wow-duration=".8s" data-wow-delay=".3s" src={require("../../../../assets/Img/bike-banner.png")} alt="img" />
        </div>
        <div className="container">
          <h2 className="title">Hyper E-Ride Bike 700C</h2>
          <p className="slogan">Maecenas consequat ex id lobortis venenatis. Mauris id erat enim. Morbi dolor dolor, auctor tincidunt lorem.</p>
          <div className="banner-price">
            <div className="new-price">$1,699</div>
            <div className="old-price">$1,799</div>
          </div>
          <div id="clockdiv">
            <div>
              <span className="days">{days}</span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="hours">{hours}</span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="minutes">{minutes}</span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="seconds">{seconds}</span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSale;
