import { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export const useTeamLogic = () => {
  const [teamData, setTeamData] = useState({
    name: "",
    designation: "",
    facebookLink: "",
    instagramLink: "",
    linkedinLink: "",
    pinterestLink: "",
    photo: null,
  });
  const [teamMembers, setTeamMembers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const nameInputRef = useRef(null);
  const handleEditClick = (member) => {
    handleEdit(member);
    setTimeout(() => {
      nameInputRef.current.scrollIntoView({ behavior: "smooth" });
      nameInputRef.current.focus();
    }, 400);
  };
  const resetForm = () => {
    setTeamData({
      name: "",
      designation: "",
      facebookLink: "",
      instagramLink: "",
      linkedinLink: "",
      pinterestLink: "",
      photo: null,
    });
    setIsEditing(false);
    setCurrentMemberId(null);
    document.getElementById("photo").value = "";
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setTeamData({ ...teamData, photo: files[0] });
    } else {
      setTeamData({ ...teamData, [id]: value });
    }
  };

  const handleCreateMember = async (e) => {
    e.preventDefault();
    const { name, designation, facebookLink, instagramLink, linkedinLink, pinterestLink, photo } = teamData;

    if (!name || !designation || !photo) {
      toast.error("Name, designation, and photo are required");
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("designation", designation);
    data.append("facebookLink", facebookLink);
    data.append("instagramLink", instagramLink);
    data.append("linkedinLink", linkedinLink);
    data.append("pinterestLink", pinterestLink);
    data.append("photo", photo);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/team/create-member`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      resetForm();
      fetchTeamMembers();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Error creating team member");
    }
  };

  const handleUpdateMember = async (e) => {
    e.preventDefault();
    const { name, designation, facebookLink, instagramLink, linkedinLink, pinterestLink, photo } = teamData;
    if (!name || !designation) {
      toast.error("Name and designation are required");
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("designation", designation);
    data.append("facebookLink", facebookLink);
    data.append("instagramLink", instagramLink);
    data.append("linkedinLink", linkedinLink);
    data.append("pinterestLink", pinterestLink);
    if (photo) data.append("photo", photo);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API}/api/v1/team/update-member/${currentMemberId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      resetForm();
      fetchTeamMembers();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Error updating team member");
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/team/get-members`);
      setTeamMembers(response.data.members);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching team members");
    }
  };

  const handleDelete = async (memberId) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/team/delete-member/${memberId}`);
      if (data?.success) {
        toast.success("Deleted Successfully");
        resetForm();
        fetchTeamMembers();
      }
    } catch (error) {
      console.log("Error while deleting team member", error.message);
      toast.error("Error while deleting team member");
    }
  };

  const handleEdit = (member) => {
    setTeamData({
      name: member.name,
      designation: member.designation,
      facebookLink: member.facebookLink,
      instagramLink: member.instagramLink,
      linkedinLink: member.linkedinLink,
      pinterestLink: member.pinterestLink,
      photo: null,
    });
    setIsEditing(true);
    setCurrentMemberId(member._id);
  };

  //function for clearing social links
  //prev data is used get current data and linktype empties specific link in data
  const clearSocialLink = (linkType) => {
    setTeamData((prevData) => ({
      ...prevData,
      [linkType]: "",
    }));
  };
  useEffect(() => {
    fetchTeamMembers();
  }, []);

  return {
    teamData,
    teamMembers,
    isEditing,
    currentMemberId,
    handleChange,
    handleCreateMember,
    handleUpdateMember,
    handleDelete,
    handleEdit,
    resetForm,
    nameInputRef,
    clearSocialLink,
    handleEditClick,
  };
};
