const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#fff9c4" : state.isHovered ? "white" : "white",
    color: state.isSelected ? "black" : defaultStyles.color,
  }),
  control: (provided, state) => ({
    ...provided,
    paddingLeft: "15px",
    lineHeight: "24px",
    fontSize: "16px",
    height: "50px",
    paddingTop: "0",
    paddingBottom: "0",
    borderRadius: "2px",
    borderColor: state.isFocused ? "#ffd910" : provided.borderColor,
    boxShadow: state.isFocused ? "0px 0px 14px rgba(0, 0, 0, 0.1)" : provided.boxShadow,
    borderWidth: state.isFocused ? "2px" : provided.borderWidth,
    outline: state.isFocused ? "none" : provided.outline,
    ":hover": {
      borderColor: "#ffd910",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: "15px",
    margin: "0px",
    padding: "0px",
    width: "100%",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    // Add more styles as needed
  }),
};
export default customStyles;
