import React from "react";
import "./UserContent.scss";
const UserContent = () => {
  return (
    <>
      <div className="col-lg-12">user Content</div>
    </>
  );
};
export default UserContent;
