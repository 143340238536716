import React, { useState, useEffect } from "react";
import "./Feedback.scss";
import axios from "axios";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import toast from "react-hot-toast";
import SanitizedHTML from "../../../SanitizedHTML/SanitizedHTML";
import Spinner from "../../../Spinner/Spinner";
import Modal from "../../../Modal/Modal";

// Helper function to truncate message to 30 words
const truncateMessage = (message, wordLimit) => {
  const words = message.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return message;
};

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const getFeedbacks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/feedback/getFeedbacks`);
      if (response.data.success) {
        const feedbacks = response.data.feedbacks;
        setFeedbacks(feedbacks);
      } else {
        toast.error("Failed to fetch feedbacks");
      }
    } catch (error) {
      console.log("Error while getting the feedbacks", error);
      toast.error("Error while getting the feedbacks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow-prev slick-arrow" onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow-next slick-arrow" onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  };

  const FeedbackSettings = {
    className: "center",
    infinite: feedbacks.length > 1,
    centerPadding: "60px",
    slidesToShow: feedbacks.length < 3 ? feedbacks.length : 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: feedbacks.length > 1,
    speed: 1000,
    autoplaySpeed: 3000,
    touchThreshold: 100,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: feedbacks.length < 2 ? feedbacks.length : 2,
          slidesToScroll: 1,
          infinite: feedbacks.length > 1,
          dots: false,
          autoplay: feedbacks.length > 1,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const openModal = (feedback) => {
    setSelectedFeedback(feedback);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedFeedback(null);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <section className="s-feedback" style={{ backgroundImage: `url(${require("../../../../assets/Img/bg-feedback.jpg")})` }}>
          <span className="effwct-bg-feedback" style={{ backgroundImage: `url(${require("../../../../assets/Img/effect-bg-feedback.svg")})` }}></span>
          <span className="mask"></span>
          <div className="container">
            <h2 className="title">feedback</h2>
            <div className="feedback-slider">
              <div className="row">
                {feedbacks.length > 1 ? (
                  <Slider {...FeedbackSettings}>
                    {feedbacks
                      .filter((feedback) => feedback.published)
                      .map((feedback) => {
                        const { _id, name, photo, message } = feedback;
                        const truncatedMessage = truncateMessage(message, 30);

                        return (
                          <div className="col-lg-4 feedback-slide" key={_id}>
                            <div className="feedback-item position-static">
                              <div className="feedback-content">
                                <SanitizedHTML htmlContent={truncatedMessage} />
                                {message.split(" ").length > 30 && (
                                  <span className="read-more-link" onClick={() => openModal(feedback)} aria-expanded={modalVisible}>
                                    Read More
                                  </span>
                                )}
                              </div>
                              <div className="feedback-item-top">
                                <img src={photo[0].url} className="img-fluid" alt="feedback_photo_1" />
                                <div className="feedback-title">
                                  <h5 className="title" aria-describedby={name}>
                                    <span>{name}</span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                ) : (
                  feedbacks.map((feedback) => {
                    const { _id, name, photo, message } = feedback;
                    const truncatedMessage = truncateMessage(message, 30);

                    return (
                      <div className="col-lg-4 feedback-slide" key={_id}>
                        <div className="feedback-item position-static">
                          <div className="feedback-content">
                            <SanitizedHTML htmlContent={truncatedMessage} />
                            {message.split(" ").length > 30 && (
                              <span className="read-more-link" onClick={() => openModal(feedback)} aria-expanded={modalVisible}>
                                Read More
                              </span>
                            )}
                          </div>
                          <div className="feedback-item-top">
                            <img src={photo[0].url} className="img-fluid" alt="feedback_photo_1" />
                            <div className="feedback-title">
                              <h5 className="title" aria-describedby={name}>
                                <span>{name}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {selectedFeedback && <Modal show={modalVisible} onClose={closeModal} title={`Feedback posted by: ${selectedFeedback.name}`} content={<SanitizedHTML htmlContent={selectedFeedback.message} />} />}
    </>
  );
};

export default Feedback;
