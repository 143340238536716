import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const ShopLogic = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [priceRange, setPriceRange] = useState([100, 1500]);
  const [selectedFilters, setSelectedFilters] = useState({
    bikes: [],
    genders: [],
    brands: [],
    colors: [],
  });
  const [minPrice, setMinPrice] = useState(100);
  const [maxPrice, setMaxPrice] = useState(1500);
  const [productsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/get-products`);
      if (data?.success) {
        setProducts(data.products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Error in getting all products");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data: categoriesData } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
      if (categoriesData?.success) {
        setCategories(categoriesData.categories);
      }
    } catch (error) {
      console.log("Error while fetching categories", error);
    }
  };

  const filteredProducts = products.filter((product) => {
    const matchesColors = selectedFilters.colors.some((selectedColor) => product.colors.includes(selectedColor));
    return (
      (selectedFilters.bikes.length === 0 || selectedFilters.bikes.includes(product.bikeCategory)) &&
      (selectedFilters.genders.length === 0 || selectedFilters.genders.includes(product.gender)) &&
      (selectedFilters.brands.length === 0 || selectedFilters.brands.includes(product.brand)) &&
      (selectedFilters.colors.length === 0 || matchesColors) &&
      minPrice <= product.price &&
      product.price <= maxPrice
    );
  });

  const updateCheckboxFilters = (categoryType, value, checked) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = checked ? [...prevFilters[categoryType], value] : prevFilters[categoryType].filter((item) => item !== value);
      return {
        ...prevFilters,
        [categoryType]: updatedFilters,
      };
    });
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    if (name === "minPrice") {
      setMinPrice(value);
    } else if (name === "maxPrice") {
      setMaxPrice(value);
    }
  };
  const filterProductsByPrice = () => {
    return filteredProducts.filter((product) => product.price >= priceRange[0] && product.price <= priceRange[1]);
  };

  const resetFilters = () => {
    setSelectedFilters({
      bikes: [],
      genders: [],
      brands: [],
      colors: [],
    });
    setPriceRange([100, 1500]);
  };

  //pagination //
  // Calculate total pages based on filtered products and products per page
  const filteredBikes = filterProductsByPrice();
  const totalPages = Math.ceil(filteredBikes.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredBikes.slice(indexOfFirstProduct, indexOfLastProduct);

  return {
    categories,
    loading,
    selectedFilters,
    getAllProducts,
    fetchCategories,
    filteredProducts,
    updateCheckboxFilters,
    handlePriceChange,
    minPrice,
    maxPrice,
    filterProductsByPrice,
    filteredBikes,
    priceRange,
    setPriceRange,
    resetFilters,
    totalPages,
    currentProducts,
    currentPage,
    setCurrentPage,
  };
};

export default ShopLogic;
