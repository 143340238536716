import React, { useState, useEffect } from "react";
import "./Cart.scss";
import Layout from "../../Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DropIn from "braintree-web-drop-in-react";
import { useCart } from "../../../../context/cart";
import { BsCartX, BsTrash, BsEnvelopeFill, BsFillTelephoneFill } from "react-icons/bs";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";
import { useAuth } from "../../../../context/auth";

const Cart = () => {
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState(null); // Store DropIn instance
  const [isPaymentReady, setIsPaymentReady] = useState(false); // Track if payment is ready
  const { cart, setCart, increaseQuantity, decreaseQuantity, removeCartItem, tax, subTotal, totalPrice } = useCart();
  const [auth] = useAuth();
  const { name, email, mobileno, address } = auth?.user || {};
  const navigate = useNavigate();

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) setCart(JSON.parse(storedCart));
  }, [setCart]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/braintree/token`);
        setClientToken(data.clientToken);
      } catch (error) {
        toast.error("Failed to load payment options");
      }
    };
    fetchToken();
  }, [auth?.token]);

  const handlePayment = async (e) => {
    e.preventDefault();

    try {
      const { nonce } = await instance.requestPaymentMethod();

      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/product/braintree/payments`, {
        nonce,
        cart,
        totalPrice,
        address: auth.user.address, // Send the user's address
      });

      if (response.data.success) {
        setCart([]);
        localStorage.removeItem("cart");
        toast.success("Payment successful");
        navigate("/dashboard/user/orders");
      } else {
        toast.error("Payment failed");
      }
    } catch (error) {
      console.error("Error while handling payments:", error);
      toast.error("Payment failed");
    }
  };

  return (
    <Layout title="Creto: Cart Page" description="Want to buy bikes? You are at a great place - Creto online bike store">
      <div className="col-lg-12 cus_shopPage">
        <Toaster />
        <section className="s-header-title">
          <div className="container">
            <h1>Cart</h1>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Cart">Cart</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="s-single-product">
          <div className="container">
            {cart.length === 0 ? (
              <div className="col-lg-12 text-center emptyCart">
                <BsCartX />
                <h3>Cart is empty, please add products to the cart</h3>
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr.no</th>
                        <th>Photo</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((product, index) => (
                        <tr key={product._id}>
                          <td>{index + 1}</td>
                          <td>
                            <img src={product.photos[0].url} style={{ width: "60px", height: "60px" }} className="img-fluid" alt={product.photos[0].name} />
                          </td>
                          <td>
                            {product.name}
                            <span className="brandName">{product.brand}</span>
                          </td>
                          <td>${product.price}</td>
                          <td>
                            <div className="input-group d-flex cartInputGroup">
                              {product.quantity <= 1 ? (
                                <button className="btn-outline-secondary" type="button" onClick={() => removeCartItem(product._id)}>
                                  <BsTrash className="btnTrash" />
                                </button>
                              ) : (
                                <button className="btn-outline-secondary" type="button" onClick={() => decreaseQuantity(product._id)}>
                                  <RiArrowDropDownLine />
                                </button>
                              )}
                              <input type="text" className="form-control" value={product.quantity || 1} readOnly />
                              <button
                                className={product.quantity >= 5 ? "btn-outline-secondary disabled" : "btn-outline-secondary"}
                                type="button"
                                disabled={product.quantity >= 5}
                                onClick={() => increaseQuantity(product._id)}>
                                <RiArrowDropUpLine />
                              </button>
                            </div>
                            {product.quantity >= 5 && <span className="maxQty">Max 5 qty allowed per product</span>}
                          </td>
                          <td>
                            <RxCrossCircled className="btnDeleteItem" onClick={() => removeCartItem(product._id)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="col-lg-12 text-end">
                    <span className="fw-bold subTotal">Sub-Total</span> : ${subTotal}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 paymentGateway">
                  <div className="row mb-4 userDetails">
                    <div className="col text-start">
                      <BsEnvelopeFill /> <span>{email}</span>
                    </div>
                    <div className="col text-end">
                      <BsFillTelephoneFill /> <span>{mobileno}</span>
                    </div>
                  </div>
                  <hr className="my-3" />
                  <h3 className="text-center">Order Summary</h3>
                  <div className="row">
                    <div className="col">
                      <h5>Sub-Total:</h5>
                    </div>
                    <div className="col orderValues">${subTotal}</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h5>Shipping:</h5>
                    </div>
                    <div className="col shippingRates">
                      $0 <span>Shipping is currently free for members</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h5>Taxes:</h5>
                    </div>
                    <div className="col orderValues">${tax}</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h5>Cart Total:</h5>
                    </div>
                    <div className="col orderValues">${totalPrice}</div>
                  </div>
                  <hr className="my-3" />
                  <h3 className="text-center">Checkout | Payment</h3>
                  {address ? (
                    <div className="row shippingAddress">
                      <h4 className="fw-bold my-2">Shipping Address</h4>
                      <h5>
                        Name: <span>{name}</span>
                      </h5>
                      <h5>
                        Email: <span>{email}</span>
                      </h5>
                      <h5>
                        Mobile No.: <span>{mobileno}</span>
                      </h5>
                      <h5>
                        Address: <span>{address}</span>
                      </h5>
                      <div className="col-12 text-center">
                        <button className="btn my-4" onClick={() => navigate("/Dashboard/user/Profile")}>
                          <span>Update Address</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button className="btn" onClick={() => navigate("/Login")}>
                      <span>Login</span>
                    </button>
                  )}
                  {clientToken ? (
                    <>
                      <DropIn
                        options={{
                          authorization: clientToken,
                          paypal: { flow: "vault", amount: totalPrice.toFixed(2), currency: "CAD", commit: true, enableShippingAddress: true },
                          card: {
                            overrides: {
                              fields: { number: { maskInput: { character: "*" } } },
                              styles: { input: { fontSize: "16px", color: "#333" }, "input.invalid": { color: "red" } },
                            },
                            supportedCardTypes: ["Visa", "MasterCard", "Amex", "Discover"],
                          },
                        }}
                        onInstance={(instance) => {
                          setInstance(instance);
                          setIsPaymentReady(true); // Enable payment once instance is ready
                        }}
                      />
                      <button
                        className="btn"
                        onClick={handlePayment}
                        disabled={!isPaymentReady} // Disable button until payment is ready
                      >
                        <span>Pay Now</span>
                      </button>
                    </>
                  ) : (
                    <p>Loading payment options...</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Cart;
