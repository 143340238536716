import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Feedback.scss";
import Layout from "../Layout";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import TextEditor from "../../TextEditor/TextEditor";

const Feedback = () => {
  const nameInputRef = useRef(null);

  const onLoad = () => {
    setTimeout(() => {
      const scrollToPosition = window.innerHeight * 0.1; // 10% from the top
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
      nameInputRef.current.focus();
    }, 100);
  };

  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    photo: null,
    message: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      photo: null,
      message: "",
    });
    document.getElementById("photo").value = "";
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setFormData((prevData) => ({ ...prevData, photo: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    }
  };

  const handleCreateFeedback = async (e) => {
    e.preventDefault();
    const { name, email, photo, message } = formdata;

    // Check required fields
    if (!name || !email || !photo || !message) {
      toast.error("Kindly fill all required fields");
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("photo", photo);
    data.append("message", message);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/feedback/createFeedback`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message || "Feedback submitted successfully");
        resetForm();
      } else {
        throw new Error(response.data.message || "Unexpected response from server");
      }
    } catch (error) {
      console.error("Error while submitting feedback", error);
      toast.error(error?.response?.data?.message || "Error in submitting feedback");
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Layout
      title={"Creto: Feedback"}
      description={"Share your valuable feedback with Creto. Help us improve our products and services to serve you better. Your input matters to us."}
      keywords={"Creto feedback, customer feedback, give feedback, feedback form, product feedback, service feedback, Creto reviews, share your thoughts"}>
      <div className="col-lg-12 cus_ContactUs">
        <div className="row">
          <section className="s-header-title">
            <div className="container">
              <h1>Feedback</h1>
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Feedback</li>
              </ul>
            </div>
          </section>
          <section className="s-contacts page-contacts feedback">
            <Toaster />
            <div className="container s-anim">
              <form onSubmit={handleCreateFeedback} id="feedbackForm" name="feedbackForm">
                <ul className="form-cover">
                  <li className="inp-name">
                    <input
                      id="name"
                      type="text"
                      className="form-control"
                      ref={nameInputRef}
                      value={formdata.name}
                      name="name"
                      placeholder="Enter your name"
                      aria-describedby="Enter your name"
                      required
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="inp-phone">
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      value={formdata.email}
                      name="email"
                      placeholder="Enter your email"
                      aria-describedby="Enter your email"
                      required
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="inp-email">
                    <input id="photo" type="file" className="form-control" name="photo" onChange={handleChange} aria-describedby="Upload your photo" required autoComplete="off" />
                  </li>
                  <li className="inp-text">
                    <TextEditor
                      id="message"
                      className="form-control"
                      name="message"
                      value={formdata.message}
                      onChange={(value) => handleChange({ target: { id: "message", value } })}
                      placeholder="Enter your feedback message"
                      aria-describedby="Enter your feedback message"
                      required
                    />
                  </li>
                </ul>
                <div className="btn-form-cover">
                  <button id="#submit" type="submit" className="btn">
                    <span>Submit Feedback</span>
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Feedback;
