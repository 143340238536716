import React, { useState, useEffect } from "react";
import "./RelatedProduct.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

const RelatedProduct = ({ singleProduct }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const { _id, bikeCategory } = singleProduct;
        // Fetch related products based on _id and bikeCategory
        const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/related-products/${_id}/${bikeCategory}`);
        setRelatedProducts(response.data?.relatedProducts || []);
      } catch (error) {
        console.error("Error fetching related products:", error);
      }
    };
    fetchRelatedProducts();
  }, [singleProduct]); // Use singleProduct in the dependency array

  const scrollToTop = () => {
    const windowHeight = window.innerHeight;
    const scrollPosition = windowHeight * 0.2; // Scroll to 10% of the window height

    window.scrollTo(0, scrollPosition);
  };

  return (
    <div className="related-products">
      <h3>Related Products</h3>
      <div className="product-list">
        <div className="product-cover block">
          <div className="row product">
            {relatedProducts.length > 0 ? (
              relatedProducts.map((product) => (
                <div className="col-xxl-3 col-lg-3 col-sm-6 col-xs-12 prod-item-col" key={product._id}>
                  <div className="product-item">
                    <Link to={`/Shop/SingleProduct/${product.slug}`} onClick={scrollToTop}>
                      {product.onSale && <span className="top-sale">On Sale</span>}
                      <ul className="product-icon-top">
                        <li>
                          <FaHeart />
                        </li>
                      </ul>

                      {product.photos && product.photos.length > 0 ? (
                        <div className="productPhoto">
                          <img
                            className="img-fluid"
                            src={product.photos[0].url} // Assuming Cloudinary provides direct URLs
                            alt={product.name}
                          />
                        </div>
                      ) : (
                        <img
                          className="img-fluid"
                          src="path_to_placeholder_image" // Replace with a placeholder image or any default image
                          alt={product.name}
                        />
                      )}
                    </Link>
                    <div className="product-item-wrap">
                      <div className="product-item-cover">
                        <Link to={`/Shop/SingleProduct/${product.slug}`} onClick={scrollToTop}>
                          <div className="price-cover">
                            <div className="new-price">${product.price}</div>
                            {/* <div className="old-price">$1.799</div> */}
                          </div>
                          <h6 className="prod-title">
                            {product.name} <br />
                            By {product.brand}
                          </h6>
                        </Link>
                        <Link to={`/Shop/SingleProduct/${product.slug}`} className="btn" onClick={scrollToTop}>
                          <span>Buy now</span>
                        </Link>
                      </div>
                      <div className="prod-info">
                        <ul className="prod-list">
                          <li>
                            For: <span>{product.gender}</span>
                          </li>

                          <li>
                            Frame type: <span>{product.frame}</span>
                          </li>
                          <li>Frame Size: {product.frameSize.join(", ")}</li>
                          <li>
                            className: <span>{product.bikeCategory}</span>
                          </li>
                          <li>
                            Bike Weight: <span>{product.weight}</span>
                          </li>
                          <li>
                            Number of gears: <span>{product.bikeGears.join(", ")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4>No related products found</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProduct;
